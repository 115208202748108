<script setup>

import TournamentFormSettings from "./TournamentFormSettings.vue";
import TournamentType from "./TournamentType.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";
import TournamentName from "./TournamentName.vue";
import TournamentAddress from "./TournamentAddress.vue";
import {useForm, Form} from 'vee-validate';
import TournamentDate from "./TournamentDate.vue";
import TournamentSport from "./TournamentSport.vue";
import TournamentTargets from "./TournamentTargets.vue";
import {nextTick, onMounted, ref} from "vue";
import TournamentBales from "./TournamentBales.vue";
import TournamentEnds from "./TournamentEnds.vue";
import TournamentRegistrationForm from "./TournamentRegistrationForm.vue";
import TournamentCommerce from "./TournamentCommerce.vue";
import TournamentPricing from "./TournamentPricing.vue";
import TournamentRegistrationFormLink from "./TournamentRegistrationFormLink.vue";
import TournamentRounds from "./TournamentRounds.vue";
import TournamentRoundForm from "./TournamentRoundForm.vue";
import DeleteTournament from "../../partials/DeleteTournament.vue";
import {BsAlert} from "../../../mixins/swal_mixins";

const props = defineProps(['inc_tournament', 'sportList', 'targetList', 'inc_user', 'club', 'sportDefaults'])
const {errors, meta} = useForm();

const store = useTournamentStore();

const backFromRounds = () => {
  store.showRounds = false;
  nextTick(() => {
    document.getElementById('roundCard').scrollIntoView({behavior: "smooth"});
  })
}

const backFromRegForm = () => {
  store.showRegForm = false;
  nextTick(() => {
    document.getElementById('regForm').scrollIntoView({behavior: "smooth"});
  })
}

const saveClicked = async () => {
  let scroll = await store.save();
  //This has to wait for the screen to change before scrolling to the top. I hate this hacky way, so hopefully fix it later
  if (scroll) {
    window.scroll({top: 9000, behavior: "instant"});
    await nextTick(async () => {
      setTimeout(() => {
        window.scroll({top: 0, behavior: "smooth"});
      }, 250)
    });
  }
}

const deleteClicked = async () => {
  BsAlert.fire({
    title: 'Really delete tournament',
    html: 'This cannot be undone',
    icon: 'error',
    confirmButtonText: 'Yes, delete tournament',
  }).then((value) => {
    window.location.href = `/tournaments`;
    axios.post('')
  });
}

onMounted(() => {
  store.setBanner('formEdit');
  store.setFullTargetList(props.targetList);
  store.setDefaultValues(props.inc_tournament, props.club, props.inc_user, props.sportDefaults);
})


</script>

<template>
  <div class="flex flex-col">
    <div class="flex justify-center">
      <div class="main">
        <div class="title">
          <button @click.prevent="backFromRegForm" v-if="store.showRegForm">
            <i class="fa-solid fa-lg fa-arrow-left"></i><span class="titleLabel">Edit registration form</span>
          </button>
          <button @click.prevent="backFromRounds" v-else-if="store.showRounds">
            <i class="fa-solid fa-lg fa-arrow-left"></i><span class="titleLabel">Edit rounds</span>
          </button>
          <a v-else href="javascript:history.back()">
            <i class="fa-solid fa-lg fa-arrow-left"></i>
            <span class="titleLabel">{{ store.tournamentSlug === null ? 'Create a' : 'Edit' }} tournament</span>
          </a>
        </div>
        <tournament-form-settings class="block md:hidden flex-1"/>
        <Form @change="store.touched()">
          <div v-if="store.showRegForm">
            <TournamentRegistrationForm/>
          </div>
          <div v-else-if="store.showRounds">
            <TournamentRoundForm/>
          </div>
          <div v-else>
            <TournamentType/>
            <TournamentName v-if="store.typeSelected"/>
            <TournamentAddress v-if="store.nameComplete && !store.postal"/>
            <TournamentDate v-if="store.addressComplete"/>
            <TournamentSport v-if="store.timeComplete" :sport-list="sportList" :target-list="targetList"/>
            <TournamentTargets v-if="store.sportComplete"/>
            <div v-if="store.targetsComplete">
              <TournamentBales/>
              <TournamentEnds/>
              <TournamentRounds id="roundCard"/>
              <TournamentRegistrationFormLink id="regForm"/>
              <TournamentCommerce/>
              <TournamentPricing/>
            </div>
          </div>
        </Form>
        <div class="flex justify-between">
          <button @click="saveClicked" v-if="store.canSave" class="btn btn-black ml-2 mt-4">
            <span class="save">Save <i v-if="store.saveLoading" class="fa fa-spinner fa-spin"></i></span>
          </button>
          <div>&nbsp</div>
          <delete-tournament v-if="store.tournamentSlug" :tournament="store.defaultTournament" :use-slot="true">
            <button @click.prevent class="btn btn-red-600 ml-2 mt-4">
              Delete Tournament
            </button>
          </delete-tournament>
        </div>
        <div style="height:600px;"></div>
      </div>
      <tournament-form-settings class="hidden md:block flex-1"/>
    </div>
  
  </div>
</template>

<style scoped>
.title {
  @apply ml-2;
  margin-bottom: 22px;
}

.titleLabel {
  @apply m-2;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: .35px;
}

.main {
  width: 600px;
  margin-top: 40px;
}

@media (max-width: 600px) {
  .main {
    margin-top: 15px;
    min-width: 300px;
  }
}

.save {
  display: block;
  width: 98px;
}

.btn {
  @apply mr-2;
  height: 40px;
}
</style>