<template>
  <div class="w-full m-2">
    <div class="text-2xl border-b border-solid border-gray-400">
      Registration Restrictions
    </div>
    <div class="flex">
      <label v-if="registrationOptions.length > 1" class="selectors">
        Category
        <select class="bs-form-box block" v-model="category">
          <option v-for="option in registrationOptions" :value="option">{{ option.name }}</option>
        </select>
      </label>
      <label v-if="category" class="selectors">
        {{ category.name }}
        <select class="bs-form-box block" v-model="regOption">
          <option v-for="choice in category.multiple" :value="choice.id">{{ choice.label }}</option>
        </select>
      </label>
      <div v-if="regOption" class="selectors">
        <label>
          What to restrict
          <select class="bs-form-box block" v-model="restrictionType">
            <option v-if="tournament.payments.pricing_extras && tournament.payments.pricing_extras.length"
                    value="price">
              Price
            </option>
            <option v-if="tournament.targets && tournament.targets.length > 1" value="target">Target
            </option>
            <option v-if="otherOptions && otherOptions.length" value="options">Registration Options</option>
          </select>
        </label>
      </div>
      <div v-if="restrictionType" class="selectors">
        <div v-if="restrictionType === 'price'">
          <label>
            Payment Extras
            <select class="bs-form-box block" v-model="priceExtra">
              <option v-for="(extra, index) in tournament.payments.pricing_extras" :value="index">
                {{ extra.name }}: {{ extra.type }} ${{ extra.amount }}
              </option>
            </select>
          </label>
        </div>
        <div v-else-if="restrictionType === 'target'">
          <label>
            Targets
            <select class="bs-form-box block" v-model="target">
              <option v-for="target in tournament.targets" :value="target.id">
                {{ target.name }}
              </option>
            </select>
          </label>
        </div>
        <div v-else-if="restrictionType === 'options'">
          <label>
            Hide From Registration Form
            <select class="bs-form-box block" v-model="hidden">
              <option v-for="detail in otherOptions" :value="detail.id">
                {{ detail.name }}
              </option>
            </select>
          </label>
        </div>
      </div>
      <div class="flex flex-col justify-end" v-if="hidden || target || (priceExtra !== null)">
        <button class="btn btn-blue-600 m-2" @click="setRestriction">
          Set Restriction
          <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>
    
    </div>
    <div v-for="(restriction,index) in restrictions">
      <span @click="remove(restriction, index)" class="cursor-pointer"><i class="fas fa-trash-alt"></i></span>
      {{ restrictionsText[index] }}
    </div>
  </div>
</template>

<script>

import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "RegistrationRestrictions",
  props: ['tournamentIn'],
  data() {
    return {
      restrictions: this.tournamentIn.restrictions.length ? this.tournamentIn.restrictions : [],
      restrictionType: '',
      category: null,
      regOption: null,
      priceExtra: null,
      target: null,
      hidden: null,
      loading: false,
    }
  },
  methods: {
    remove(restriction, index) {
      BsAlert.fire({
        title: 'Remove Restriction?',
        icon: 'question',
        html: this.restrictionsText[index],
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post(`/tournaments/${this.tournament.slug}/restrictions`, {
            restriction: restriction,
            remove: true
          }).then(({data}) => {
            if (data['success']) {
              this.restrictions.splice(index, 1);
            }
          })
        }
      })
    },
    setRestriction() {
      this.loading = true;
      let typeId = (this.priceExtra !== null) ? this.priceExtra : (this.target ? this.target : (this.hidden ? this.hidden : null));
      
      let restriction = {
        tournament_id: this.tournament.id,
        regform_id: this.category.id,
        multiple_id: this.regOption,
        type: this.restrictionType,
        type_id: typeId,
      }
      
      this.$axios.post(`/tournaments/${this.tournament.slug}/restrictions`, {restriction: restriction}).then(({data}) => {
        if (data['success']) {
          this.loading = false;
          this.restrictions.push(data['restriction']);
          this.restrictionType = '';
          this.regOption = null;
          this.priceExtra = null;
          this.target = null;
          this.hidden = null;
          this.loading = false;
        }
      })
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    restrictionsText() {
      let texts = [];
      this.restrictions.forEach((restriction) => {
        let category = this.tournament.regform.find(f => f.id === restriction.regform_id);
        let option = category.multiple.find(f => f.id === restriction.multiple_id)
        let string = `Competitors that select the ${category.name}:${option.label}`
        if (restriction.type === 'price') {
          let price = this.tournament.payments.pricing_extras[restriction.type_id];
          string += ` will have the payment extra of ${price.name} ${price.type}$${price.amount} added to the price.`
        } else if (restriction.type === 'target') {
          let target = this.tournament.targets.find(f => parseInt(f.id) === parseInt(restriction.type_id));
          string += ` will be assigned the ${target.name} target.`
        } else if (restriction.type === 'options') {
          let hidden = this.tournament.regform.find(f => f.id === restriction.type_id);
          string += ` will have the ${hidden.name} field hidden from the registration form.`
        }
        texts.push(string);
      })
      return texts
    },
    otherOptions() {
      return this.tournament.regform.filter(f => !['first_name', 'last_name', 'name', 'email', this.category.option].includes(f.option))
    },
    registrationOptions() {
      return this.tournament.regform.filter(f => f.multiple !== null && f.type !== 'waiver');
    },
    optionDetails() {
      let details = [];
      this.registrationOptions.forEach(function (option) {
        let multiple = option.multiple.map(m => m.label);
        details.push({'name': option.name, 'options': multiple})
      });
      return details;
    }
  },
  mounted() {
    if (this.registrationOptions.length === 1) {
      this.category = this.registrationOptions[0];
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('refresh');
    next()
  }
}
</script>

<style scoped>
.selectors {
  @apply mr-2;
}

</style>
