import {defineStore} from "pinia";


export const useAssignmentStore = defineStore('assignment', {
  state: () => {
    return {
      shown: [],
      show: false,
      showUnassigned: false,
    };
  },
  getters: {},
  actions: {},
})