<script setup>
import {useManageStore} from "../../../../stores/manageStore";

const manage = useManageStore();
</script>

<template>
  <ul @click="manage.resetRoundFilters" class="tabs mb-3 flex flex-wrap" role="tablist">
    <li class="selectedTab" id="mainTab">
      <a @click.prevent="manage.selectTab()" href="" class="active text-gray-900"
         role="tab">Competitor Details</a>
    </li>
    <li v-for="(r,index) in manage.tournament.rounds" class="tabItem" :id="'round_'+r.id">
      <a @click.prevent="manage.selectTab(r)" href="" class="nav-link text-gray-900"
         role="tab">{{ r.name }}</a>
    </li>
  </ul>
</template>

<style scoped>
.selectedTab {
  @apply bg-gray-200;
}

</style>