<template>
  <h3 class="my-2">
    <!--    <input type="hidden" :value="fullSelectionLink" id="urlSelections">-->
    <span class="flex justify-between share-buttons flex-wrap">
      <span v-if="selectedCompetitor" class="flex pl-1">
        <span class="h-center">
          <button class="btn btn-red-600" @click="cancelCompetitor()" title="Back to all results">
            <i class="fas fa-times"></i></button>
        </span>
        <span class="text-2xl ml-2 h-center">{{ selectedCompetitor.name }} Results</span>
      </span>
      <span v-else class="text-2xl">{{ title }}</span>
      <span class="flex justify-end w-full flex-wrap">
        <transition name="fade">
          <div v-if='showUpdated' class="h-center mr-2 text-green-400"> up to date</div>
        </transition>
        <button class="btn btn-outline-blue-600 m-1" @click="refresh">
          Refresh <i :class="refreshing ? 'fa-spin' : ''" class="fad fa-sync-alt"></i>
        </button>
        <button class="btn btn-outline-blue-600 m-1" @click="toggleSize">
          {{ minimize ? 'Expand' : 'Compress' }}
          <i class="fas" :class="minimize ? 'fa-expand' : 'fa-compress'"></i>
        </button>
        <button
          v-if="!showLadders && selectedCompetitor == null"
          id="fullBreakdownButton"
          class="btn btn-outline-blue-600 m-1"
          @click="showFullBreakdown">
          Show Full Breakdown
        </button>
        <button v-if="hasLadders && !fullBreakdown" id="showLaddersButton" class="btn btn-outline-blue-600 m-1"
                :class="showLadders ? 'btn-blue-600' :'btn-outline-blue-600' " @click="showLaddersClicked">
          {{ showLadders ? 'Hide' : 'Show' }} Ladder Results
        </button>
        <button id="printButton" class="btn btn-outline-blue-600 m-1" @click="printResults">
          Print Results
        </button>
        <clipboard-copy message="Results link copied to clipboard" :copy-value="fullSelectionLink">
          <button @click.prevent class="btn btn-outline-blue-600 m-1">
            Share Results
          </button>
        </clipboard-copy>
      </span>
    </span>
  </h3>
</template>
<script>
import {mapState, mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import {Toast} from "../../../mixins/swal_mixins";
import ClipboardCopy from "../../partials/ClipboardCopy.vue";

export default {
  name: "ResultOptions",
  components: {ClipboardCopy},
  props: ['hasLadders'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, ['showUpdated', 'fullSelectionLink', 'tournamentName']),
    ...mapWritableState(useResultsStore, [
      'printing',
      'refreshing',
      'overall',
      'round',
      'minimize',
      'title',
      'selectedCompetitor',
      'fullBreakdown',
      'showLadders',
    ]),
    
  },
  methods: {
    async cancelCompetitor() {
      this.store.selectedCompetitor = null;
      await this.store.updateUrl();
    },
    overallSelected() {
      this.overall = true;
      this.round = {};
      this.title = this.tournamentName + ' Overall Results';
      this.store.updateUrl();
    },
    printResults() {
      this.limit = 9999;
      new Promise((resolve) => {
        this.printing = true;
        resolve()
      }).then(() => {
        // Get HTML to print from element
        let printHtml;
        if (this.fullBreakdown && !this.selectedCompetitor) {
          printHtml = document.getElementById('printFullBreakdown').innerHTML;
        } else if (this.fullBreakdown && this.selectedCompetitor) {
          printHtml = document.getElementById('printCompetitor').innerHTML;
        } else {
          printHtml = document.getElementById('printResults').innerHTML;
        }
        
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        // noinspection CssUnusedSymbol
        WinPrint.document.write(`<!DOCTYPE html>
                        <html lang="">
                          <head>
                            <style>
                                table, th, td {
                                    border: 1px solid #808080;
                                    border-collapse: collapse;
                                    text-align: center;
                                }
                                .flex {
                                    display: flex;
                                }
                                .flex-nowrap {
                                    flex-wrap: nowrap !important;
                                }
                                .whitespace-nowrap {
                                    white-space: nowrap !important;
                                }
                                .text-right {
                                    text-align: right !important;
                                }
                                .ml-2 {
                                    margin-left: 0.5rem !important;
                                }
                            </style>
                            <title></title>
                          </head>
                          <body>
                            ${printHtml}
                          </body>
                        </html>`);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
        this.printing = false;
      })
    },
    refresh() {
      this.$emit('refresh');
    },
    shareResults() {
      let url = document.getElementById("urlSelections");
      url.setAttribute('type', 'text');
      url.select();
      navigator.clipboard.writeText(url.value);
      url.setAttribute('type', 'hidden');
      Toast.fire({title: 'Link copied to clipboard', icon: "success"});
    },
    showFullBreakdown() {
      this.fullBreakdown = !this.fullBreakdown;
      let button = document.getElementById("fullBreakdownButton");
      if (this.fullBreakdown) {
        this.title = this.tournamentName + " Full Breakdown";
        button.innerText = "Show Overall Results";
      } else {
        this.overallSelected();
        button.innerText = "Show Full Breakdown";
      }
    },
    showLaddersClicked() {
      this.showLadders = !this.showLadders;
      if (this.showLadders) {
        this.title = `${this.tournamentName} Ladder Results`
      } else {
        this.overallSelected();
      }
    },
    toggleSize() {
      this.minimize = !this.minimize;
    },
  }
}
</script>
<style scoped>


</style>