<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import FormCheckbox from "./FormCheckbox.vue";
import moment from "moment";
import {computed} from "vue";

const store = useTournamentStore();


const closed = (times) => {
  store.sortTimes(times);
  store.touched();
}

function format(time) {
  store.touched()
  let dateTime = new Date(time);
  let momentTime = moment(dateTime);
  return momentTime.format('D MMM YYYY h:mm A');
}

function formatOpen(time) {
  store.regOpen = format(time)
}

function formatClose(time) {
  store.regClose = format(time)
}




</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Date & Time</div>
    <div>
      <div class="bs-section-header">Time Zone</div>
      <select class="bs-input-box mb-4" v-model="store.timezone">
        <option v-for="option in store.timezoneOptions" :selected="option === store.timezone">{{ option }}</option>
      </select>
    </div>
    <div class="flex flex-col sm:flex-row">
      <div class="flex-1">
        <div class="bs-section-header">Line times</div>
        <div v-for="(time, i) in store.times">
          <VueDatePicker class="mb-3" :is24="false"
                         :ignore-time-validation="true"
                         :key="`Line Time ${i}`"
                         @closed="closed(store.times)"
                         v-model="store.times[i]['time']"
                         minutes-increment="15"
                         time-picker-inline
                         @cleared="store.removeTime(i)"
                         @update:model-value="store.formatTime(time,i)">
            <template #input-icon>
              <div class="flex justify-center ml-3">
                <i class="fa-regular fa-lg fa-clock-nine text-stone-400"></i>
              </div>
            </template>
          </VueDatePicker>
        </div>
        <button @click.prevent="store.addTime" class="lighter ml-2">
          <i class="far fa-plus"></i> Add line times
        </button>
      </div>
    
    </div>
    <div class="flex mt-4">
      <div class="flex flex-col flex-1 mr-4">
        <div class="bs-section-header">Registration Opens</div>
        <VueDatePicker :auto-apply="true" :enable-time-picker="true" :is24="false"
                       :model-value="store.regOpen"
                       @update:model-value="formatOpen"
                       :clearable="false"
                       minutes-increment="15"
                       time-picker-inline>
          <template #input-icon>
            <div class="flex justify-center ml-3">
              <i class="fa-regular fa-lg fa-calendar-range text-stone-400"></i>
            </div>
          </template>
        </VueDatePicker>
      </div>
      <div class="flex flex-col flex-1">
        <div class="bs-section-header">Registration Closes</div>
        <VueDatePicker :auto-apply="true" :enable-time-picker="true" :is24="false"
                       :model-value="store.regClose"
                       @update:model-value="formatClose"
                       :clearable="false"
                       minutes-increment="15"
                       time-picker-inline>
          <template #input-icon>
            <div class="flex justify-center ml-3">
              <i class="fa-regular fa-lg fa-calendar-range text-stone-400"></i>
            </div>
          </template>
        </VueDatePicker>
      </div>
    </div>
  
  </div>
</template>

<style scoped>
.lighter {
  @apply font-medium text-stone-500;
  letter-spacing: 0.018rem;
  line-height: 17px;
}

</style>