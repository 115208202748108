<template>
  <div class="flex">
    <div class="flex flex-col justify-center pl-2">
      <div class="btn-sm btn-outline-blue-600" @click="regenerate">Regenerate</div>
    </div>
    <div class="flex flex-col justify-center pl-2">
      <div class="btn-sm  btn-outline-gray-600" @click="remove">Remove</div>
    </div>
  </div>
</template>

<script>

import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "QuickSetupButtons",
  props: ['tournament', 'round', 'time', 'location', 'ladder', 'step'],
  computed: {
    selection() {
      let selection = `${this.round.name}`;
      if (this.time != null) selection += ` - ${this.time.time}`;
      if (this.location != null) selection += ` - ${this.location.name}`;
      return selection;
    }
  },
  methods: {
    remove() {
      BsAlert.fire({
        titleText: `Remove Codes for ${this.selection}?`,
        icon: 'error',
        html: 'This will permanently erase the codes, they will no longer work for any device setup. New codes may be generated at any time.',
        confirmButtonText: 'Yes, clear codes',
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post('/tournaments/' + this.tournament.slug + '/remove_codes', {
            round: this.round.id,
            time: this.time ? this.time.id : null,
            location: this.location ? this.location.id : null
          }).then((_) => {
            this.$emit('updated');
          }).catch(({response}) => {
            console.log(response);
          })
        }
      });
    },
    regenerate() {
      let vm = this;
      BsAlert.fire({
        title: `Regenerate codes for ${this.selection}?`,
        html: 'This will make any existing codes for this selection invalid',
        icon: 'warning',
        confirmButtonText: 'Yes, regenerate codes',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$axios.post('/tournaments/' + vm.tournament.slug + '/regenerate_codes', {
            round: this.round.id,
            time: this.time ? this.time.id : null,
            location: this.location ? this.location.id : null
          }).then((_) => {
            this.$emit('updated');
          })
          .catch(({response}) => {
            console.log(response);
          });
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    }
  },
}
</script>

<style scoped>

</style>
