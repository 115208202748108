<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Account Details
    </div>
    <div class="card-body">
      <div @keyup="submitErrors = false">
        <Form @submit="submit" class="flex flex-wrap">
          <div class="flex flex-col w-full lg:w-1/2 px-1 mt-2 md:mt-0">
            <label for="name">Name </label>
            <Field rules="required|min:3|max:60|clean|full_name" @blur="splitName"
                   type="text" name="name" class="bs-form-box" v-model="name"/>
            <span class="text-sm text-gray-600">This will be displayed on your public profile</span>
            <ErrorMessage class="errorText" name="name"/>
          </div>
          <div class="flex flex-col w-full lg:w-1/2 px-1 mt-2 md:mt-0">
            <label for="name">Email </label>
            <Field rules="required|email"
                   @blur="checkEmail"
                   type="text" name="email" class="bs-form-box" v-model="user.email"/>
            <span class="text-sm text-gray-600">The email is used to login to Bowscore. If you do not want to
              change the account name, you may use a different email when registering for tournaments.</span>
            <ErrorMessage class="errorText" name="email"/>
          </div>
          <div class="mt-2 flex w-full lg:w-1/2">
            <input type="checkbox" class="switch" name="hide" value="1" v-model="user.hide">
            <div class="flex flex-col justify-center">
              <label class="ml-2" for="hide">
                Make Profile Private
                <small class="text-gray-600">
                  Only you will be able to see your profile.
                </small>
              </label>
            </div>
          </div>
          <div class="mt-2 w-full lg:w-1/2">
            <div class="flex justify-end">
              <span v-if="submitErrors" class="float-right text-red-300 mr-2">You must fix errors before
                submitting.</span>
              <transition name="fade" :duration="{ enter: 1800, leave: 800 }">
                <span v-show="updatedMessage" class="float-right text-xl text-blue-600 mr-2">updated</span>
              </transition>
              <button type="submit" class="btn float-right btn-blue-600">
                Update
                <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, ErrorMessage, Field} from "vee-validate";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "EditProfile",
  components: {Form, Field, ErrorMessage},
  props: ['inc_user'],
  data() {
    return {
      name: this.inc_user.full_name,
      user: this.inc_user,
      loading: false,
      updatedMessage: false,
      submitErrors: false,
      startingEmail: '',
      
    }
  },
  created() {
    this.startingEmail = this.inc_user.email;
  },
  methods: {
    submit() {
      this.loading = true;
      let vm = this;
      this.$axios
      .put('/archers/' + this.user.slug, {
        user: this.user
      })
      .then(({data}) => {
        vm.loading = false;
        if (data.success) {
          vm.updatedMessage = true;
          setTimeout(vm.hideUpdated, 1500);
        }
      })
      .catch(({response}) => {
        console.log(response);
        vm.loading = false;
      });
    },
    splitName() {
      let splitName = this.name.split(' ');
      if (splitName.length > 1) {
        this.user.last_name = splitName[splitName.length - 1];
        splitName.pop();
        this.user.first_name = splitName.join(' ');
      }
    },
    hideUpdated() {
      this.updatedMessage = false;
    },
    checkEmail() {
      if ((this.startingEmail !== this.user.email) && !this.errors.first('email')) {
        BsAlert.fire({
          titleText: 'We need to verify your access to this email.',
          html: 'Email Address: ' + this.user.email,
          confirmButtonText: 'Send me an email verification',
        }).then(({dismiss}) => {
          if (!dismiss) {
            this.$axios
            .get('/check_user_email', {params: {email: this.user.email}})
            .then(({data}) => {
              if (data.error) {
                return BsAlert.fire({title: data.error.message, showCancelButton: false, icon: "error"});
              } else {
                BsAlert.fire({
                  title: 'Enter your verification code',
                  html: 'This code can be found in an email sent to ' + this.user.email,
                  input: 'text',
                  inputAttributes: {
                    autocapitalize: 'off'
                  },
                  confirmButtonText: 'Submit Code',
                  showLoaderOnConfirm: true,
                  inputValidator: (code) => {
                    if (!code) {
                      return 'You need to enter the code.'
                    }
                    if (code !== data.code) {
                      return 'Incorrect Code'
                    }
                  }
                }).then(({value}) => {
                  if (value) {
                    BsAlert.fire({
                      titleText: 'Code Accepted.',
                      icon: 'success',
                      html: 'Please continue with the form to finalize changes.',
                      showCancelButton: false,
                    })
                  } else {
                    this.user.email = this.startingEmail;
                    BsAlert.fire({
                      icon: 'warning',
                      html: 'Email will not be changed',
                      showCancelButton: false,
                    })
                  }
                })
              }
            })
          } else {
            this.user.email = this.startingEmail;
            BsAlert.fire({
              icon: 'warning',
              html: 'Email will not be changed',
              showCancelButton: false,
            })
          }
          
        });
        
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
