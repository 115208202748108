<template>
  <div id="printResults">
    <div v-if="printing" style="display:flex; flex-direction:column;">
      <span v-if="selectedCompetitor">Competitor: {{ selectedCompetitor.name }}</span>
      <span>Tournament: {{ tournamentIn.name }}</span>
      <span v-if="!overall && round.name">Round: {{ round.name }}</span>
      <span v-if="selectedDivision">Division: {{ selectedDivision.label }}</span>
      <span v-if="selectedClass">Class: {{ selectedClass.label }}</span>
      <span v-if="selectedTarget">Target: {{ selectedTarget.name }}</span>
      <span v-if="selectedGender">Gender: {{ selectedGender.label }}</span>
      <span v-if="selectedTeam">Team: {{ selectedTeam.name }}</span>
    </div>
    <div v-if="canViewResults">
      <overall-results-table
        v-if="overall && !selectedCompetitor"
        :tournament-in="tournamentIn"
        :competitors="competitors"
        :place-list="placeList"
        :filtered-competitors="filteredCompetitors"/>
      <individual-results-table
        v-else-if="selectedCompetitor"
        :tournament-in="tournamentIn"
        :place-list="placeList"/>
      <round-results-table
        v-else
        :tournament-in="tournamentIn"
        :competitors="competitors"
        :place-list="placeList"
        :filtered-competitors="filteredCompetitors"/>
      <div class="text-center" v-if="search.length && store.filteredUnassigned.length">
        The following competitors have registered for the tournament, but have either not been approved or
        assigned to a {{ baleWord }}:
        {{ store.filteredUnassigned.map(m => m.name).join(', ') }}
      </div>
    </div>
    <div v-else class="h-128">
      Results for this round are not publicly available.
    </div>
  </div>
</template>
<script>
import {mapState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import FormSwitch from "../../partials/FormSwitch.vue";
import CompetitorScore from "./CompetitorScore.vue";
import user from "../../../store/modules/user";
import OverallResultsTable from "./OverallResultsTable.vue";
import IndividualResultsTable from "./IndividualResultsTable.vue";
import RoundResultsTable from "./RoundResultsTable.vue";

export default {
  name: "OverallResults",
  components: {RoundResultsTable, IndividualResultsTable, OverallResultsTable, CompetitorScore, FormSwitch},
  data() {
    return {
      store: useResultsStore(),
    }
  },
  props: ['tournamentIn', 'filteredCompetitors', 'competitors', 'placeList'],
  computed: {
    ...mapState(useResultsStore, [
      'overall',
      'round',
      'search',
      'selectedCompetitor',
      'selectedGender',
      'selectedDivision',
      'selectedClass',
      'selectedRound',
      'selectedTarget',
      'selectedTeam',
      'printing',
      'user',
    ]),
    baleWord() {
      return this.$trans.choice('search.bale', 1)
    },
    canViewResults() {
      if (this.overall) return true; //Only certain rounds can be hidden;
      if (this.round.hidden) {
        return this.user && this.auth_manage;
      }
      return true;
    }
  }
}
</script>
<style scoped>


</style>