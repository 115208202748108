<script setup>

import {computed} from "vue";
import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field} from "vee-validate";
import FormCheckbox from "./FormCheckbox.vue";

const store = useTournamentStore();
const endWord = computed(() => store.style === 'paper' ? 'end' : 'starting stake')

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1)
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">{{ endWord }}s & Arrows</div>
    <div class="flex">
      <div class="flex-1">
        <div class="bs-section-header">Number of {{ endWord }}s</div>
        <Field class="bs-input-box" name="ends" v-model="store.ends" rules="required|min_value:1|max_value:100"
               type="number"/>
        <ErrorMessage class="errorText" name="ends"/>
      </div>
      <div class="flex-1 ml-6">
        <div class="bs-section-header">Arrows per {{ endWord }}</div>
        <Field class="bs-input-box" name="arrows" v-model="store.arrows" rules="required|min_value:1|max_value:10"
               type="number"/>
        <ErrorMessage class="errorText" name="arrows"/>
      </div>
      <div class="flex-1">
      
      </div>
    </div>
    <div class="mt-4 bs-section-header">{{ capitalize(endWord) }} review</div>
    <form-checkbox
      @tapped="store.accept_button = !store.accept_button"
      :active="store.accept_button"
      :title="`Require archers to review and accept their score after each ${endWord}`"/>
  </div>
</template>

<style scoped>

</style>