import Swal from "sweetalert2";

export const BsAlert = Swal.mixin({
  showConfirmButton: true,
  reverseButtons: true,
  showCancelButton: true,
  confirmButtonColor: '#62A82F',
  customClass: {
    confirmButton: 'bs-confirm-button',
  }
})

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  }
});