<template>
  <div>
    <div v-if="club.id" class="py-2">
      <div class="flex justify-end">
        <button @click="deleteClub" class="btn btn-outline-gray-600">DELETE CLUB</button>
      </div>
    </div>
    <Form @submit="submitUpdates">
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
          <label for="name">Name</label>
          <Field @blur="checkName" type="text" name="name" rules="required|min:3|max:80|clean"
                 class="bs-form-box" v-model="club.name"/>
          <span class="hintText">This is the formal name of the club.</span>
          <ErrorMessage name="name" class="errorText"/>
        </div>
        <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
          <label for="name">Contact Email</label>
          <Field @blur="checkEmail" type="text" name="email" class="bs-form-box"
                 rules="required|min:3|max:100|clean|email" v-model="club.email"/>
          <small class="hintText">
            This email will be used for any tournament notifications to and from competing archers.
          </small>
          <ErrorMessage name="email" class="errorText"/>
        </div>
        <div class="w-full md:w-1/2 px-2">
          <h2 class="text-2xl py-1">Mailing Address <span class="hintText">(Optional)</span></h2>
          <hr>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Address 1</label>
              <Field type="text" name="mailing_address1" class="bs-form-box"
                     rules="min:2|max:100|clean|punctuation" v-model="club.mailing_address.address1"/>
              <ErrorMessage name="mailing_address1" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Address 2</label>
              <Field type="text" name="mailing_address2" class="bs-form-box"
                     rules="min:2|max:100|clean|punctuation" v-model="club.mailing_address.address2"/>
              <ErrorMessage name="mailing_address2" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>City/Town</label>
              <Field type="text" name="mailing_city" class="bs-form-box" rules="min:2|max:100|clean|punctuation"
                     v-model="club.mailing_address.city"/>
              <ErrorMessage name="mailing_city" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>State/Province/Region</label>
              <Field type="text" name="mailing_state" class="bs-form-box" rules="min:2|max:100|clean|punctuation"
                     v-model="club.mailing_address.state"/>
              <ErrorMessage name="mailing_state" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Zip/Postal</label>
              <Field type="text" name="mailing_zip" class="bs-form-box" rules="min:2|max:100|clean|punctuation"
                     v-model="club.mailing_address.zip"/>
              <ErrorMessage name="mailing_zip" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Country</label>
              <Field type="text" name="mailing_country" class="bs-form-box" rules="min:2|max:100|clean|punctuation"
                     v-model="club.mailing_address.country"/>
              <ErrorMessage name="mailing_country" class="errorText"/>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 px-2 bg-blue-100">
          <h2 class="text-2xl py-1">Range Address</h2>
          <hr>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Address 1</label>
              <Field type="text" name="range_address1" class="bs-form-box"
                     rules="required|min:2|max:100|clean|punctuation"
                     v-model="club.range_address.address1"/>
              <ErrorMessage name="range_address1" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Address 2</label>
              <Field type="text" name="range_address2" class="bs-form-box" rules="min:2|max:100|clean|punctuation"
                     v-model="club.range_address.address2"/>
              <ErrorMessage name="range_address2" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>City/Town</label>
              <Field type="text" name="range_city" class="bs-form-box" rules="required|min:2|max:100|clean|punctuation"
                     v-model="club.range_address.city"/>
              <ErrorMessage name="range_city" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>State/Province/Region</label>
              <Field type="text" name="range_state" class="bs-form-box" rules="required|min:2|max:100|clean|punctuation"
                     v-model="club.range_address.state"/>
              <ErrorMessage name="range_state" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Zip/Postal</label>
              <Field type="text" name="range_zip" class="bs-form-box" rules="required|min:2|max:100|clean|punctuation"
                     v-model="club.range_address.zip"/>
              <ErrorMessage name="range_zip" class="errorText"/>
            </div>
            <div class="w-full md:w-1/2 flex flex-col px-2 mb-2">
              <label>Country</label>
              <Field type="text" name="range_country" class="bs-form-box"
                     rules="required|min:2|max:100|clean|punctuation"
                     v-model="club.range_address.country"/>
              <ErrorMessage name="range_country" class="errorText"/>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 flex">
        <input type="checkbox" class="switch" name="hide" v-model="club.hide">
        <div class="flex flex-col justify-center">
          <label class="ml-2" for="hide">
            Hide Club
            <small class="text-gray-600">
              Club can still be accessed to anyone with a link.
            </small>
          </label>
        </div>
      </div>
      <div class="flex justify-end w-full py-2">
        <button type="submit" class="btn btn-outline-blue-600">
          {{ club.id != null ? 'Update' : 'Save' }} <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
        </button>
      </div>
      <span v-if="submitErrors" class="w-full flex justify-end text-sm text-red-500">
        You must correct any errors before submitting.
      </span>
    </Form>
  </div>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "Edit",
  components: {ErrorMessage, Field, Form},
  props: ['inc_club'],
  data() {
    return {
      club: this.inc_club,
      loading: false,
      submitErrors: false,
      startingEmail: this.inc_club.email,
      message: '',
    }
  },
  methods: {
    submitUpdates() {
      this.loading = true;
      let url = `/clubs/${this.club.id ? this.club.slug : ''}`;
      let data = {club: this.club};
      if (!this.club.id) data['message'] = this.message;
      this.$axios
        [!this.club.id ? 'post' : 'put'](url, data)
      .then(({data}) => {
        if (data.success) {
          window.location.href = '/clubs/' + data.slug;
        } else {
          this.loading = false;
        }
      })
    },
    linksHtml(data) {
      let links = '<div class="flex flex-col text-center"><div>Click on the link to visit an existing clubs page.</div>';
      Object.values(data).forEach(function (club) {
        links += `<a href="${club.slug}" class="text-blue-600">${club.name}</a>`;
      });
      links += '</div>';
      return links;
    },
    checkName() {
      if (this.club.name) {
        this.$axios
            .get('/check_name', {params: {name: this.club.name}})
            .then(({data}) => {
              if (data.existing) {
                let links = this.linksHtml(data.existing);
                BsAlert.fire({
                  titleText: 'Do you really want to name your club ' + this.club.name + '?',
                  html: links,
                  confirmButtonText: 'Continue using name',
                  footer: 'To prevent confusion, try to keep your club name unique.'
                })
              }
            })
      }
    },
    codeAccepted() {
      BsAlert.fire({
        titleText: 'Code Accepted.',
        icon: 'success',
        html: 'Please continue with the form to finalize changes.',
        showCancelButton: false,
      })
    },
    handleEmailValidation() {
      this.$axios
          .get('/check_email', {params: {email: this.club.email}})
          .then(({data}) => {
            if (data.error) BsAlert.fire({title: data.error.message, showCancelButton: false});
            BsAlert.fire({
              title: 'Enter your verification code',
              html: 'This code can be found in an email sent to ' + this.club.email,
              input: 'text',
              inputAttributes: {autocapitalize: 'off'},
              confirmButtonText: 'Submit Code',
              inputValidator: (code) => {
                if (!code) return 'You need to enter the code.';
                if (code !== data.code) return 'Incorrect Code';
              }
            }).then(({value}) => {
              if (value) return this.codeAccepted();
              this.club.email = this.startingEmail;
              BsAlert.fire({
                icon: 'warning',
                html: 'Email will not be changed',
                showCancelButton: false,
              })
            });
          });
    },
    checkEmail() {
      if ((this.startingEmail !== this.club.email)) {
        BsAlert.fire({
          titleText: 'We need to verify your access to this email.',
          html: 'Email Address: ' + this.club.email,
          confirmButtonText: 'Send me an email verification',
        }).then(({dismiss}) => {
          if (!dismiss) this.handleEmailValidation();
        });
      }
    },
    deleteClub() {
      BsAlert.fire({
        titleText: 'Are you sure you want to delete this club?',
        html: 'This will completely erase the club and all associated tournaments!',
        icon: 'error',
        confirmButtonColor: '#f27474',
        confirmButtonText: 'Delete Club',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$axios.delete(`/clubs/${this.club.slug}`, {});
        },
      }).then(value => {
        if (value.value) {
          BsAlert.fire({title: 'The Club has been Deleted!', showCancelButton: false});
          window.location.href = '/clubs/';
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
