<template>
  <div @click="uploadScores"
       class="order-2 sm:order-3 text-blue-600 text-5xl flex flex-col cursor-pointer">
    <div v-if="uploadingButton === 'uploading'" key="showUploading" class="flex justify-end">
      <i class="fas fa-spin fa-spinner"></i>
    </div>
    <div v-else-if="uploadingButton === 'save'" key="showNotUploading" class="flex justify-end">
      <i class="far fa-arrow-alt-circle-up"></i>
    </div>
    <div v-else-if="uploadingButton === 'happy'" key="showHappy"
         class="flex justify-end cursor-default">
      <i class="far fa-smile"></i>
    </div>
    <div v-else-if="uploadingButton === 'offline'" key="showOffline" class="flex justify-end">
      <i class="fas fa-wifi-slash text-gray-500"></i>
    </div>
    <div v-if="offlineScores && offlineScores.length > 0" id='unsaved'
         class="text-gray-500 whitespace-nowrap text-xs">
      {{ offlineScores.length }} unsaved changes
    </div>
  </div>
</template>

<script>
import mixin from "../../mixins/scoring_mixins";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "SyncButton",
  mixins: [mixin],
  data() {
    return {
      uploading: false,
    }
  },
  computed: {
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    uploadingButton() {
      if (this.uploading) {
        return 'uploading';
      }
      if (this.offlineScores.length > 0) {
        if (!this.online) {
          return 'offline';
        }
        return 'save';
      }
      return 'happy';
    },
  },
  methods: {
    connectMessage() {
      BsAlert.fire({
        title: 'You cannot upload scores without being connected to the internet.',
        showCancelButton: false,
      })
    },
    async uploadScores() {
      if (!this.offlineScores.length) return;
      if (!navigator.onLine) return this.connectMessage();
      this.uploading = true;
      let fullCode = await this.$localForage.getItem(`${this.tournament.id}_code`);
      let code = fullCode?.id;
      let url = '/tournaments/' + this.tournament.slug + '/sync';
      this.$axios.post(url, {
        'scores': this.offlineScores,
        'groups': this.offlineAssignment,
        'competitors': this.offlineCompetitors,
        'code': code,
      }).then((response) => {
        this.uploading = false;
        if (response.data.success) {
          this.$store.dispatch('tournament/resetOffline');
        }
        if (response.data.disabled) {
          fullCode.disabled = true;
          this.$localForage.setItem(`${this.tournament.id}_code`, fullCode);
        }
      }).catch(({response}) => {
        console.log(response);
      });
    },
  }
}
</script>

<style scoped>

</style>
