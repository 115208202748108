<template>
  <div>
    <div class="mt-2" v-if="club.image">
      <div v-if="goduser" class="flex justify-end mb-2">
        <div class="flex flex-col justify-center mr-2"><span>Image is
          {{ club.image_enabled ? 'Enabled' : 'Disabled' }}</span></div>
        <button @click="enable" class="btn btn-outline-blue-600 mr-2">Enable Image</button>
        <button @click="disable" class="btn btn-outline-gray-600 mr-2">Disable Image</button>
        <button @click="deleteClub" class="btn btn-outline-red-600 mr-2">DELETE CLUB</button>
      </div>
    </div>
    <div v-cloak class="mt-3 px-3 py-6 lg:py-10 banner md:h-80" id="clubBanner"
         :style="'background-image: url(' + imgUrl + ');'">
      <h5 v-if="club.range_address && (club.range_address.city || club.range_address.state)"
          class="uppercase mb-0">
        {{ club.range_address.city ? club.range_address.city + ', ' : '' }}{{ club.range_address.state }}
      </h5>
      <h5 v-else-if="club.mailing_address && (club.mailing_address.city || club.mailing_address.state)"
          class="uppercase mb-0">
        {{ club.mailing_address.city ? club.mailing_address.city + ', ' : '' }}{{ club.mailing_address.state }}
      </h5>
      <h1 class="uppercase text-center text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl my-8">
        {{ club.name }}
      </h1>
    </div>
  </div>
</template>

<script>
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ClubImage",
  props: ['inc_club', 'goduser'],
  data() {
    return {
      club: this.inc_club,
    }
  },
  computed: {
    imgUrl() {
      if (this.club.image && (this.club.image_enabled || this.goduser)) {
        return this.club.image;
      } else {
        return 'https://bowscore-clubs.s3.amazonaws.com/defaultClubImage.jpg'
      }
    }
  },
  methods: {
    enable() {
      this.$axios
      .post('/godmode/enable_image', {club: this.club.slug})
      .then(({data}) => {
        if (data.success) {
          this.club.image_enabled = true;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    disable() {
      this.$axios
      .post('/godmode/disable_image', {club: this.club.slug})
      .then(({data}) => {
        if (data.success) {
          this.club.image_enabled = false;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    deleteClub() {
      BsAlert.fire({
        titleText: 'Delete ' + this.club.name,
        text: 'This is a big deal. Are you sure you want to do delete it?',
        icon: 'error',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.$axios
          .post('/godmode/delete_club', {club: this.club.slug})
          .then(({data}) => {
            if (data.success) {
              window.location.href = '/clubs';
            }
          }).catch(({error}) => {
            console.log(error)
          });
        },
      })
      
    }
  }
  
}
</script>

<style scoped>
#clubBanner {
  background-color: black;
  background-size: cover;
  background-position: center;
  max-height: 500px;
  color: #ffffff;
  text-shadow: 0 0 1em #000;
}
</style>
