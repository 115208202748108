<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import FormCheckbox from "./FormCheckbox.vue";

const store = useTournamentStore();

const selected = ((target) => {
  store.addTarget(target);
  store.touched();
})

const activeTarget = ((target) => {
  return store.targets.find((f) => f.id === target.id);
});

const setStyle = (style) => {
  store.style = style;
  store.touched();
}
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Targets</div>
    <div class="bs-section-header">Type (select all that apply)</div>
    <div class="flex flex-wrap">
      <div v-for="target of store.sportTargets">
        <button @click.prevent="selected(target)" class="bs-input-box"
                :class="activeTarget(target) ? 'activeBox' : ''">
          {{ target.name }}
        </button>
      </div>
    </div>
    <div class="bs-section-header">Style</div>
    <button @click.prevent="setStyle('paper')" class="bs-input-box"
            :class="store.style === 'paper' ? 'activeBox' : ''">
      Paper
    </button>
    <button @click.prevent="setStyle('3d')" class="bs-input-box" :class="store.style === '3d' ? 'activeBox' : ''">
      3D
    </button>
    <div v-if="store.targets.length > 1">
      <div class="mt-4 bs-section-header">Select at registration</div>
      <form-checkbox
        @tapped="store.toggleHideTargets()"
        :active="!store.hide_targets"
        :title="`Include target selection as part of the competitor registration process`"/>
    </div>
  
  </div>
</template>

<style scoped>
.activeBox {
  @apply text-white bg-black;
  color: #FFFFFF !important;
}

.bs-input-box {
  @apply text-black font-medium;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: auto;
}
</style>