<template>
  <div class="ml-4 mr-2">
    <input type="hidden" :value="linkUrl" id="urlHidden">
    <div>Tournament: {{ tournament.name }}</div>
    <div class="flex flex-col ml-5">
      <router-link to="/practice/" class="routerLinks" exact>
        <a>Home</a>
      </router-link>
      <router-link to="/practice/assignments" class="routerLinks">
        <a>Assignments</a>
      </router-link>
      <router-link to="/practice/score" class="routerLinks">
        <a> Score </a>
      </router-link>
      <router-link to="/practice/results" class="routerLinks">
        <a>Results</a>
      </router-link>
      <router-link to="/practice/progress" class="routerLinks">
        <a>Progress</a>
      </router-link>
      <a @click="copyLink" class="routerLinks">
        <i class="fas fa-share-square mr-2"></i> Share Link
      </a>
      <a @click="clone" class="routerLinks">
        <i class="fas fa-clone mr-2"></i>Clone Tournament
      </a>
    </div>
  </div>
</template>

<script>

import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "PracticeNavigation",
  props: {'tournament': Object, 'user': Object},
  data() {
    return {
      uploading: false,
      screenHeight: 300,
      ready: this.inc_ready,
    }
  },
  
  computed: {
    device_id() {
      return this.$parent.device_id;
    },
    offlineAssignments() {
      return this.$store.getters['tournament/getOfflineAssignments'];
    },
    offlineScores() {
      return this.$store.getters['tournament/getOfflineScores'];
    },
    offlineCompetitors() {
      return this.$store.getters['tournament/getOfflineCompetitors'];
    },
    changes() {
      return this.offlineAssignments.length + this.offlineScores.length + this.offlineCompetitors.length
    },
    linkUrl() {
      return window.location.href
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  methods: {
    clone() {
      if (this.online) {
        BsAlert.fire({
          title: 'Clone Tournament?',
          text: "This will duplicate the settings and competitors, but no scores will carry over.",
          icon: 'info',
          confirmButtonText: 'Yes, clone it!'
        }).then((result) => {
          if (result.value) {
            window.location = '/practice/' + this.tournament.slug + '/clone';
          }
        })
      } else {
        BsAlert.fire({
          icon: 'info',
          text: 'This feature is not available offline.',
          showCancelButton: false,
        })
      }
      
    },
    copyLink() {
      var url = document.getElementById("urlHidden");
      url.setAttribute('type', 'text');
      url.select();
      document.execCommand('copy');
      url.setAttribute('type', 'hidden');
      BsAlert.fire({
        titleText: 'A link to this tournament has been copied to the clipboard',
        html: 'You may now paste this link in an email or message to share the tournament.',
        footer: 'Anyone with the link can score and edit this tournament. Please share it wisely.',
        customClass: {
          footer: 'text-red-600'
        },
        showCancelButton: false,
      })
    },
    uploadChanges() {
      if (this.changes) {
        if (this.online) {
          this.uploading = true;
          let url = '/tournaments/' + this.tournament.slug + '/sync';
          this.$axios.post(url, {
            'scores': this.offlineScores,
            'groups': this.offlineAssignments,
            'competitors': this.offlineCompetitors
          })
          .then(({data}) => {
            this.uploading = false;
            // this.$store.commit('tournament/SET_TOURNAMENT', {
            //     'tournament': data.tournament
            // });
            this.$store.dispatch('tournament/resetOffline')
          })
          .catch(({response}) => {
            console.log(response);
          });
        } else {
          this.connectMessage()
        }
      }
    },
    connectMessage() {
      BsAlert.fire({
        title: 'You cannot sync changes without being connected to the internet.',
        showCancelButton: false,
      })
    },
  }
}
</script>

<style scoped>
.routerLinks {
  @apply py-1
}

.active {
  @apply text-blue-400;
}
</style>
