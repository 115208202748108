<script setup>
import {onMounted, ref} from "vue";
import axios from 'axios';
import CardSettings from "./CardSettings.vue";
import {useDashboardStore} from "../../../stores/DashboardStore";

let archers = ref([]);

const loading = ref(true);

const props = defineProps(['user']);
const store = useDashboardStore();

onMounted(() => {
  axios.get('/dashboard/archers').then(({data}) => {
    if (data['success']) {
      archers.value = data['list'];
    }
    loading.value = false;
  }).catch((e) => {
    loading.value = false;
    store.axiosError(e);
  })
})


const registrations = (archer) => {
  let count = (archer?.user?.upcoming_tournaments ?? []).length;
  if (!count) return '';
  return `${count} registration${count > 1 ? 's' : ''}`;
}

</script>

<template>
  <div class="bs-shadow-card cardStyle">
    <card-settings name="archers"/>
    <div class="flex justify-between">
      <div class="w-full">
        <div class="bs-card-header">Archers you manage</div>
        <div>
          <a :href="`/profile/${user.slug}/archers`" v-for="archer of archers" class="itemRow sm:flex-row">
            <div class="label">{{ archer.name }}</div>
            <div class="text ">{{ archer.email }}</div>
            <div class="text">{{ registrations(archer) }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardStyle {
  @apply w-full relative mb-4;
  padding-top: 32px;
  padding-bottom: 38px;
}

.itemRow {
  @apply mb-4 flex flex-col;
  cursor: pointer;
}

.label {
  @apply font-bold text-black pr-1;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
  flex: 2;
}

.text {
  @apply font-medium text-stone-400 pr-1;
  flex: 2;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
}

</style>