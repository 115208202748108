<template>
  <div class="m-4 card card-blue-700">
    <div class="card-header ">
      <div class="flex justify-between">
        <div>
          {{ ladder.name }}:
          <div v-if="restrictions.length === 0 || restrictions[0].length === 0">All Competitors</div>
          <div v-for="restriction in restrictions">
            <span v-for="(v, k) in restriction" class="mr-2">{{ k }}:&nbsp;{{ v }}.</span>
          </div>
          <div v-if="ladder.info">
            {{ ladder.info }}
          </div>
        </div>
        <div>
          <div v-if=!ladder.shoot_out @click="clearAll()" class="btn btn-outline-blue-600">
            Clear Ladder Assignments
          </div>
        </div>
      </div>
    </div>
    <div v-if="ladder.shoot_out" class="card-body px-0" :style="`overflow:scroll;`">
      <div class="flex flex-col sm:flex-row justify-between">
        <div>
          <div class="m-2">Order competitors from best (1) to worst ({{ ladder.start_ladder }})</div>
          <div class="m-2 hintText">Shoot-out will begin with {{ ladder.start_ladder }} vs.
            {{ ladder.start_ladder - 1 }}. The winner will compete against {{ ladder.start_ladder - 2 }} and repeat this
            until the winner competes against 1.
          </div>
        </div>
        <div>
          <form-switch
            @toggled="showUnassigned = !showUnassigned"
            :model="showUnassigned"
            :id="'show_unassigned'"
            :label="'Unassigned Only'"
            :subLabel="'Restrict the dropdown competitor list to unassigned competitors only when editing a position.'">
          </form-switch>
        </div>
      </div>
      
      <div class="flex flex-col sm:flex-row">
        <div v-if="isManager" class="order-1 sm:order-2" style="flex:1;">
          <div @click="clearAll()" class="btn btn-outline-blue-600 m-1">
            Clear Assignments
          </div>
          <div @click="randomize()" class="btn btn-outline-blue-600 m-1">
            Randomize Assignments
          </div>
        </div>
        <div class="order-2 sm:order-1" style="flex:5;">
          <div v-for="count in ladder.start_ladder" class="w-full mr-4 flex flex-col justify-center">
            <shoot-out-assignment-selector
              :key="`${ladder.id}_${count}_shootout_${refreshed}`"
              :ladder="ladder"
              :tournament-in="tournamentIn"
              @forceUpdate="forceUpdate"
              :assignment-in="shootOutAssigned(count)"
              :number="count"
              :show-unassigned="showUnassigned"
              :ladder-competitors="competitors"/>
          </div>
        </div>
      </div>
      <detailed-shoot-out-assignments
        :ladder="ladder"
        :user="user"
        @forceUpdate="forceUpdate"
        :competitors="competitorsIn"
        :tournament-in="tournamentIn"/>
    </div>
    <div v-else class="card-body px-0" :style="`overflow:scroll;`">
      <div class="absolute w-full" style="padding-right:2px;">
        <div style="height:45px;"></div>
        <div
          v-for="count in ladder.end_ladder"
          :style="`height: ${height(columns(ladder) - 1, ladder)}px;`"
          :class="count % 2 === 0 ? 'bg-blue-100' : 'bg-blue-200'"/>
      </div>
      <div class="flex relative px-2" style="z-index:10;" :style="`width:${ladderCounts(ladder).length * 330}px`">
        <div v-for="(starting, step) in ladderCounts(ladder)" class="flex-1">
          <div style="height:45px;" class="flex justify-center">
            <div style="margin-right:17px;" class="flex justify-center">
              <button @click="autoAssign(step)" class="btn btn-blue-600">Auto Assign Step {{ step + 1 }}</button>
              <i v-if="hasAssignments(step)"
                 class="mx-2 fa-2x fas fa-trash-alt align-middle"
                 @click="clearStep(step)"
                 style="line-height:45px;"></i>
            </div>
          </div>
          <div
            v-for="(number, numberIndex) in starting"
            class="mr-4 flex flex-col justify-center"
            :style="`height:${height(step, ladder)}px;`">
            <bracket-assignment-selector
              :key="`${ladder.id}_${step}_${number}`"
              @assigned="assigned"
              :assignment-in="assignment(step, number)"
              :ladder="ladder"
              :step="step"
              :tournament-slug="tournamentIn.slug"
              :number="number"
              :number-index="numberIndex"
              :ladder-competitors="competitors"/>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>
<script>
import BracketAssignmentSelector from "./BracketAssignmentSelector.vue";
import ShootOutAssignmentSelector from "./ShootOutAssignmentSelector.vue";
import FormSwitch from "../partials/FormSwitch.vue";
import DetailedShootOutAssignments from "./DetailedShootOutAssignments.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "LadderAssignmentCard",
  components: {DetailedShootOutAssignments, FormSwitch, ShootOutAssignmentSelector, BracketAssignmentSelector},
  props: ['ladder', 'index', 'restrictions', 'competitorsIn', 'tournamentIn', 'user'],
  data() {
    return {
      assignedList: [],
      rowHeight: 50.0,
      assignments: this.tournamentIn.ladder_assignments,
      refreshed: 0,
      showUnassigned: true,
      detailed: false
    }
  },
  computed: {
    isManager() {
      return this.user && this.user.auth_manage;
    },
    competitors() {
      let list = this.competitorsIn;
      return list;
    }
  },
  watch: {
    tournamentIn(newVal) {
      this.assignments = newVal.ladder_assignments;
      this.refreshed++;
    }
  },
  methods: {
    forceUpdate() {
      this.$emit('forceUpdate');
    },
    clearAll() {
      BsAlert.fire({
        titleText: `Really clear all assignments for ladder ${this.ladder.name}?`,
        icon: 'warning',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await this.$axios.post(`/tournaments/${this.tournamentIn.slug}/clear_ladder_assignments`, {
            ladder: this.ladder.id,
          });
          this.$emit('forceUpdate');
          await this.getAssignments();
        }
      })
    },
    clearStep(step) {
      BsAlert.fire({
        titleText: `Really clear all assignments for Step ${step + 1}?`,
        icon: 'warning',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await this.$axios.post(`/tournaments/${this.tournamentIn.slug}/clear_ladder_assignments`, {
            ladder: this.ladder.id,
            step: step,
          });
          await this.getAssignments();
        }
      })
    },
    async getAssignments() {
      await this.$axios.get(`/tournaments/${this.tournamentIn.slug}/ladder_assignments`,
        {params: {'ladder': this.ladder.id}}).then((response) => {
        this.assignments = response.data.list;
      });
    },
    hasAssignments(step) {
      let exists = this.assignments.some(function (a) {
        return a['step'] === step;
      })
      return exists
    },
    ladderCounts() {
      let list = [];
      let start = this.ladder.start_ladder;
      let end = this.ladder.end_ladder;
      while (start >= end) {
        list.push(start);
        start = start / 2;
      }
      return list;
    },
    assignment(step, number) {
      let start = number;
      let half = number / 2.0;
      let up = Math.ceil(half);
      let position = start % 2 === 1 ? 'A' : 'B';
      let assignment = this.assignments.find(function (a) {
        return a['step'] === step && a['bale_position'] === position && a['bale'] === up;
      })
      return assignment;
    },
    shootOutAssigned(count) {
      let worst = this.ladder.start_ladder - 2;
      let step = worst - count + 1;
      let pos = 'A'
      let bale = 1;
      if (count > worst) {
        step = 0;
        if (count === worst + 2) bale = 2;
      }
      let ladderId = this.ladder.id;
      let assignment = this.assignments.find(function (a) {
        return a['ladder_id'] === ladderId && a['step'] === step && a['bale_position'] === pos && a['bale'] === bale;
      })
      return assignment;
    },
    autoAssign(step) {
      BsAlert.fire({
        titleText: `Auto Assign for ${this.ladder.name} step ${step + 1}?`,
        html: 'This will replace any existing assignments for the selection.',
        icon: 'warning',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder`, {
            'ladder': this.ladder,
            'step': step
          });
          await this.getAssignments();
        }
      })
    },
    randomize() {
      BsAlert.fire({
        titleText: `Randomly assign positions for ${this.ladder.name}?`,
        html: 'This will replace any existing assignments for the selection.',
        icon: 'warning',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          let list = this.competitors.map(m => m.id);
          await this.$axios.post(`/tournaments/${this.tournamentIn.slug}/random_ladder_assignments`, {
            'ladder': this.ladder,
            'ids': list,
          });
          this.$emit('forceUpdate');
        }
      })
    },
    heightMatrix(ladder) {
      let list = [];
      let number = this.rowHeight;
      for (let column = 0; column < this.columns(ladder); column++) {
        list.push(number);
        number = number * 2;
      }
      return list;
    },
    assigned(competitorId) {
      if (this.assignedList.includes(competitorId)) {
        let list = this.assignedList;
        list = list.filter((f) => {
          return f !== competitorId;
        })
        this.assignedList = list;
      } else {
        this.assignedList.push(competitorId);
      }
    },
    height(column, ladder) {
      return this.heightMatrix(ladder)[column];
    },
    columns(ladder) {
      return this.ladderCounts(ladder).length;
    },
  },
}
</script>

<style scoped>

</style>