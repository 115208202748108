import router from './routes';
import {createApp} from 'vue/dist/vue.esm-bundler';
import {store} from './store'; // 715mb
import {defineRule} from 'vee-validate';
import {required, email, min, max, min_value, max_value} from '@vee-validate/rules';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import {Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';

import VueMultiselect from 'vue-multiselect';
import draggable from 'vuedraggable'
import VueHtmlToPaper from 'vue-html-to-paper';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Lang from 'lang.js';
import {createPinia} from "pinia";
import VueCookies from 'vue-cookies'
import VueSocialSharing from 'vue-social-sharing';
import Dropdown from "./components/partials/Dropdown.vue";
import Filter from "bad-words";
import axios from 'axios';
import localforage from 'localforage';
import moment from "moment";
import {useTournamentStore} from "./stores/TournamentStore";
import {BsAlert} from "./mixins/swal_mixins";


let default_locale = window.default_language;
let fallback_locale = window.fallback_locale;
let messages = window.messages;
const pinia = createPinia()


const app = createApp({
  el: '#app',
  mounted() {
    window.addEventListener('online', function () {
      axios.get('/online').then((data) => {
        store.dispatch('tournament/setOnline', true);
      }).catch(() => {
        store.dispatch('tournament/setOnline', false);
      });
    });
    window.addEventListener('offline', function () {
      axios.get('/online').then((data) => {
        store.dispatch('tournament/setOnline', true);
      }).catch(() => {
        store.dispatch('tournament/setOnline', false);
      });
    });
    waitForStorageToBeReady().then(() => {
      this.$store.state.storageReady = true;
      if (!store.getters['device/getDevice']) {
        store.dispatch('device/setDevice');
      }
      if (!this.tournamentSlug && store.getters['tournament/getTournament']) {
        store.commit('tournament/CLEAR_TOURNAMENT');
      }
      this.setOnline();
    });

  },
  computed: {
    sponsorAd() {
      return store.getters['sponsor/getSponsor']
    },
    tournamentSlug() {
      let path = window.location.pathname.split('/');
      if (path.length > 2 && (path[1] === 'tournaments' || path[1] === 'practice')) {
        return path[2];
      }
      return null
    },
    locked() {
      if (this.tournamentSlug) {
        return store.getters['tournament/getLocked'](this.tournamentSlug);
      } else {
        return false
      }
    },
  },
  methods: {
    setOnline() {
      if (navigator.onLine !== false) {
        store.commit('tournament/SET_ONLINE', true);
      } else {
        axios.get('/online').then((data) => {
          store.commit('tournament/SET_ONLINE', true);
        }).catch(() => {
          store.commit('tournament/SET_ONLINE', false);
        });
      }
    }
  },
}).use(router).use(store).use(pinia);


// Tell Vue to use the plugin
app.component('draggable', draggable);
app.component('VueMultiselect', VueMultiselect);
app.component('dropdown', Dropdown);
app.component('VueDatePicker', VueDatePicker);
app.use(VueCookies);
app.use(VueSocialSharing);
app.component(Cropper);


router.afterEach((to, from, next) => {
  const store = useTournamentStore();
  if (to.name === 'register' || to.name === 'editTournament') {
    store.setBanner('formEdit');
  } else {
    if (!location.pathname.includes('create')) store.setBanner(null);
  }
})

pinia.use(({store}) => {
  store.router = router;
})


const isDev = process.env.NODE_ENV !== "production";
app.config.devtools = isDev;
app.config.performance = isDev;
app.config.productionTip = false;


router.beforeEach((to, from, next) => {
  if (window.location.pathname.startsWith('/practice')) {
    let allowed = [
      '/practice/results',
      '/practice/results/team',
      '/practice/results/club',
      '/practice/',
      '/practice',
      '/practice/assignments',
      '/practice/progress',
      '/practice/score',
      '/reshoots',
      '/score',
    ];
    if (allowed.includes(to.path)) {
      return next();
    } else {
      if (to.path === '/') {
        return next(false)
      }
      BsAlert.fire({title: 'Invalid URL'}).then(() => {
        return next(false);
      });
    }
  } else {
    let safeRoutes = ['/', '/score', '/score_ladder', '/results', '/assignment', '/progress', '/complete', '/logs', '/results/team', '/results/club'];
    if (safeRoutes.includes(to.path)) {
      return next();
    }
    if (to.path.startsWith('/competitors/')) {
      return next();
    }
    localforage.getItem('vuex').then((vuex) => {
      let user = null;
      if (vuex && vuex.user) {
        user = vuex.user;
      }
      if (!(user && user.user)) {
        let slug = '';
        let path = window.location.pathname.split('/');
        if (path.length > 2 && (path[1] === 'tournaments' || path[1] === 'practice')) {
          slug = path[2];
        }
        axios.get('/get_user/' + slug).then(({data}) => {
          user = data.user;
          if (user) {
            safeRoutes = ['/group', '/register', '/assignment'];
            if (!user.auth_manage) {
              if (safeRoutes.includes(to.path)) {
                return next()
              } else {
                BsAlert.fire({
                  title: 'That page is only available to administrators. Please contact the club owner to gain access.',
                  showCancelButton: false,
                }).then(() => {
                  return next('/');
                });
              }
            } else {
              return next();
            }
          } else {
            let remember = window.location.origin + window.location.pathname + '#' + to.fullPath.slice(1);
            let exp = moment().add(5, 'minutes');
            exp = new Date(exp).toUTCString();
            document.cookie = 'rememberpage = ' + remember + '; expires=' + exp + ';path=/';

            BsAlert.fire({
              html: '<div class="text-left">Registration and management requires a free BowScore account. That way we can keep track of your events and scores and remember your default choices. <br>' +
                '<br>' +
                'If you have an account, <a href="/login" class="text-blue-600 cursor-pointer">log in here</a>.<br>' +
                'If you need an account, <a href="/login" class="text-blue-600 cursor-pointer">create one here</a>.<br>' +
                '<br>' +
                'Thanks! </div>',
              icon: "info",
              cancelButtonText: 'Create Account',
              cancelButtonColor: '#3085d6',
              showCloseButton: true,
              confirmButtonText: 'Log In',
            }).then(({dismiss, value}) => {
              if (value) {
                return window.location.href = '/login';
              }
              if (dismiss === 'cancel') {
                return window.location.href = '/register';
              }
              return next('/');
            });
          }
        });
      } else {
        safeRoutes = ['/group', '/register', '/assignment'];
        if (!user.user.auth_manage) {
          if (safeRoutes.includes(to.path)) {
            return next()
          } else {
            BsAlert.fire({
              showCancelButton: false,
              title: 'That page is only available to administrators. Please contact the club owner to gain access.'
            }).then(() => {
              return next('/');
            });
          }
        } else {
          return next();
        }
      }
    });
  }
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * e.g. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

const components = import.meta.glob('./components/**/*.vue', {eager: true});
Object.entries(components).forEach(([path, definition]) => {
  // Get name of component, based on filename
  // "./components/Fruits.vue" will become "Fruits"
  const componentName = path.split('/').pop().replace(/\.\w+$/, '')
  if (definition.default != null) {
    // Register component on this Vue instance
    app.component(componentName, definition.default)
  } else {
    debugger;
  }
})

const axiosInstance = axios.create({
  withCredentials: true,
})

app.config.globalProperties.$axios = {...axiosInstance};
app.config.globalProperties.$localForage = localforage;
app.config.globalProperties.$trans = new Lang({messages, locale: default_locale, fallback: fallback_locale});


const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ]
};
app.use(VueHtmlToPaper, options);

let filter = new Filter();
filter.removeWords('hell', 'hells', 'butt', 'crap', 'sex', 'screwing', 'screw', 'cox', 'willy', 'willies', 'fanny', 'god');

defineRule('clean', (value) => {
  if (filter.isProfane(value)) return 'Inappropriate language is not allowed.';
  return true;
});

defineRule('full_name', (value) => {
  if (value == null || value.split(' ').length > 1) return true;
  return 'You must have a first and last name, separated by a space.';
})

defineRule('punctuation', (value) => {
  if (/[^~`!@#$%^&*()+={\[}\];'",<.>/?|\\]$/.test(`${value}`)) return true;
  return '"~`!@#$%^&*()+={}[]\';\",.?|<>/\\" characters are not allowed';
})

defineRule('json', value => {
  if (/^[0-9A-Z :.?/()\-_]*$/i.test(`${value}`)) return true;
  return 'Certain characters are not allowed';
})
defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true;
  }
  return 'Passwords must match';
});

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('min_value', min_value);
defineRule('max_value', max_value);


// Object.keys(AllRules).forEach(rule => {
//   defineRule(rule, AllRules[rule]);
// });


if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: '6267f040e8bc7cfbfc2474a924f871c6',
    plugins: [new BugsnagPluginVue()],
  });
}

const waitForStorageToBeReady = async (to, from) => {
  await store.restored
}

app.mount('#app')


