<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {Field} from "vee-validate";
import {computed, onMounted, watch} from "vue";

const store = useCompetitorStore();

function disabledTime(time, archer) {
  if (store.possibleRestrictions.length === 0) {
    let space = hasSpace(time.id);
    return !space;
  }
  let enabled = times(archer).includes(`${time.id}`);
  return !enabled;
}

function hasSpace(time) {
  for (let loc in store.groupTimeCounts[time]) {
    for (let bale in store.groupTimeCounts[time][loc]) {
      if (store.groupTimeCounts[time][loc][bale] > 0) {
        return true;
      }
    }
  }
  return false;
}

function times(archer) {
  let list = [];
  if (Object.keys(store.groupAvailable).length === 0) return list;
  if (!(archer.id in store.groupAvailable)) return list;
  for (let timeId of Object.keys(store.groupAvailable[archer.id])) {
    let added = false;
    let item = store.groupAvailable[archer.id][timeId];
    for (let l in item.locations) {
      let loc = item.locations[l];
      if (loc.allowed === false) break;
      for (let b in loc.bales) {
        let bale = item.locations[l].bales[b];
        if (bale.allowed === true) {
          let spots = Object.keys(store.groupUpdatedCounts).length ? store.groupUpdatedCounts[timeId][loc.location][parseInt(b) + 1] : 0;
          if (spots > 0) {
            list.push(timeId);
            added = true;
            break;
          }
        }
      }
      if (added) break;
    }
  }
  return list;
}

function canWaitlist(archer) {
  for (let time of store.times) {
    let disabled = disabledTime(time, archer);
    if (disabled) return true;
  }
  return false;
}

const hasFullTime = computed(() => {
  for (let time of store.times) {
    for (let archer of store.selectedArchers) {
      let disabled = disabledTime(time, archer);
      if (disabled) return true;
    }
  }
  return false;
});

onMounted(() => {
  store.setTimeCounts();
})

const invalid = computed(() => {
  if (!store.submitted) return false;
  return !store.justGroupTimesComplete;
});


watch(() => store.groupTimes, (newValue, oldValue) => {
  let list = JSON.parse(JSON.stringify(store.groupTimeCounts));
  for (let archerId of Object.keys(store.groupTimes)) {
    let time = store.groupTimes[archerId];
    if (time !== null && time !== 'Waitlist') {
      if (Object.keys(store.groupAvailable).length === 0) break;
      if (!(archerId in store.groupAvailable)) continue;
      let item = store.groupAvailable[archerId][time];
      archerLoop:
        for (let l in item.locations) {
          let loc = item.locations[l];
          for (let b in loc.bales) {
            let bale = item.locations[l].bales[b];
            if (bale.allowed === true) {
              let count = list[time][loc.location][parseInt(b) + 1];
              if (count > 0) {
                list[time][loc.location][parseInt(b) + 1]--;
                break archerLoop;
              }
            }
          }
        }
    }
  }
  store.groupUpdatedCounts = list;
}, {deep: true})

</script>

<template>
  <div v-if="store.times.length > 1" class="bs-card" :class="invalid ? 'invalid' : ''" id="groupTimeCard">
    <div class="bs-section-header">Line Times</div>
    <div v-for="archer of store.selectedArchers" class="flex mb-2">
      <div class="bs-input-box flex-2 mr-4">{{ archer.name }}</div>
      <Field as="select"
             v-model="store.groupTimes[archer.id]"
             :name="`${archer.name} (Archer ID:${archer.id}) Time`"
             class="bs-input-box flex-2">
        <option v-for="time of store.times"
                :disabled="disabledTime(time, archer)"
                :value="time.id"
                :selected="store.groupTimes[archer.id] === time.id">
          {{ time.time }}
        </option>
        <option v-if="canWaitlist(archer)" :value="'Waitlist'">Waitlist</option>
      </Field>
    </div>
    <div v-if="hasFullTime" class="text-stone-400">
      One or more line times are either full, or not allowed for your registration selection. Waitlisted competitors
      must be manually approved by the tournament director before participating in the event.
    </div>
  </div>
</template>

<style scoped>
.invalid {
  border-color: red;
}
</style>