<script setup>

import {ref, computed} from "vue";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {useAssignmentStore} from "../../stores/assignmentStore";

const props = defineProps(['tournament'])
const store = useAssignmentStore();

const options = computed(() => {
  let t = props.tournament;
  let list = [];
  if (t.targets.length > 1) list.push({name: 'Target', value: 'target'})
  for (let item of t.regform) {
    list.push({name: item.name, value: item.option})
  }
  return list;
})
</script>

<template>
  <div>
    <div v-if="store.show">
      Registration details:
      <VueMultiselect
        v-model="store.shown"
        :searchable="false"
        :options="options"
        :hideSelected="false"
        label="name"
        track-by="name"
        :multiple="true"
        class="multiselect_blue"/>
    </div>
    <div v-else class="btn btn-blue-600" @click="store.show = true">
      Show registration details
    </div>
  
  
  </div>
</template>

<style scoped>

</style>