<script setup>
import {useAssignmentStore} from "../../stores/assignmentStore";
import {computed} from 'vue';

const props = defineProps(['competitor', 'tournament'])
const store = useAssignmentStore();

const targets = computed(() => {
  let list = [];
  for (let target of props.tournament.targets) {
    list[target.id] = target.name;
  }
  return list;
})

function formValue(option) {
  if (option === 'target') {
    if (props.competitor?.target_id == null) return '-';
    return targets.value[props.competitor.target_id] ?? '-';
  } else {
    let formItem = props.competitor?.regform.find(f => f.option === option);
    return formItem?.name ?? '-';
    
  }
}
</script>

<template>
  <div v-for="option of store.shown" class="text-center text-gray-500 customBorder border-gray-500 ">
    {{ formValue(option.value) }}
  </div>
</template>

<style scoped>
.customBorder {
  margin-top: -4px;
  padding-top: 2px;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-left: 1px solid;
}
</style>