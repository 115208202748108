<template>
  <div>
    <div v-if="competitor">
      <div v-if="scoringLocked">
        User scoring for {{ competitor.name }} is no longer available.
        <a class="btn-sm btn-blue-600" :href="`/tournaments/${tournament.slug}`">Return to Tournament</a>
      </div>
      <div v-else-if="minutesRemaining != null">
        Time Left: {{ secondsRemaining ? `${secondsRemaining} seconds` : `${minutesRemaining} minutes` }}
      </div>
      <div v-if="!scoringLocked && !scoringUnavailable">
        <scoring-component
          :tournamentIn="tournament"
          :inc_competitor="competitor"
          :user="user"/>
      </div>
      <div v-if="scoringUnavailable">
        <div>{{ scoringUnavailable }}</div>
      </div>
    </div>
    <div v-else class="text-center flex-grow py-32">
      <div v-if="loadingMessage">
        <h1 class="mt-4 text-5xl">{{ loadingMessage }}</h1>
        <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
      </div>
      <div v-else class="mt-4 text-5xl sm:w-2/3 mx-auto">
        There was an error loading the tournament.
      </div>
    </div>
  </div>

</template>

<script>
import ScoringComponent from "./ScoringComponent.vue";
import {useDisplayStore} from "../../stores/DisplayStore";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "SingleScore",
  components: {ScoringComponent},
  props: ['tournamentIn', 'user'],
  data() {
    return {
      minutesRemaining: null,
      secondsRemaining: null,
      scoringLocked: false,
      loadingMessage: 'Loading Details',
      scoring: null,
      round: null,
      onlineTournament: null,
      target: null,
      arrow: null,
      end: null,
      uuid: this.$route.params.uuid,
      competitor: null,
      store: useDisplayStore(),
    }
  },
  watch: {
    minutesRemaining(newVal) {
      if (newVal > 1) {
        setTimeout(() => {
          this.minutesRemaining--;
        }, (60 * 1000))
      } else {
        this.secondsRemaining = 10;
      }
    },
    secondsRemaining(newVal) {
      if (newVal > 0) {
        setTimeout(() => {
          this.secondsRemaining--;
        }, 1000);
      } else {
        this.scoringLocked = true;
        BsAlert.fire({title: 'Time Up', showCancelButton: false});
      }
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    scoringUnavailable() {
      let message = null;
      let assigned = true;
      let approved = true;
      this.competitor.assignments.forEach(f => {
        if (!f.bale || !f.line_time || !f.location || !f.position) {
          assigned = false;
        }
        if (!f.approved) {
          approved = false;
        }
      })
      if (!approved) {
        message = 'You have not yet been approved to compete in this tournament.';
      } else if (!assigned) {
        message = 'You may not score until the tournament director has assigned your position.'
      }
      return message ? message : false;
    }
  },
  methods: {
    setTimer() {
      let time = this.tournament.time_limit;
      let now = new Date();
      let nowTime = now.getTime();
      let offset = now.getTimezoneOffset() * 60 * 1000;
      let start = new Date(this.competitor.scoring_started).getTime();
      let minutesPassed = Math.floor((nowTime + offset - start) / (60 * 1000));
      let remaining = time - minutesPassed;
      if (remaining > 0) {
        this.scoringLocked = false;
        this.minutesRemaining = remaining > 0 ? remaining : 0;
      } else {
        this.scoringLocked = true;
      }
    },
  },
  destroyed() {
    this.store.$patch({
      restrictedBales: [],
      restrictedCompetitors: [],
      restrictedLocations: [],
      restrictedRounds: [],
      restrictedTimes: [],
    })
  },
  created() {
    let uuid = this.$route.params.uuid;
    if (this.tournament.time_limit) {
      this.$axios.get(`/competitors/${uuid}`).then(({data}) => {
        this.competitor = data.competitor;
      }).then(() => {
        if (this.competitor.scoring_started) {
          this.setTimer();
        } else if (this.scoringAvailable) {
          BsAlert.fire({
            icon: "warning",
            html: `This Tournament has a ${this.tournament.time_limit} minute time limit. The timer will start as soon as you click "Begin Scoring".`,
            confirmButtonText: 'Begin Scoring',
            cancelButtonText: 'Go back, I am not ready yet.',
          }).then(({dismiss}) => {
            if (dismiss) {
              window.location.href = `/tournaments/${this.tournament.slug}`;
            } else {
              this.$axios.post(`/start_timer/${this.competitor.uuid}`).then(({data}) => {
                if (data.success) {
                  this.competitor.scoring_started = data.start_time;
                  this.setTimer();
                } else if (data.error) {
                  BsAlert.fire({
                    icon: "error",
                    html: data.error.message,
                    showCancelButton: false,
                  }).then(() => {
                    window.location.href = `/tournaments/${this.tournament.slug}`;
                  })
                }
              }).catch((response) => {
                BsAlert.fire({
                  icon: 'error',
                  showCancelButton: false,
                  html: response.message,
                }).then(() => {
                  window.location.href = `/tournaments/${this.tournament.slug}`;
                })
              })
            }
          })
        }
      })
    } else {
      this.$axios.get(`/competitors/${uuid}`).then(({data}) => {
        this.competitor = data.competitor;
      });
    }
  }
}
</script>

<style scoped>

</style>
