<template>
  <div v-if="amountDue > 0" class="card card-red-700">
    <div class="card-header">
      Bowscore Fee
    </div>
    <div class="card-body flex justify-center">
      <table class="">
        <tbody>
        <tr>
          <td></td>
          <td>{{ scoredCompetitors }}</td>
          <td>Scored Competitors</td>
        </tr>
        <tr style="border-bottom: black 1px solid;">
          <td>-</td>
          <td>{{ feesCollected }}</td>
          <td>Fees Paid by Competitors</td>
        </tr>
        <tr class="">
          <td></td>
          <td>{{ scoredCompetitors - feesCollected }}</td>
          <td> Unpaid Fee X $2.00 = ${{ amountDue }}.00 <a
            :href="'/tournaments/' + tournament.slug + '/invoice'"
            class="btn px-1 py-0 btn-outline-green-500">Pay</a></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TournamentFees",
  props: ['tournament'],
  data() {
    return {}
  },
  computed: {
    scoredCompetitors() {
      return this.tournament.scoredCompetitors;
    },
    feesCollected() {
      return this.tournament.paidCompetitors;
    },
    amountDue() {
      return ((this.scoredCompetitors - this.feesCollected) * 2);
    },
  }
}
</script>

<style scoped>

</style>
