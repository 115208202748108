<script setup>

import {Toast} from "../../mixins/swal_mixins";

const props = defineProps(['copyValue', 'message'])
const share = () => {
  let value = document.getElementById("hiddenValue");
  value.setAttribute('type', 'text');
  value.select();
  navigator.clipboard.writeText(value.value);
  value.setAttribute('type', 'hidden');
  Toast.fire({title: props.message, html: props.copyValue, icon: "success"})
}
</script>

<template>
  <div @click.prevent="share">
    <input type="hidden" :value="copyValue" id="hiddenValue">
    <slot/>
  </div>
</template>

<style scoped>

</style>