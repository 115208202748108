<template>
  <div class="flex-grow overflow-x-hidden sm:m-2">
    <div v-if="teams">
      <team-club-results-links :teams="teams"/>
      <div class="bg-blue-100 shadow-inner">
        <div class="flex flex-col">
          <div class="mx-auto mt-2 w-full md:w-3/4 lg:w-1/2 max-w-lg bs-form-box flex">
            <span class="h-center"><i class="fas fa-search"></i></span>
            <input type="text" class="flex-grow pl-2 mx-2" v-model="search">
            <div v-if="search !== ''" class="h-center" @click="search = ''">
              <span class=""><i class="fas fa-times"></i></span>
            </div>
          </div>
          <div class="flex flex-wrap justify-center">
            <div>
              <div @click='overallSelected' class="btn m-2"
                   :class="overall?'btn-blue-600':'btn-outline-blue-600'">Overall
              </div>
            </div>
            <dropdown v-if="tournament.rounds.length > 1" class="m-2" width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn btn-secondary dropdown-toggle whitespace-nowrap">
                  Round{{ overall ? '' : ': ' + round.name }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="round in tournament.rounds" class="dropdown-item" href=""
                 @click.prevent="roundSelected(round)">{{ round.name }}</a>
            </dropdown>
            <div v-else>
              <div @click='roundSelected(tournament.rounds[0])' class="btn m-2"
                   :class="overall?'btn-outline-blue-600':'btn-blue-600'">Breakdown
              </div>
            </div>
            <div v-if="divisionArray">
              <div class="btn-group m-2">
                <div v-if="selectedDivision" @click="cancelDivision"
                     class="btn btn-outline-secondary">X
                </div>
                <dropdown width="180px">
                  <template v-slot:trigger>
                    <button type="button"
                            class="btn btn-secondary dropdown-toggle whitespace-nowrap">
                      Division{{ selectedDivision ? ': ' + selectedDivision.label : '' }}
                      <i class="fas fa-caret-down"></i>
                    </button>
                  </template>
                  <a v-for="division in divisionArray" class="dropdown-item" href=""
                     @click.prevent="divisionSelected(division)">{{ division.label }}</a>
                </dropdown>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <h3 class="my-2">
        <input type="hidden" :value="fullSelectionLink" id="urlSelections">
        <div class="flex justify-between share-buttons">
          <div class="text-2xl">{{ title }}</div>
          
          <div>
            <button id="printButton" class="btn btn-outline-blue-600" @click="printResults">
              Print Results
            </button>
            <button id="shareButton" class="btn btn-outline-blue-600" @click="shareResults">
              Share Results
            </button>
          </div>
        </div>
      </h3>
      <div id="printResults">
        <div v-if="overall" class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Place (overall)</th>
              <th class="table-header">Place (selection)</th>
              <th class="table-header" style="max-width:400px; text-align: left;">Name</th>
              <th class="table-header">Total Score</th>
              <th class="table-header">Total {{ tournament.target_style === 'Stakes' ? '12' : 'X' }}'s
              </th>
              <th class="table-header" v-if="tournament.rounds.length > 1"
                  v-for="round in tournament.rounds">{{ round.name }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(team, index) in filteredTeams" @click="breakdownToggle(team.name)"
                class="cursor-pointer">
              <td>{{ team.place }}</td>
              <td>{{ index + 1 }}</td>
              <td style="max-width:400px; text-align: left;">
                {{ team.name }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ member.name }}
                  </div>
                </div>
              </td>
              <td>{{ team.score }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ member.score }}
                  </div>
                </div>
              </td>
              <td>{{ team.x }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ member.x }}
                  </div>
                </div>
              </td>
              <td v-if="tournament.rounds.length > 1" v-for="round in tournament.rounds">
                {{ team.rounds.find(f => f.round === round.id).score }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ tournament.scoreList[member.id].rounds[round.id].points }}
                    <span v-if="tournament.scoreList[member.id].rounds[round.id].X">
                      {{ tournament.scoreList[member.id].rounds[round.id].X }}x
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="!filteredTeams.length">
              <td colspan="50" class="table-empty">There are no {{ teamWord }}s that meet the
                requirements/filter.
              </td>
            </tr>
            
            </tbody>
          </table>
        </div>
        <div v-else class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Place (overall)</th>
              <th class="table-header">Place (selection)</th>
              <th class="table-header" style="max-width:400px; text-align: left;">Name</th>
              <th class="table-header">{{ tournament.rounds.length > 1 ? 'Round' : 'Total' }} Score</th>
              <th class="table-header">
                {{ tournament.rounds.length > 1 ? 'Round' : 'Total' }}
                {{ tournament.target_style === 'Stakes' ? '12' : 'X' }}'s
              </th>
              <template v-for="end in round.ends_count">
                <th class="table-header">{{ endWord(end) }}</th>
              </template>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(team, index) in filteredTeams" @click="breakdownToggle(team.name)"
                class="cursor-pointer">
              <td>{{ team.place }}</td>
              <td>{{ index + 1 }}</td>
              <td style="width:300px; text-align: left;">
                {{ team.name }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ member.name }}
                  </div>
                </div>
              </td>
              <td>
                {{ team.rounds.find(f => f.round === round.id).score }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ tournament.scoreList[member.id].rounds[round.id].points }}
                  </div>
                </div>
              </td>
              <td>
                {{ team.rounds.find(f => f.round === round.id).x }}
                <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                  <div v-for="member in team.breakdown">
                    {{ tournament.scoreList[member.id].rounds[round.id].X }}
                  </div>
                </div>
              </td>
              <template v-for="end in round.ends_count">
                <td>
                  {{
                    team.rounds.find(f => f.round === round.id).ends.find(f => f.end ===
                      end).score
                  }}
                  <div v-if="breakdown.includes(team.name)" class="border-t border-gray-500">
                    <div v-for="member in team.breakdown">
                      {{ tournament.scoreList[member.id].rounds[round.id].ends[(end)].points }}
                      <span v-if="tournament.scoreList[member.id].rounds[round.id].ends[(end)].X">
                        {{ tournament.scoreList[member.id].rounds[round.id].ends[(end)].X }}x
                      </span>
                    </div>
                  </div>
                </td>
              </template>
            </tr>
            <tr v-show="filteredTeams.length === 0">
              <td class="table-empty" colspan="50">
                <div class="flex flex-col">
                  <div>
                    There are no {{ teamWord }}s that meet the requirements/filter.
                  </div>
                  <div>
                    Results require minimum of 8 {{ teamWord }} members with scores.
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-else>
      There are no teams/clubs for this tournament.
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import TeamClubResultsLinks from "./TeamClubResultsLinks.vue";
import Dropdown from "../../partials/Dropdown.vue";
import * as TeamType from "../../../enums/teamType";
import {Toast} from "../../../mixins/swal_mixins";

export default {
  name: "tournamentTeamResults",
  components: {Dropdown, TeamClubResultsLinks},
  props: ['tournamentIn'],
  data() {
    return {
      lineTime: this.tournamentIn.line_times[0],
      location: this.tournamentIn.locations[0],
      end: 1,
      round: this.tournamentIn.rounds.length === 1 ? this.tournamentIn.rounds[0] : {},
      value: [],
      overall: this.tournamentIn.rounds.length !== 1,
      selectedRound: null,
      selectedDivision: null,
      selectedTeam: null,
      search: '',
      teams: [],
      fullSelectionLink: location.href,
      breakdown: [],
    }
  },
  created() {
    this.setSelectedDivision();
  },
  beforeMount() {
    this.setTeams();
    this.selectFilters();
    this.setMeta();
  },
  computed: {
    
    competitors() {
      return this.tournament.competitorList;
    },
    tournament() {
      return this.tournamentIn;
    },
    teamType() {
      return this.$route.params.teamType;
    },
    title() {
      let string = this.tournament.name + ' ';
      string += this.selectedDivision ? this.selectedDivision.label + ' ' : '';
      string += this.selectedRound ? this.selectedRound.name + ' ' : '';
      string += this.teamWord + ' Results';
      return string;
    },
    teamWord() {
      switch (this.teamType) {
        case TeamType.team:
          return 'Team';
        case TeamType.club:
          return 'Club';
        default:
          return 'Team/Club';
      }
    },
    longestRound() {
      let count = 0;
      let nope = false;
      this.tournament.rounds.forEach(round => round.ends_count > count ? count = round.ends_count : nope = true);
      return count;
    },
    divisionArray() {
      let options = this.tournament.regform.find(detail => detail.option === 'division');
      if (options && options.enabled) {
        return options.multiple
      }
    },
    filteredTeams() {
      let division = this.selectedDivision ? this.selectedDivision : this.divisionArray[0];
      let teams = this.validTeams[division.label];
      let vm = this;
      Object.values(teams).forEach(function (team) {
        //gather scores
        let scoreTotals = [];
        team.competitors.forEach(function (competitor) {
          let scores = vm.tournament.scoreList[competitor.id];
          let xValue = 'X' in scores ? scores.X : scores[12];
          let scoreHolder = {score: scores.points, x: xValue, id: competitor.id, name: competitor.name};
          scoreTotals.push(scoreHolder);
        });
        scoreTotals.sort(function (a, b) {
          let n = b.score - a.score;
          if (n !== 0) {
            return n
          }
          return b.x - a.x;
        });
        //combine top 5 and add to score
        team.breakdown = [];
        team.rounds.forEach(function (round) {
          round.breakdown = [];
        });
        for (let i = 0; i < 5; i++) {
          team.score += scoreTotals[i].score;
          team.x += scoreTotals[i].x;
          team.breakdown.push(scoreTotals[i]);
          //calculate rounds scores
          team.rounds.forEach(function (round) {
            let scores = vm.tournament.scoreList[scoreTotals[i].id].rounds[round.round];
            round.score += scores.points;
            round.x += scores.X;
            round.ends.forEach(function (end) {
              let endScores = scores.ends[end.end];
              end.score += endScores.points;
              end.x += endScores.X;
            });
          });
          
        }
      });
      //sort by top score
      let sorted = Object.values(teams).sort(function (a, b) {
        let n = b.score - a.score;
        if (n !== 0) {
          return n
        }
        return b.x - a.x;
      });
      let place = 1;
      Object.values(sorted).forEach(function (sortedTeam) {
        sortedTeam.place = place;
        place++;
      });
      if (this.search.length) {
        let search = this.search.toLowerCase();
        sorted = sorted.filter(function (team) {
          let name = team.name.toLowerCase();
          return name.includes(search);
        })
      }
      return sorted;
    },
    validTeams() {
      let tournament = this.tournament;
      let competitors = this.competitors;
      let vm = this;
      
      let divisionList = {};
      this.divisionArray.forEach(function (division) {
        divisionList[division.label] = {};
        vm.teams.forEach(function (team) {
          divisionList[division.label][team] = {
            name: team,
            competitors: [],
            score: 0,
            x: 0,
            place: 0,
            rounds: []
          };
          Object.values(tournament.assignedCompetitors.rounds).forEach(function (round) {
            let endArray = [];
            let end_count = tournament.rounds.find(f => f.id === round.round_id).ends_count;
            for (let i = 0; i < end_count; i++) {
              endArray.push({end: i + 1, score: 0, x: 0, place: 0});
            }
            divisionList[division.label][team].rounds.push({
              round: round.round_id,
              score: 0,
              x: 0,
              place: 0,
              ends: endArray,
            });
            
          });
        });
      });
      Object.values(competitors).forEach(function (competitor) {
        if (competitor.team && competitor.teamType && tournament.scoreList[competitor.id].complete) {
          if (competitor.teamType === vm.teamType) {
            let division = competitor.regform.find(f => f.option === 'division').name;
            let team = divisionList[division][competitor.team];
            if (team) {
              let index = team.competitors.findIndex(p => p.id === competitor.id);
              if (index === -1) {
                team.competitors.push(competitor);
              }
            }
          }
        }
      });
      
      let validTeam = {};
      this.divisionArray.forEach(function (division) {
        validTeam[division.label] = {};
      });
      Object.keys(divisionList).forEach(function (divisionName) {
        Object.keys(divisionList[divisionName]).forEach(function (team) {
          if (divisionList[divisionName][team].competitors.length > 7) {
            validTeam[divisionName][team] = divisionList[divisionName][team];
          }
        });
      });
      return validTeam;
    },
  },
  methods: {
    endWord(end) {
      let text = `${trans.choice('search.end', 1)} ${end}`;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    setSelectedDivision() {
      let vm = this;
      let div = vm.divisionArray[0];
      for (let division of vm.divisionArray) {
        if (Object.keys(vm.validTeams[division.label]).length > 0) {
          div = division;
          break;
        }
      }
      vm.selectedDivision = div;
    },
    breakdownToggle(teamName) {
      let exists = this.breakdown.indexOf(teamName);
      if (exists !== -1) {
        this.breakdown.splice(exists, 1);
      } else {
        this.breakdown.push(teamName);
      }
    },
    setTeams() {
      let teamList = [];
      if (this.tournament && this.tournament.sport_id === 1 && this.competitors && Object.keys(this.competitors).length) {
        Object.values(this.competitors).forEach(function (c) {
          let hasTeam = c.regform.find(f => f.option === 'team/club-name' || f.option === 'team_club_name');
          if (hasTeam && hasTeam !== 'undefined') {
            c.team = hasTeam.name;
            if (!teamList.includes(hasTeam.name)) teamList.push(hasTeam.name);
          }
          let hasType = c.regform.find(f => f.option === 'team/club' || f.option === 'team_club');
          if (hasType && hasType !== 'undefined') {
            if (hasType.length) {
              console.log(hasType);
            } else {
              console.log('no type');
            }
          }
          c.teamType = hasType && hasType !== 'undefined' && hasType.name.length ? hasType.name : TeamType.combined;
        })
      }
      this.teams = teamList;
    },
    teamSelected(team) {
      this.selectedTeam = team;
      this.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    cancelTeam() {
      this.selectedTeam = null;
      this.updateUrl();
    },
    updateUrl() {
      let filters = {};
      if (!this.overall) {
        filters['round'] = this.round.slug;
      }
      if (this.selectedDivision) {
        filters['division'] = this.selectedDivision.label;
      }
      this.$router.push({query: filters}).catch(err => {
      });
      this.fullSelectionLink = window.location.href;
    },
    divisionSelected(division) {
      this.selectedDivision = division;
      this.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    cancelDivision() {
      this.selectedDivision = null;
      this.updateUrl();
    },
    
    roundSelected(round) {
      this.overall = false;
      this.round = round;
      this.selectedRound = round;
      this.updateUrl();
    },
    overallSelected() {
      this.overall = true;
      this.round = {};
      this.selectedRound = null;
      this.updateUrl();
    },
    
    selectFilters() {
      let query = this.$route.query;
      if (query.round) {
        this.tournament.rounds.forEach((round) => {
          if (round.slug === query.round) {
            this.roundSelected(round);
          }
        });
      }
      if (query.division) {
        let options = this.tournament.regform.find(detail => detail.option === 'division');
        if (options && options.enabled) {
          options.multiple.forEach((option) => {
            if (option.label === query.division) {
              this.divisionSelected(option);
            }
          });
        }
      }
      this.updateUrl();
    },
    setMeta() {
      let title = this.tournament.name + ' Tournament Results';
      let image = this.tournament.image && this.tournament.image_enabled ? this.tournament.image : null;
      
      let start = moment(new Date(this.tournament.start_time)).format('DD MMM');
      let end = moment(new Date(this.tournament.end_time)).format('DD MMM YYYY');
      let loc = '';
      if (this.tournament.address) {
        if (this.tournament.address.city) {
          loc += this.tournament.address.city;
          if (this.tournament.address.state) {
            loc += ', ';
          }
        }
        loc += this.tournament.address.state ? this.tournament.address.state : '';
      }
      let description = `${start} through ${end} - ${loc}  ${this.tournament.tagline}`;
      if (this.selectedCompetitor) {
        title = this.selectedCompetitor.name + ' Tournament Results';
      }
      document.querySelector('meta[property="og:description"]').setAttribute("content", description);
      document.querySelector('meta[property="og:title"]').setAttribute("content", title);
      if (image) {
        document.querySelector('meta[property="og:image"]').setAttribute("content", image);
      }
    },
    
    printResults() {
      // Get HTML to print from element
      const printHtml = document.getElementById('printResults').innerHTML;
      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    <style>
                        table, th, td {
                            border: 1px solid gray;
                            border-collapse: collapse;
                            text-align: center;
                        }
                        .flex {
                            display: flex;
                        }
                        .flex-nowrap {
                            flex-wrap: nowrap !important;
                        }
                        .whitespace-nowrap {
                            white-space: nowrap !important;
                        }
                        .text-right {
                            text-align: right !important;
                        }
                        .ml-2 {
                            margin-left: 0.5rem !important;
                        }
                    </style>
                  </head>
                  <body>
                    ${printHtml}
                  </body>
                </html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    shareResults() {
      let url = document.getElementById("urlSelections");
      url.setAttribute('type', 'text');
      url.select();
      document.execCommand('copy');
      url.setAttribute('type', 'hidden');
      Toast.fire({title:'Link copied to clipboard', icon: "success"});
    },
  }
}
</script>

<style scoped>
th, td {
  width: 1px;
  text-align: center;
}
</style>
