<script setup>
import {useManageStore} from "../../../../stores/manageStore";
import SortableHeader from "./SortableHeader.vue";
import ManageCompetitorRow from "./ManageCompetitorRow.vue";
import {computed} from "vue";

const manage = useManageStore();

const emptySpan = computed(() => {
  let required = 6;
  if (manage.tournament.line_times.length > 1) required++;
  required += (manage.tournament.payments.pricing_extras ?? []).length;
  required += manage.validFormHeaders.length;
  if (manage.tournament.targets.length > 1) required++;
  return required;
})
</script>

<template>
  <div :class="manage.printing ? '' :'table-responsive'">
    <table class="table table-bordered table-striped ">
      <thead>
      <tr class="bg-white">
        <SortableHeader label="First Name" value="first_name"/>
        <SortableHeader label="Last Name" value="last_name"/>
        <th class="table-header">Status</th>
        <SortableHeader label="Email" value="email"/>
        <th class="table-header text-nowrap" v-if="manage.tournament.line_times.length > 1">Requested Time</th>
        <th class="table-header text-nowrap" v-for="extra in manage.tournament.payments.pricing_extras ?? []">
          {{ extra.name }} ({{ extra.type }}{{ extra.amount }})
        </th>
        <SortableHeader v-for="detail in manage.validFormHeaders" :label="detail.name" :value="detail.option"/>
        <th class="table-header" v-if="manage.tournament.targets.length > 1">Target</th>
        <th class="table-header text-nowrap">Time Registered</th>
        <th class="table-header">Delete</th>
      </tr>
      </thead>
      <tbody>
      <manage-competitor-row
        v-show="manage.competitorList.length"
        v-for="competitor of manage.competitorList"
        :round="manage.round"
        :competitor="competitor"
        :key="competitor.id"
        :tournament="manage.tournament"
        @competitor-change="manage.incrementChange"
        @delete="manage.deleteCompetitor(competitor)"/>
      <tr v-show="!manage.competitorList.length">
        <td :colspan="emptySpan" class="table-empty text-center">There are no competitors</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>