<template>
  <div v-if="tournament" class="flex flex-col">
    <div>
      <a @click.prevent="printInvoice" href="" class="float-right text-gray-600 ml-3" title="Print invoice">Print
        <i class="far fa-print"></i></a>
    </div>
    <div class="card card-blue-700" id="printArea">
      <div class="card-header">
        <div class="flex justify-between">
          <div class="ml-3 flex justify-center flex-col">
            <h1 class="uppercase">Invoice</h1>
          </div>
          <div class="mr-3">
            <img class="p-0 m-0 w-48 screen" src="/img/White_Bowscore_Logo.png" alt="Bowscore">
            <img class="p-0 m-0 w-48 print" src="/img/Orange_Bowscore_Logo.png" alt="Bowscore">
            <div>invoice@bowscore.com</div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="flex justify-between">
          <div class="flex">
            <div>
              <div class="text-gray-600">
                Billed To
              </div>
              <div>
                <div>{{ tournament.club.name }}</div>
                <div>{{ tournament.club.email }}</div>
                <div v-html="address"></div>
              </div>
            </div>
            <div class="ml-3">
              <div class="text-gray-600">
                Tournament
              </div>
              <div>
                <div>{{ tournament.name }}</div>
                <div>Start: {{ formatTime(tournament.start_time) }}</div>
                <div>End: {{ formatTime(tournament.end_time) }}</div>
              </div>
            </div>
          </div>
          <div class="text-right mr-4">
            <div class="text-gray-600">
              Amount Due
            </div>
            <div>
              <h1 class="text-blue-600 text-5xl">${{ tournament.unpaid * cost }}.00</h1>
            </div>
          </div>
        </div>
        <div class="my-3 py-3 border-t-4 border-blue-600 border-solid flex">
          <div class="flex-col w-1/2">
            <div class="flex mb-3 text-lg text-blue-600">
              <div style="flex:2">
                Description
              </div>
              <div class="text-right flex-1">
                Fee
              </div>
            </div>
            <div v-for="competitor in competitors"
                 class="pt-2 pb-2 flex border-b border-solid border-gray-600">
              <div style="flex:2">
                <div class="text-sm">Scoring Competitor</div>
                <div class="text-gray-600">{{ competitor.name }}</div>
              </div>
              
              <div class="text-right flex-1">
                <div>${{ cost }}.00</div>
              </div>
            </div>
            <div class="float-right mt-3 mb-5">
              <span class="text-lg text-blue-700 mr-5">Total</span>
              <span>${{ tournament.unpaid * cost }}.00</span>
            </div>
          </div>
          <div class="flex flex-col w-1/2 p-4">
            <div class="flex-grow flex justify-center m-auto flex-col screen">
              <div v-if="!paid && !paying" @click="paying = true"
                   class="btn btn-outline-gray-600 screen">Pay Invoice
              </div>
              <div :class="paying ? 'flex screen' : 'hidden'" class="flex-col my-2 w-72 sm:w-96">
                <div>Card Details</div>
                <div v-if="useAuthNet">
                  <div class="bs-form-box" id="authNetCard">
                    <input class="w-1/3 focus:outline-none" maxlength="16" type="text"
                           name="cardNumber"
                           id="cardNumber" placeholder="Card number"/>
                    <div class="text-right float-right w-2/3">
                      <input class="w-1/4 justify-end text-right focus:outline-none" type="text"
                             name="expMonth" id="expMonth" maxlength="2"
                             placeholder="MM"/>{{ ' / ' }}
                      <input class="w-1/4 justify-end focus:outline-none" maxlength="4"
                             type="text"
                             name="expYear" id="expYear" placeholder="YYYY"/>
                      <input class="w-1/4 justify-end text-right focus:outline-none" maxlength="4"
                             type="text"
                             name="cardCode" id="cardCode" placeholder="CVV"/>
                    </div>
                  </div>
                  <input type="hidden" name="authNetToken" id="authNetToken"/>
                  <input type="hidden" name="authNetDescriptor" id="authNetDescriptor"/>
                </div>
                <div v-else>
                  <div ref="card" class="bs-form-box flex flex-col justify-center"></div>
                </div>
                <div class="flex justify-end mt-2">
                  <div class="btn btn-outline-blue-600" @click.prevent="pay">
                    Submit Payment <span v-if="loading"><i
                    class="fas fa-spin fa-spinner"></i></span>
                  </div>
                </div>
              </div>
              <div v-if="paid" class="screen uppercase text-2xl text-blue-700">Paid</div>
            </div>
            <div v-if="!paid" class="p-3 shadow-inner bg-blue-100 text-center rounded text-gray-500">
              <p>
                Failure to pay may result in scores being removed from Bowscore.
                Payment must be received within 2 weeks of the tournament end date.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <a :href="'/tournaments/' + tournament.slug" class="float-left text-blue-600"> &rarr; return to
        tournament</a>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "TournamentInvoice",
  props: ['user', 'tournament', 'inc_competitors', 'stripe_publishable', 'api_login', 'auth_net_key'],
  data() {
    return {
      paid: !this.tournament.unpaid,
      cost: 2,
      paying: false,
      stripe: null,
      elements: null,
      card: null,
      loading: false,
      competitors: this.inc_competitors,
    }
  },
  computed: {
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    address() {
      let string = '';
      if (this.tournament.club.mailing_address.address1) {
        string += this.tournament.club.mailing_address.address1 + '<br>' +
          (this.tournament.club.mailing_address.address2 ? this.tournament.club.mailing_address.address2 + '<br>' : '') +
          (this.tournament.club.mailing_address.city ? this.tournament.club.mailing_address.city + ', ' : '') +
          (this.tournament.club.mailing_address.state ? this.tournament.club.mailing_address.state + ' ' : '') +
          (this.tournament.club.mailing_address.zip ? this.tournament.club.mailing_address.zip : '');
      } else if (this.tournament.club.range_address.address1) {
        string += this.tournament.club.range_address.address1 + '<br>' +
          (this.tournament.club.range_address.address2 ? this.tournament.club.range_address.address2 + '<br>' : '') +
          (this.tournament.club.range_address.city ? this.tournament.club.range_address.city + ', ' : '') +
          (this.tournament.club.range_address.state ? this.tournament.club.range_address.state + ' ' : '') +
          (this.tournament.club.range_address.zip ? this.tournament.club.range_address.zip : '');
      } else if (this.tournament.address.address1) {
        string += this.tournament.address.address1 + '<br>' +
          (this.tournament.address.address2 ? this.tournament.address.address2 + '<br>' : '') +
          (this.tournament.address.city ? this.tournament.address.city + ', ' : '') +
          (this.tournament.address.state ? this.tournament.address.state + ' ' : '') +
          (this.tournament.address.zip ? this.tournament.address.zip : '');
      }
      return string;
    },
  },
  methods: {
    formatTime(time) {
      return moment(time).format('DD MMM YYYY')
    },
    printInvoice() {
      document.body.innerHTML = document.getElementById('printArea').innerHTML;
      window.print();
      window.location.reload();
    },
    handleStripe() {
      return this.stripe.createToken(this.card).then(({token, error}) => {
        if (error) {
          this.loading = false;
          return BsAlert.fire({title: error.message, showCancelButton: false, icon: "error"});
        } else {
          this.submitPayment({
            'stripeToken': token.id,
            'paymentAmount': (this.tournament.unpaid * this.cost),
          })
        }
        
      });
    },
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        var html = '<div>'
        Object.values(response.messages.message).forEach((message) => {
          html += `<div>${message.text}</div>`;
        })
        html += '</div>'
        BsAlert.fire({
          title: 'Error Processing',
          html: html,
          icon: "error",
          showCancelButton: false,
        })
        this.loading = false;
      } else {
        this.submitPayment({
          'authNetToken': response.opaqueData.dataValue,
          'authNetDescriptor': response.opaqueData.dataDescriptor,
          'paymentAmount': (this.tournament.unpaid * this.cost),
        })
      }
    },
    handleAuthNet() {
      var data = {
        authData: {clientKey: this.auth_net_key, apiLoginID: this.api_login},
        cardData: {
          cardNumber: document.getElementById("cardNumber").value,
          month: document.getElementById("expMonth").value,
          year: document.getElementById("expYear").value,
          cardCode: document.getElementById("cardCode").value,
        }
      }
      Accept.dispatchData(data, this.responseHandler);
    },
    submitPayment(paymentData) {
      axios.post('/tournaments/' + this.tournament.slug + '/pay_invoice', paymentData).then(({data}) => {
        if (data.success) {
          this.paid = true;
          let remaining = '';
          if (data.remaining) {
            remaining = 'There are still ' + Object.keys(data.remaining).length + ' unpaid archers.';
            this.competitors = data.remaining;
          }
          BsAlert.fire({
            titleText: 'Payment Successful',
            html: remaining,
            showCancelButton: false,
            icon: 'success',
          }).then(() => {
            this.paying = false;
          })
        } else if (data.error) {
          BsAlert.fire({title: data.error.message, showCancelButton: false, icon: "error"})
        }
        this.loading = false;
      })
    },
    pay() {
      this.loading = true;
      if (!this.useAuthNet) {
        this.handleStripe();
      } else {
        this.handleAuthNet();
      }
    }
  },
  mounted() {
    if (!this.useAuthNet) {
      this.stripe = Stripe(this.stripe_publishable);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount(this.$refs['card']);
    }
  },
}
</script>

<style scoped>
.print {
  display: none;
}

@media print {
  .screen {
    display: none;
  }
  
  .print {
    display: block;
  }
}
</style>
