<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";

const store = useCompetitorStore();
</script>

<template>
  <div class="bs-card" :class="store.wait_list ? 'text-stone-400' : 'text-black'">
    <div class="bs-section-header">Tournament Total</div>
    <div class="flex mb-2">
      <div class="flex-2 bs-input-box mr-4">{{ store.registrationText }}</div>
      <div class="flex-1 bs-input-box flex justify-between">
        <div class="flex flex-col justify-center">
          <i class="fa-regular fa-lg text-stone-400 fa-dollar-sign"></i>
        </div>
        <div>{{ store.registrationFee }}.00</div>
      </div>
    </div>
    <div class="flex mb-2" v-for="item of store.selectedPurchases">
      <div class="flex-2 bs-input-box mr-4">{{ item.name }}</div>
      <div class="flex-1 bs-input-box flex justify-between">
        <div class="flex flex-col justify-center">
          <i class="fa-regular fa-lg text-stone-400 fa-dollar-sign"></i>
        </div>
        <div>{{ item.amount }}.00</div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="flex-2 bs-input-box mr-4">Bowscore service fee</div>
      <div class="flex-1 bs-input-box flex justify-between">
        <div class="flex flex-col justify-center">
          <i class="fa-regular fa-lg text-stone-400 fa-dollar-sign"></i>
        </div>
        <div>{{ store.bowscoreFee }}.00</div>
      </div>
    </div>
    <div class="flex mb-4">
      <div class="flex-2 ml-6 mr-4 flex justify-end">
        <div class="totalText">Total</div>
      </div>
      <div class="flex-1  bs-input-box flex justify-between">
        <div class="flex flex-col justify-center">
          <i class="fa-regular fa-lg text-stone-400 fa-dollar-sign"></i>
        </div>
        <div class="font-bold">{{ store.registrationTotal }}.00</div>
      </div>
    </div>
    <div class="text-stone-400 mt-4" v-if="store.wait_list">
      Payment is not collected for waitlisted competitors until they have been approved.
    </div>
  </div>

</template>

<style scoped>
.bs-input-box {
  @apply bg-stone-50;
}

.fa-dollar-sign {
  margin-top: 2px;
}

.totalText {
  @apply flex flex-col justify-center font-semibold text-stone-400;
  font-size: 14px;
  letter-spacing: .018rem;
}
</style>