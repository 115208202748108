import {defineStore} from "pinia";
import Bugsnag from "@bugsnag/js";
import axios from "axios";
import {BsAlert} from "../mixins/swal_mixins";
import {store} from '../store';


export const useManageStore = defineStore('manage', {
  state: () => {
    return {
      tournament: null,
      round: null,
      changeCount: 1,
      suiteChange: 0,
      selectedOptions: [],
      user: null,
      selectedTarget: null,
      selectedStatus: null,
      selectedTime: null,
      selectedLocation: null,
      selectedBale: null,
      tournamentSlug: null,
      trans: null,
      printing: false,
      sorted: [],
      search: null,
      competitorList: [],
    };
  },
  getters: {
    baleWord(state) {
      let text = 'Bale';
      if (state.trans === null) return text;
      text = state.trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    allBales(state) {
      if (state.tournament == null) return [];
      return Object.values(state.tournament.bales).map(m => m.name);
    },
    columnList(state) {
      return {
        'line_time': 'Line Time',
        'location': 'Location',
        'bale': state.baleWord,
        'approved': 'Approved for Round'
      };
    },
    validFormHeaders(state) {
      let list = [];
      for (let detail of state.tournament.regform) {
        if (detail.enabled) {
          let options = ['name', 'email', 'first_name', 'last_name'];
          if (!(options.includes(detail.option) || ['header', 'waiver'].includes(detail.type))) {
            list.push(detail);
          }
        }
      }
      return list;
    },
  },
  actions: {
    setSelectedOption(option, value) {
      this.selectedOptions.find(f => f.name === option).selected = value;
      this.filterCompetitors();
    },
    setSelectedTarget(target) {
      this.selectedTarget = target;
      this.filterCompetitors();
    },
    setSelectedStatus(status) {
      this.selectedStatus = status;
      this.filterCompetitors();
    },
    setSelectedExtra(extra) {
      this.selectedExtra = extra;
      this.filterCompetitors();
    },
    setSelectedTime(time) {
      this.selectedTime = time;
      this.filterCompetitors();
    },
    setSelectedLocation(location) {
      this.selectedLocation = location;
      this.filterCompetitors();
    },
    setSelectedBale(bale) {
      this.selectedBale = bale;
      this.filterCompetitors();
    },
    setSelectedApproved(value) {
      this.selectedApproved = value;
      this.filterCompetitors();
    },
    filterCompetitors() {
      let list = [];
      if (!this.tournament) return list;
      list = this.tournament.competitors;
      if (this.search?.length) {
        let search = this.search.toUpperCase();
        list = list.filter(function (competitor) {
          let first = competitor.first_name != null ? competitor.first_name.toUpperCase() : '';
          let last = competitor.last_name != null ? competitor.last_name.toUpperCase() : '';
          return first.includes(search) || last.includes(search);
        });
      }
      if (this.round !== null && this.round.competitors) {
        list = this.filterByTime(list);
        list = this.filterByLocation(list);
        list = this.filterByBale(list);
        list = this.filterByApproved(list);
      }
      list = this.filterByStatus(list);
      list = this.filterByTarget(list);
      list = this.filterByExtras(list);
      list = this.filterByOptions(list);
      this.competitorList = list;
    },
    updateSuite() {
      if (!this.suiteChange) return;
      axios.get(`/tournaments/${this.tournamentSlug}/suite/tournament`).then(({data}) => {
        store.commit('tournament/SET_TOURNAMENT', {'tournament': data.tournament});
        this.suiteChange = 0;
      }).catch((error) => {
        this.axiosError(error);
      });
    },
    updateAvailable(available) {
      this.tournament.available = available;
    },
    filterByOptions(competitors) {
      if (!this.selectedOptions) return competitors;
      let options = this.selectedOptions.filter(f => f.selected !== null);
      for (let option of options) {
        let label = this.selectedOptions.find(f => f.name === option.name).selected === 'none' ? null : option.selected.label;
        competitors = competitors.filter(function (p) {
            let form = p.regform.find(f => f.option === option.name);
            return form && form.multiple ? form.multiple.includes(label) : form?.name === label;
          }
        )
      }
      return competitors;
    },
    filterByExtras(competitors) {
      if (!this.selectedExtra) return competitors;
      let extra = this.selectedExtra;
      return competitors.filter(function (p) {
        let form = (p.pricing_extras ?? []).filter(f => {
          if (extra.hasOwnProperty('id')) return f.id === extra.id;
          return f.name === extra.name;
        });
        return form.length > 0;
      })
    },
    filterByTarget(competitors) {
      if (!this.selectedTarget) return competitors;
      let target = this.selectedTarget === 'none' ? null : parseInt(this.selectedTarget.id);
      return competitors.filter(f => f.target_id === target);
    },
    filterByStatus(competitors) {
      if (!this.selectedStatus) return competitors;
      let status = this.selectedStatus === 'waitlist' ? 'wait_list' : this.selectedStatus;
      return status === 'unpaid' ? competitors.filter(f => !f.paid) : competitors.filter(f => f[status]);
    },
    filterByApproved(competitors) {
      if (!this.selectedApproved) return competitors;
      let approved = this.selectedApproved === 'yes' ? 1 : 0;
      let matchIds = this.round.competitors.filter(f => f.pivot.approved === approved).map(a => a.id);
      return competitors.filter((f) => matchIds.includes(f.id));
    },
    filterByBale(competitors) {
      if (!this.selectedBale) return competitors;
      let bale = this.selectedBale === 'none' ? null : this.selectedBale;
      let matchIds = this.round.competitors.filter(f => f.pivot.bale === bale).map(a => a.id);
      return competitors.filter((f) => matchIds.includes(f.id));
    },
    filterByLocation(competitors) {
      if (!this.selectedLocation) return competitors;
      let location = this.selectedLocation === 'none' ? null : this.selectedLocation.name;
      let matchIds = this.round.competitors.filter(f => f.pivot.location === location).map(a => a.id);
      return competitors.filter((f) => matchIds.includes(f.id))
    },
    filterByTime(competitors) {
      if (!this.selectedTime) return competitors;
      let time = this.selectedTime === 'none' ? null : this.selectedTime.id;
      let matchIds = null;
      if (time) {
        matchIds = this.round.competitors.filter(f => parseInt(f.pivot.line_time) === parseInt(time)).map(a => a.id);
      } else {
        matchIds = this.round.competitors.filter(f => f.pivot.line_time === null).map(a => a.id);
      }
      return competitors.filter((f) => matchIds.includes(f.id));
    },
    deleteCompetitor(competitor) {
      let exists = this.tournament.competitors.findIndex((f) => {
        return f.id === competitor.id
      });
      if (exists !== -1) {
        this.incrementChange();
        this.tournament.competitors.splice(exists, 1);
      }
    },
    sort(column) {
      let down = this.sorted.includes(column);
      let formList = this.tournament.regform.map((m) => m.option);
      let list = [...this.competitorList];
      if (['first_name', 'last_name', 'email'].includes(column)) {
        list.sort((a, b) => {
          const aValue = a[column].toLowerCase();
          const bValue = b[column].toLowerCase();
          return aValue > bValue ? (down ? 1 : -1) : (down ? -1 : 1);
        });
      } else if (formList.includes(column)) {
        list.sort((a, b) => {
            let aValue = a.regform.find((f => f.option === column))?.name;
            let bValue = b.regform.find((f => f.option === column))?.name;
            return aValue?.toLowerCase() < bValue?.toLowerCase() ? (down ? 1 : -1) : (down ? -1 : 1);
          }
        );
      } else {
        list.sort((a, b) => {
          return a.assignments[this.round.id][column] > b.assignments[this.round.id][column] ? (down ? 1 : -1) : (down ? -1 : 1);
        });
      }
      if (down) {
        let position = this.sorted.indexOf(column);
        this.sorted.splice(position, 1);
      } else {
        this.sorted.push(column);
      }
      this.competitorList = list;
    },
    bulkOptions(column) {
      let list = {0: 'remove'};
      if (column === 'line_time') {
        this.round.line_times.forEach(f => list[f.id] = f.time);
      } else if (column === 'location') {
        this.round.locations.forEach(f => list[f.id] = f.name);
      } else if (column === 'bale') {
        let max = 0;
        for (let location of this.round.locations) {
          if (parseInt(location.bales) > max) max = parseInt(location.bales);
        }
        for (let i = 1; i < max + 1; i++) {
          list[i] = i;
        }
      } else if (column === 'approved') {
        return {0: 'remove', 1: 'approve'};
      }
      return list;
    },
    selectAllColumn() {
      BsAlert.fire({
        title: 'Select Column',
        input: 'select',
        inputOptions: this.columnList,
        confirmButtonText: 'Next'
      }).then((result) => {
        if (!result.value) return;
        this.selectAllValue(result.value);
      })
    },
    selectAllValue(column) {
      BsAlert.fire({
        title: 'Select Value',
        input: 'select',
        inputOptions: this.bulkOptions(column),
        confirmButtonText: 'Select Option'
      }).then((result) => {
        if (!result.value) return;
        this.finalizeUpdateAll(column, result.value);
      })
    },
    finalizeUpdateAll(column, value) {
      let dbVal = column === 'location' ? this.bulkOptions(column)[value] : parseInt(value);
      BsAlert.fire({
        title: `Set all ${this.columnList[column]} to ${this.bulkOptions(column)[value]}?`,
        text: 'This will update all ' + this.competitorList.length + ' selected competitors',
        icon: 'warning',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: (_) => {
          return this.updateAll(column, dbVal);
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    },
    updateAll(column, value) {
      return axios.post(`/tournaments/${this.tournamentSlug}/mass_update`, {
        'column': column,
        'value': value,
        'competitors': this.competitorList.map(m => m.id),
        'round': this.round.id,
      }).then(({data}) => {
        if (data.success) {
          this.incrementChange();
          let roundId = this.round.id;
          this.competitorList.forEach(function (competitor) {
            competitor.assignments[roundId][column] = value === 'remove' ? null : value;
          })
        } else if (data.error) {
          return BsAlert.fire({
            title: data.error.message,
            icon: "error",
            showCancelButton: false
          });
        }
      }).catch((error) => {
        this.axiosError(error);
      });
    },
    updateAllClicked() {
      this.selectAllColumn();
    },
    selectTab(tab = null) {
      this.round = tab;
      if (this.round !== null) {
        let previousTab = document.querySelector('.selectedTab');
        previousTab.className = "tabItem";
        let newTab = document.querySelector('#round_' + this.round.id);
        newTab.className = "selectedTab";
      } else {
        let previousTab = document.querySelector('.selectedTab');
        previousTab.className = "tabItem";
        let newTab = document.querySelector('#mainTab');
        newTab.className = "selectedTab";
      }
    },
    resetRoundFilters() {
      this.selectedLocation = null;
      this.selectedTime = null;
      this.selectedBale = null;
      this.selectedApproved = null;
    },
    clearSearch() {
      this.search = '';
      this.filterCompetitors();
    },
    selectedOption(detail) {
      let value = '';
      if (this.selectedOptions) {
        let selected = this.selectedOptions.find(f => f.name === detail.option).selected;
        if (selected) {
          value = `: ${selected === 'none' ? 'none' : selected.label}`;
        }
      }
      return value;
    },
    targetName(competitor) {
      if (competitor.target_id != null && this.tournament.targets.length > 1) {
        let targetId = parseInt(competitor.target_id);
        let target = this.tournament.targets.find(f => parseInt(f.id) === targetId);
        if (target != null) return `"${target.name}"\n`;
      }
      return '\n';
    },
    incrementChange() {
      this.changeCount++;
      this.suiteChange++;
    },
    setDefaults(tournament, user) {
      this.user = user;
      this.tournamentSlug = tournament.slug;
      this.filterCompetitors();
      this.getTournament();
    },
    axiosError(error) {
      if (error.response) {
        Bugsnag.notify(error, event => {
          if (this.user !== null) event.setUser(this.user.id, this.user.email, this.user.full_name);
          event.addMetadata('Axios', {
            status: error.response.status,
            statusText: error.response.statusText,
            headers: error.response.headers,
            data: error.response.data
          });
        });
      } else {
        Bugsnag.notify(error);
      }
    },
    getTournament() {
      if (this.tournamentSlug !== null && this.changeCount) {
        axios.get(`/tournaments/${this.tournamentSlug}/show`).then(({data}) => {
          this.tournament = data.tournament;
          this.filterCompetitors();
          let list = [];
          this.tournament.regform.forEach(function (detail) {
            list.push({'name': detail.option, 'selected': null})
          });
          this.selectedOptions = list;
          this.changeCount = 0
        }).catch((error) => {
          this.axiosError(error)
        });
      }
    },

    validPrintColumn(detail) {
      return detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header' && detail.type !== 'waiver';
    },
    requestedTime(competitor) {
      let index = competitor.line_time;
      if (index == null) return '';
      let time = this.tournament.line_times.find((t) => parseInt(t.id) === parseInt(index));
      if (time) return time.time;
      return '';
    },
    comboTable() {
      let string = '';
      let details = {};
      string += '"Name","Email"';
      for (let detail of this.tournament.regform) {
        if (this.validPrintColumn(detail)) {
          string += ',"' + detail.name + '"';
          details[detail.option] = detail.multiple ? [] : '';
        }
      }
      if (this.tournament.line_times.length > 1) string += ',"Requested Time"';
      for (let index in this.tournament.rounds) {
        string += `,"Line Time ${index + 1}","Location ${index + 1}","`;
        string += this.baleWord + ` ${index + 1}"`;
        string += `,"Position ${index + 1}"`
      }
      string += '\n';
      for (let competitor of this.competitorList) {
        string += `"${competitor.name}","${competitor.email}",`;
        let competitorDetail = Object.assign({}, details);
        for (let detail of competitor.regform) {
          competitorDetail[detail.option] = detail.multiple ? detail.multiple : detail.name;
        }
        for (let detail of this.tournament.regform) {
          if (this.validPrintColumn(detail)) {
            if (competitorDetail[detail.option] && competitorDetail[detail.option].length) {
              string += (detail.type === 'checkbox' ? `"${competitorDetail[detail.option].join(' - ')}"` : `"${competitorDetail[detail.option]}"`) + ',';
            } else {
              string += ','
            }
          }
        }
        if (this.tournament.line_times.length > 1) string += `"${this.requestedTime(competitor)}",`;
        for (let index in this.tournament.rounds) {
          const round = this.tournament.rounds[index];
          let assignment = competitor.assignments[round.id];
          if (assignment != null) {
            let time;
            if (assignment.line_time != null) time = round.line_times.find(f => f.id === parseInt(assignment.line_time));
            string += (index > 0 ? ',' : '') + (time != null ? `"${time.time}"` : '') + ','
              + (assignment.location ? '"' + assignment.location + '"' : '') + ','
              + (assignment.bale ? assignment.bale : '') + ','
              + (assignment.position ? assignment.position : '');
          }
        }
        string += '\n';
      }
      return string;
    },
    tableData() {
      let string = '';
      if (this.round === null) {
        let details = {};
        string += '"First Name", "Last Name","Status","Email"';
        if (this.tournament.line_times.length > 1) string += ',"Requested Time"';
        for (let extra of this.tournament.payments.pricing_extras ?? []) {
          string += `,"${extra.name} (${extra.type}${extra.amount})"`;
        }
        for (let detail of this.tournament.regform) {
          if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header') {
            string += `,"${detail.name}"`;
            details[detail.option] = detail.multiple ? [] : '';
          }
        }
        string += `${this.tournament.targets.length > 1 ? ',"Target"' : ''}\n`;
        for (let competitor of this.competitorList) {
          let status = [];
          if (!competitor.paid) {
            status.push('unpaid');
          } else if (competitor.paid_reason) {
            status.push(competitor.paid_reason)
          } else {
            status.push('paid')
          }
          if (competitor.wait_list) status.push('wait listed');
          if (competitor.withdrew) status.push('withdrew');
          string += `"${competitor.first_name}","${competitor.last_name}",`;
          string += status.length ? `"${status.join(' - ')}",` : '';
          string += `"${competitor.email}",`;
          if (this.tournament.line_times.length > 1) string += `"${this.requestedTime(competitor)}",`;
          for (let extra of this.tournament.payments.pricing_extras ?? []) {
            let selected = false
            if (competitor.pricing_extras && competitor.pricing_extras.length) {
              let exists = [];
              if (extra.hasOwnProperty('id')) {
                exists = competitor.pricing_extras.filter((e) => e.id === extra.id);
              } else {
                exists = competitor.pricing_extras.filter((e) => e.name === extra.name);
              }
              if (exists.length > 0) selected = true;
            }
            string += `"${selected ? 'yes' : 'no'}",`;
          }
          let competitorDetail = Object.assign({}, details);
          for (let detail of competitor.regform) {
            competitorDetail[detail.option] = detail.multiple ? detail.multiple : detail.name;
          }
          for (let detail of this.tournament.regform) {
            if (detail.option !== 'name' && detail.option !== 'email' && detail.type !== 'header') {
              if (competitorDetail[detail.option] && competitorDetail[detail.option].length) {
                string += (detail.type === 'checkbox' ? `"${competitorDetail[detail.option].join(' - ')}"` : `"${competitorDetail[detail.option]}"`) + ',';
              } else {
                if (detail.option === 'first_name') {
                  string += `${competitor.first_name},`;
                } else if (detail.option === 'last_name') {
                  string += `${competitor.last_name},`;
                } else {
                  string += ','
                }
              }
            }
          }
          if (this.tournament.targets.length <= 1) string = string.slice(0, -1);
          string += this.targetName(competitor);
        }
      } else {
        string += `"First Name","Last Name","Line Time","Location","${this.baleWord}","Position","Approved for Round"\n`;
        for (let competitor of this.competitorList) {
          let assignment = competitor.assignments[this.round.id];
          string += `"${competitor.first_name}",${competitor.last_name ? competitor.last_name : ''},`;
          let time = this.round.line_times.find(f => f.id === parseInt(assignment.line_time));
          string += (time ? `"${time.time}"` : '') + ','
            + (assignment.location ? '"' + assignment.location + '"' : '') + ','
            + (assignment.bale ? assignment.bale : '') + ','
            + (assignment.position ? assignment.position : '') + ','
            + (assignment.approved ? 'yes' : 'no') + '\n';
        }
      }
      return string
    },
  }
})