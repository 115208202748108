<template>
  <div class="p-1 mb-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
    <div class="bs-form-box" :class="[baleStatus() === 'full'?'complete':'incomplete']">
      {{ baleWord }} {{ bale.name }}
      ({{ baleCompetitorsCount }}/{{ tournament.bale_competitors }})
    </div>
    <div v-for="position in baleCompetitors" style="margin-bottom:1px;" class="relative"
         :key="round.id + '_' +bale.name +'_'+position.position">
      <div v-if="position.position || position.competitor" class="flex flex-col">
        <div class="flex-grow bs-form-box nameHolder">
          <div class="btn rounded-r-none w-10 flex-none" :class="position.competitor ? 'btn-green-500' : 'btn-red-500'">
            <span>{{ position.position }}</span>
          </div>
          <input
            class="searchBox h-center boxContent"
            :ref="'input_'+position.position"
            v-model="search" tabindex="1"
            :placeholder="positionSet(position) ? competitors[position.competitor].name : 'type to filter'"
            v-if="editing === position.position">
          <div v-else class="h-center boxContent" :class="position.competitor ? '' : 'text-gray-500'">
            <span class="nameBox">
              {{ positionSet(position) ? competitors[position.competitor].name : 'Open' }}
            </span>
          </div>
          <div
            v-if="canEdit(position)"
            @click="editPosition(position) "
            title="edit position"
            :class="editing === position.position ? 'btn border-none' : 'btn btn-gray-400'"
            class="editPosition">
            <span v-if="editing !== position.position"><i class="fas fa-pencil-alt"></i></span>
            <span v-else class="h-center h-full"><i class="fas fa-times"></i></span>
          </div>
        </div>
        <AssignedExtras :competitor="competitors[position.competitor]" :tournament="tournament"/>
      </div>
      <div v-if="editing === position.position" class="searchListContainer">
        <div class="searchList">
          <button
            v-if="position.competitor"
            class="searchListItem"
            @click.prevent="remove(position.competitor, position.position)">
            <i class="fas fa-trash"></i> REMOVE COMPETITOR
          </button>
          <button v-if="!searchList.length" class="searchListItem">No Competitors</button>
          <button
            v-if="position.position" v-for="(competitor, index) in searchList"
            @click.prevent="selected(competitor,position)"
            class="searchListItem" :tabindex="index+2">
            {{ competitor.name }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="reloaded"></div>
  </div>
</template>

<script>

import {BsAlert} from "../../mixins/swal_mixins";
import AssignedExtras from "./AssignedExtras.vue";
import {useAssignmentStore} from "../../stores/assignmentStore";

export default {
  name: "BaleAssignment",
  components: {AssignedExtras},
  props: ['tournament', 'round', 'lineTime', 'location', 'bale'],
  data() {
    return {
      value: null,
      baleCompetitors: null,
      editing: null,
      search: '',
      highlighted: '',
      store: useAssignmentStore(),
    }
  },
  created() {
    this.setBaleCompetitors();
  },
  computed: {
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    baleAssigned() {
      let timeAssigned = this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id];
      return timeAssigned.locations[this.location.id].bales[this.bale.name];
    },
    competitors() {
      return this.tournament.competitorList;
    },
    reloaded() {
      let reloaded = this.$store.getters['tournament/getReloaded'];
      this.setBaleCompetitors();
      return reloaded;
    },
    assignments() {
      return this.$store.getters['tournament/getAssignments'];
    },
    selectOptions() {
      if (this.store.showUnassigned) {
        return Object.values(this.allUnassigned);
      }
      return Object.values(this.competitors);
    },
    searchList() {
      return this.selectOptions.filter(f => f.name.toUpperCase().includes(this.search.toUpperCase()));
    },
    assigned() {
      let ids = this.baleAssigned.competitors;
      return Object.keys(this.competitors)
                   .filter(key => ids.includes(parseInt(key) ? parseInt(key) : key))
                   .reduce((obj, key) => {
                     obj[key] = this.competitors[key];
                     return obj;
                   }, {});
    },
    unassignedTime() {
      let ids = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors;
      return Object.keys(this.competitors)
                   .filter(key => ids.includes(parseInt(key) ? parseInt(key) : key))
                   .reduce((obj, key) => {
                     obj[key] = this.competitors[key];
                     return obj;
                   }, {});
    },
    unassignedNoTime() {
      let ids = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors;
      return Object.keys(this.competitors)
                   .filter(key => ids.includes(parseInt(key) ? parseInt(key) : key))
                   .reduce((obj, key) => {
                     obj[key] = this.competitors[key];
                     return obj;
                   }, {});
    },
    user() {
      return this.$store.state.user.user
    },
    baleCompetitorsCount() {
      return Object.values(this.assigned).length;
    },
    allUnassigned() {
      let list = [];
      Object.values(this.tournament.unassignedCompetitors.rounds[this.round.id].line_times).forEach(function (time) {
        list = list.concat(time.competitors);
      });
      return Object.keys(this.competitors)
                   .filter(key => list.includes(parseInt(key) ? parseInt(key) : key))
                   .reduce((obj, key) => {
                     obj[key] = this.competitors[key];
                     return obj;
                   }, {});
    },
    justMe() {
      if (this.user) {
        let me = Object.values(this.allUnassigned).find(f => f.user_id === this.user.id);
        return me ? [me] : [];
      }
      return [];
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  methods: {
    canEdit(position) {
      if (this.user) {
        if (this.user.hasOwnProperty('auth_manage') && this.user.auth_manage) return true;
        if (this.positionSet(position)) {
          if (this.competitors[position.competitor].user_id === this.user.id && this.tournament.self_assignment) {
            return true;
          }
        }
      }
      return false;
    },
    
    positionSet(position) {
      if (position.competitor) {
        return this.competitors[position.competitor] != null;
      }
      return false;
    },
    editPosition(position) {
      if (this.editing === position.position) {
        this.editing = null;
        this.search = '';
      } else {
        this.editing = position.position;
        this.$nextTick(() => {
          this.$refs['input_' + position.position][0].focus();
        })
      }
    },
    setBaleCompetitors() {
      this.baleCompetitors = this.baleAssigned.positions;
    },
    baleStatus() {
      let competitors = this.baleCompetitorsCount;
      if (competitors >= this.tournament.bale_competitors) {
        return 'full'
      } else if (competitors === 0) {
        return 'empty'
      }
      return 'available'
    },
    remove(competitor, position) {
      let time = 'noTime';
      position = position ? position : "";
      this.baleCompetitors[position].competitor = null;
      let ids = this.baleAssigned.competitors;
      let exists = ids.indexOf(competitor);
      ids.splice(exists, 1);
      this.search = '';
      this.editing = null;
      if (this.online) {
        this.url = '/tournaments/' + this.tournament.slug + '/assignment/remove';
        this.$axios.post(this.url, {
          'competitor': competitor,
          'round': this.round.id
        })
            .then(({data}) => {
              time = 'noTime';
              if (data.lineTime) {
                time = data.lineTime;
              }
              this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.push(competitor);
              this.updateCounts('remove', time);
              this.$store.commit('tournament/UPDATE_ASSIGNMENT', {
                'competitor': competitor,
                'round': this.round.id,
                'details': {time: null, location: null, bale: null, position: null}
                
              });
            })
            .catch(({response}) => {
              console.log(response);
            });
      } else {
        time = this.round.line_times.find(t => t.id === this.competitors[competitor].line_time) ? this.competitors[competitor].line_time : 'noTime';
        this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.push(competitor);
        let detail = {
          competitor: competitor,
          round: this.round.id,
          time: time === 'noTime' ? null : time,
          location: null,
          bale: null
        };
        this.updateOffline(detail);
        this.updateCounts('remove', time);
        
      }
    },
    selected(competitor, position) {
      let current = Object.values(this.tournament.assignments).find((f) => {
        return f.competitor_id === competitor.id && f.round_id === this.round.id;
      });
      let currentText = '<div class="text-left w-2/3 mx-auto mb-2">';
      let rTime = null;
      if (competitor.line_time && parseInt(competitor.line_time) !== this.lineTime.id) {
        rTime = Object.values(this.round.line_times).find(f => f.id === parseInt(competitor.line_time));
        if (rTime) {
          currentText += 'Requested Time: ' + rTime.time + '<br>';
        }
      }
      if (current != null && current.line_time) {
        let time = Object.values(this.round.line_times).find(f => f.id === parseInt(current.line_time)).time;
        currentText += this.round.line_times.length > 1 ? 'Line Time: ' + time + '<br>' : '';
        if (current.location && current.bale && current.position) {
          currentText += this.round.locations.length > 1 ? 'Location: ' + current.location + '<br>' : '';
          currentText += this.baleWord + ': ' + current.bale + '<br>';
          currentText += 'Position: ' + current.position;
          currentText += '</div>Would you like to move them to this position?';
          BsAlert.fire({
            titleText: competitor.name + ' is already assigned',
            icon: 'info',
            html: currentText,
            cancelButtonText: position.competitor ? 'Swap Competitors' : 'Cancel',
            confirmButtonText: position.competitor ? 'Replace Current' : 'Confirm',
            showCloseButton: true,
          }).then(({dismiss}) => {
            if (position.competitor && dismiss && dismiss === 'cancel') {
              this.assignCompetitor(competitor.id, position.position, current, position.competitor, true);
            }
            if (!dismiss) {
              this.assignCompetitor(competitor.id, position.position, current, position.competitor, false);
            }
          });
        } else {
          if (rTime) {
            currentText += '</div>Would you like to move them to this position?';
            BsAlert.fire({
              titleText: competitor.name + ' requested a different Line Time',
              icon: 'info',
              html: currentText,
              showCloseButton: true,
            }).then(({dismiss}) => {
              if (!dismiss) {
                this.assignCompetitor(competitor.id, position.position, null, position.competitor, false);
              }
            });
          } else {
            this.assignCompetitor(competitor.id, position.position, null, position.competitor, false)
          }
          
        }
      } else {
        this.assignCompetitor(competitor.id, position.position, null, position.competitor, false)
      }
    },
    updateOffline(detail) {
      this.$store.dispatch('tournament/updateOfflineAssignments', detail);
    },
    
    assignCompetitor(newCompetitor, newPosition, oldPosition, oldCompetitor, swap) {
      let newBale = this.baleAssigned;
      newBale.competitors.push(newCompetitor);
      newBale.positions[newPosition].competitor = newCompetitor;
      let detailNew = {
        round: this.round.id,
        time: this.lineTime.id,
        location: this.location.name,
        bale: this.bale.name,
        position: newPosition,
      };
      let detailRemove = {round: this.round.id, time: null, location: null, bale: null, position: null};
      let updates = [];
      updates.push({competitor: newCompetitor, detail: detailNew});
      let loc = oldPosition ? this.round.locations.find(f => f.name === oldPosition.location).id : null;
      let oldBale = oldPosition ? this.tournament.assignedCompetitors.rounds[this.round.id].line_times[oldPosition.line_time].locations[loc].bales[oldPosition.bale] : null;
      if (oldCompetitor && oldPosition) {
        if (swap) {
          newBale.competitors.push(newCompetitor);
          newBale.positions[newPosition].competitor = newCompetitor;
          oldBale.competitors.push(oldCompetitor);
          oldBale.positions[oldPosition.position].competitor = oldCompetitor;
          let oldIndex = oldBale.competitors.indexOf(newCompetitor);
          oldBale.competitors.splice(oldIndex, 1);
          let newIndex = newBale.competitors.indexOf(oldCompetitor);
          newBale.competitors.splice(newIndex, 1);
          let detailOld = {
            round: this.round.id,
            time: oldPosition.line_time,
            location: oldPosition.location,
            bale: oldPosition.bale,
            position: oldPosition.position,
          };
          updates.push({competitor: oldCompetitor, detail: detailOld});
        } else {
          //clear old position
          let oldIndex = oldBale.competitors.indexOf(oldCompetitor);
          oldBale.competitors.splice(oldIndex, 1);
          oldBale.positions[oldPosition.position].competitor = null;
          //clear old competitor from new position
          let newIndex = newBale.competitors.indexOf(oldCompetitor);
          newBale.competitors.splice(newIndex, 1);
          updates.push({competitor: oldCompetitor, detail: detailRemove});
          //decrease count
          this.tournament.assignedCompetitors.rounds[this.round.id].line_times[oldPosition.line_time].locations[loc].competitors_count--;
          this.tournament.assignedCompetitors.rounds[this.round.id].line_times[oldPosition.line_time].competitors_count--;
          this.tournament.assignedCompetitors.rounds[this.round.id].competitors_count--;
          this.tournament.assignedCompetitors.competitors_count--;
          
          let requestedTime = this.competitors[oldCompetitor].line_time;
          let time = this.round.line_times.find(t => t.id === parseInt(requestedTime)) ? requestedTime : null;
          if (time != null) {
            this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors_count++;
            this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count++;
            this.tournament.unassignedCompetitors.competitors_count++;
          }
        }
      } else if (oldPosition && !oldCompetitor) {
        //clear old position
        let oldIndex = oldBale.competitors.indexOf(newCompetitor);
        oldBale.competitors.splice(oldIndex, 1);
        oldBale.positions[oldPosition.position].competitor = null;
        //move count
        if (parseInt(oldPosition.line_time) !== this.lineTime.id) {
          if (oldPosition.location !== this.location.name) {
            this.tournament.assignedCompetitors.rounds[this.round.id].line_times[oldPosition.line_time].locations[loc].competitors_count--;
            this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].competitors_count++;
          } else {
            this.tournament.assignedCompetitors.rounds[this.round.id].line_times[oldPosition.line_time].competitors_count--;
            this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors_count++;
          }
        } else if (oldPosition.location !== this.location.name) {
          this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[loc].competitors_count--;
          this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].competitors_count++;
        }
      } else if (oldCompetitor && !oldPosition) {
        //clear old competitor from new position
        let newIndex = newBale.competitors.indexOf(oldCompetitor);
        newBale.competitors.splice(newIndex, 1);
        
        let requestedTime = this.competitors[oldCompetitor].line_time;
        let time = this.round.line_times.find(t => t.id === parseInt(requestedTime)) ? requestedTime : 'noTime';
        this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.push(oldCompetitor);
        this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors_count++;
        this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count++;
        this.tournament.unassignedCompetitors.competitors_count++;
        
        updates.push({competitor: oldCompetitor, detail: detailRemove});
        
        let exists = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors.indexOf(newCompetitor);
        if (exists === -1) {
          let requestedTime = this.competitors[newCompetitor].line_time;
          let time = this.round.line_times.find(t => t.id === parseInt(requestedTime)) ? requestedTime : null;
          if (time) {
            exists = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.indexOf(newCompetitor);
            this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.splice(exists, 1);
            this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors_count--;
            this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count--;
          }
        } else {
          this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors.splice(exists, 1);
          this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors_count--;
          this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count--;
        }
        
      } else {
        let exists = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors.indexOf(newCompetitor);
        if (exists === -1) {
          let requestedTime = this.competitors[newCompetitor].line_time;
          let time = this.round.line_times.find(t => t.id === parseInt(requestedTime)) ? requestedTime : null;
          if (time) {
            exists = this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.indexOf(newCompetitor);
            this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors.splice(exists, 1);
            this.updateCounts('add', time);
          }
        } else {
          this.tournament.unassignedCompetitors.rounds[this.round.id].line_times['noTime'].competitors.splice(exists, 1);
          this.updateCounts('add', 'noTime');
        }
      }
      updates.forEach((update) => {
        this.updateAssignment(update)
      });
    },
    
    async updateAssignment(update) {
      this.search = '';
      this.editing = null;
      await this.$axios.post('/tournaments/' + this.tournament.slug + '/assignment', {
        'competitor': update.competitor,
        'details': update.detail,
      }).then(({data}) => {
        if (data.success) {
          this.search = '';
          this.editing = null;
          this.$store.commit('tournament/UPDATE_ASSIGNMENT', {
            'competitor': update.competitor,
            'round': this.round.id,
            'details': update.detail,
          });
        }
      }).catch(() => {
        update.detail['competitor'] = update.competitor;
        this.updateOffline(update.detail);
      });
    },
    
    updateCounts(type, time) {
      if (type === 'remove') {
        this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].competitors_count--;
        this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors_count--;
        this.tournament.assignedCompetitors.rounds[this.round.id].competitors_count--;
        this.tournament.assignedCompetitors.competitors_count--;
        
        this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors_count++;
        this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count++;
        this.tournament.unassignedCompetitors.competitors_count++;
      } else if (type === 'add') {
        this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].competitors_count++;
        this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors_count++;
        this.tournament.assignedCompetitors.rounds[this.round.id].competitors_count++;
        this.tournament.assignedCompetitors.competitors_count++;
        
        this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[time].competitors_count--;
        this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count--;
        this.tournament.unassignedCompetitors.competitors_count--;
      }
    }
  }
}
</script>

<style scoped>

.border-none {
  border: none;
}

.boxContent {
  @apply text-center flex-grow overflow-x-hidden mx-2;
}

.nameBox {
  @apply overflow-x-hidden;
  text-overflow: ellipsis;
}

.editPosition {
  @apply cursor-pointer rounded-l-none;
}

.nameHolder {
  @apply flex justify-between p-0;
  overflow: hidden;
  white-space: nowrap;
}

.searchBox {
  @apply flex-grow pl-2;
}

.searchBox:focus {
  outline: none;
}

.searchListContainer {
  @apply bg-blue-800 text-blue-200 rounded-b absolute w-full z-10;
}

.searchList {
  @apply m-2 overflow-y-auto max-h-xxs flex flex-col;
  scrollbar-color: #bee3f8 transparent;
  
}

.searchListItem {
  @apply text-left;
}

.searchListItem:hover,
.searchListItem:focus {
  @apply bg-blue-600 cursor-pointer;
  outline: none;
}

.complete {
  @apply bg-blue-700 text-blue-200 rounded;
}

.incomplete {
  @apply bg-blue-200 text-blue-600 rounded;
}
</style>
