<script setup>

import {useManageStore} from "../../../../stores/manageStore";
import {computed} from "vue";

const manage = useManageStore();
const selectedOptions = computed(() => manage.selectedOptions);

</script>

<template>
  <div class="bg-blue-100 shadow-inner pb-2 mb-2 flow-root" @click="manage.getTournament()">
    <div v-for="(detail,index) in manage.tournament.regform.filter(f => f.multiple !== null)" class="btn-group ml-2">
      <div
        v-if="selectedOptions && selectedOptions.find(f => f.name === detail.option).selected"
        @click="manage.setSelectedOption(detail.option, null)"
        class="btn-sm btn-outline-gray-600">
        X
      </div>
      <dropdown :key="index + 'detail'">
        <template v-slot:trigger>
          <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
            {{ detail.name }}
            {{ manage.selectedOption(detail) }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a class="dropdown-item" href=""
           @click.prevent="manage.setSelectedOption(detail.option,'none')">
          none selected
        </a>
        <a v-for="options in detail.multiple" class="dropdown-item" href=""
           @click.prevent="manage.setSelectedOption(detail.option, options)">
          {{ options.label }}
        </a>
      </dropdown>
    </div>
    <div class="ml-2 btn-group">
      <div v-if="manage.selectedTarget" @click="manage.setSelectedTarget(null)" class="btn-sm btn-outline-gray-600">
        X
      </div>
      <dropdown :key="'target_dropdown'">
        <template v-slot:trigger>
          <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
            Target
            {{
              !manage.selectedTarget ? '' : ': ' + (manage.selectedTarget === 'none' ? 'none' : manage.selectedTarget.name)
            }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a class="dropdown-item" href="" @click.prevent="manage.setSelectedTarget('none')">none
          selected</a>
        <a v-for="target in manage.tournament.targets" class="dropdown-item" href=""
           @click.prevent="manage.setSelectedTarget(target)">{{ target.name }}</a>
      </dropdown>
    </div>
    <div class="dropdown ml-2 btn-group">
      <div v-if="manage.selectedStatus" @click="manage.setSelectedStatus(null)" class="btn-sm btn-outline-gray-600">X
      </div>
      <dropdown>
        <template v-slot:trigger>
          <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
            Status{{ !manage.selectedStatus ? '' : ': ' + manage.selectedStatus }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a v-for="status in ['paid','unpaid','withdrew','waitlist']" class="dropdown-item"
           href=""
           @click.prevent="manage.setSelectedStatus(status)">{{ status }}</a>
      </dropdown>
    </div>
    <div v-if="manage.tournament.payments.pricing_extras && manage.tournament.payments.pricing_extras.length"
         class="dropdown ml-2 btn-group">
      <div v-if="manage.selectedExtra" @click="manage.setSelectedExtra(null)" class="btn-sm btn-outline-gray-600">X
      </div>
      <dropdown>
        <template v-slot:trigger>
          <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
            Pricing Extras{{ !manage.selectedExtra ? '' : ': ' + manage.selectedExtra.name }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a v-for="extra in manage.tournament.payments.pricing_extras" class="dropdown-item"
           href=""
           @click.prevent="manage.setSelectedExtra(extra)">{{ extra.name }}</a>
      </dropdown>
    </div>
    
    <template v-if="manage.round !== null">
      <div class="dropdown ml-2 btn-group">
        <div v-if="manage.selectedTime" @click="manage.setSelectedTime(null)"
             class="btn-sm btn-outline-gray-600">X
        </div>
        <dropdown :key="'time_dropdown'">
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              Line Time{{
                !manage.selectedTime ? '' : ': ' + (manage.selectedTime === 'none' ? 'none' :
                  manage.selectedTime.time)
              }}
              <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a class="dropdown-item" href="" @click.prevent="manage.setSelectedTime('none')">none selected</a>
          <a v-for="line_time in manage.round.line_times"
             class="dropdown-item"
             href=""
             @click.prevent="manage.setSelectedTime(line_time)">
            {{ line_time.time }}
          </a>
        </dropdown>
      </div>
      <div class="dropdown ml-2 btn-group">
        <div v-if="manage.selectedLocation" @click="manage.setSelectedLocation(null)"
             class="btn-sm btn-outline-gray-600">X
        </div>
        <dropdown>
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              Location{{
                !manage.selectedLocation ? '' : ': ' + (manage.selectedLocation === 'none' ?
                  'none' :
                  manage.selectedLocation.name)
              }}
              <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a class="dropdown-item" href="" @click.prevent="manage.setSelectedLocation('none')">none selected</a>
          <a v-for="location in manage.round.locations" class="dropdown-item" href=""
             @click.prevent="manage.setSelectedLocation(location)">{{ location.name }}</a>
        </dropdown>
      </div>
      <div class="dropdown ml-2 btn-group">
        <div v-if="manage.selectedBale" @click="manage.setSelectedBale(null)"
             class="btn-sm btn-outline-gray-600">X
        </div>
        <dropdown>
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              {{ manage.baleWord }}{{ !manage.selectedBale ? '' : ': ' + manage.selectedBale }}
              <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a class="dropdown-item" href="" @click.prevent="manage.setSelectedBale('none')">none selected</a>
          <a v-for="bale in manage.allBales" class="dropdown-item" href=""
             @click.prevent="manage.setSelectedBale(bale)">{{ bale }}</a>
        </dropdown>
      </div>
      <div class="dropdown ml-2 btn-group">
        <div v-if="manage.selectedApproved" @click="manage.setSelectedApproved(null)"
             class="btn-sm btn-outline-gray-600">X
        </div>
        <dropdown>
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              Approved{{ !manage.selectedApproved ? '' : ': ' + manage.selectedApproved }}
              <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a v-for="approved in ['yes','no']" class="dropdown-item" href=""
             @click.prevent="manage.setSelectedApproved(approved)">{{ approved }}</a>
        </dropdown>
      </div>
    </template>
    <div class="w-full sm:w-1/2 md:w-2/5 lg:w-1/3 xl:w-1/4 float-right mt-2 px-2">
      <div class="bs-form-box flex">
        <div class="">
          <span class="input-group-text"><i class="fas fa-search"></i></span>
        </div>
        <input type="text" class="searchBox" v-model="manage.search" @keyup="manage.filterCompetitors()">
        <div v-if="manage.search?.length" class="" @click="manage.clearSearch()">
          <span class=""><i class="fas fa-times"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.searchBox {
  @apply ml-2 flex-grow;
  border: none;
  outline: none;
  
}

.btn-group {
  @apply mt-2;
}
</style>