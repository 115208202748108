<script setup>

</script>

<template>
  <div class="text centered">
    <div class="flex-col-reverse lg:flex-row flex justify-between">
      <div class="copyright"> Copyright Scoring Tech 2024</div>
      <div class="flex flex-col lg:flex-row lg:justify-between">
        <a href="/contact_us" target="_blank" class="link">Contact Us</a>
        <a href="/terms_of_service" target="_blank" class="link">Terms and Conditions</a>
        <a href="/privacy_policy" target="_blank" class="link">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.centered {
  margin: 200px 16px 72px;
}

@media (min-width: 1100px) {
  .link {
    margin-right: 45px;
  }
  
  .copyright {
    margin-left: 45px;
  }
}

.link {
  margin-bottom: 22px;
  color: #000000;
}

.text {
  @apply font-semibold;
  font-size: 16px;
  color: #9D9D9D;
  letter-spacing: .32px;
  line-height: 19px;
}
</style>