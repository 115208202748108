<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Quick Setup Codes
    </div>
    <div class="card-body">
      <div class="text-gray-700">
        Enter one of these codes on the scoring app, or at
        <a href="/authorize" class="text-blue-600 cursor-pointer" target="_blank">bowscore.com/authorize</a>.
        The device will be redirected to the tournament
        and locked to the scoring page with full scoring authorization.
      </div>
      <master-code :tournament="tournament"/>
      <div v-if="codes">
        <hr class="my-3">
        <div class="text-gray-700">
          The following codes can be used to restrict a scoring device to the indicated {{ baleWord }}.
          The device will be fully authorized to score, and the restrictions can be edited/removed by clicking the
          <i class="fas fa-lock"></i> icon on the scoring page of the device.
        </div>
        <div v-if="codeLength" class="flex flex-col-reverse sm:flex-col justify-between">
          <div>
            <div v-for="ladder in tournament.ladders">
              <h1 class="text-3xl flex cursor-pointer">
                Ladder {{ ladder.name }} : {{ ladderCode(ladder.id, null, null) }}
              </h1>
            
            </div>
            <div v-for="(round, r) in roundsWithCodes">
              <h1 class="text-3xl flex cursor-pointer">
                Round {{ round.name }}: {{ codes[round.id]['code'] ?? '' }}
                <quick-setup-buttons @updated="getCodes" :tournament="tournament" :round="round"/>
              </h1>
              <div>
                <div v-for="(line_time, t) in round.line_times" class="ml-3">
                  <h2 class="text-2xl flex cursor-pointer">
                    Line Time {{ line_time.time }}
                    <quick-setup-buttons @updated="getCodes" :tournament="tournament" :round="round" :time="line_time"/>
                  </h2>
                  <div>
                    <div v-for="(loc, l) in round.locations" class="ml-3">
                      <h3 class="text-xl cursor-pointer flex">
                        Location {{ loc.name }}: {{ codes[round.id].line_times[line_time.id].locations[loc.id].code }}
                      </h3>
                      <div>
                        <div v-for="bale in parseInt(loc.bales)" class="ml-3">
                          <div class="text-lg">
                            {{ baleWord }} {{ bale }} :
                            {{ code(round.id, line_time.id, loc.id, bale) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <div v-else-if="!failed" @click="generateCodes" class="flex justify-center">
          <div class="btn-sm btn-outline-blue-600">Generate Codes
            <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
          </div>
        </div>
      </div>
      <div v-if="(codes && codeLength) || failed" class="flex justify-end">
        <div class="mr-2">
          <div @click="removeCodes" class="btn-sm btn-outline-gray-600">Remove all Codes</div>
        </div>
        <div>
          <div @click="regenerateCodes" class="btn-sm btn-outline-blue-600">Regenerate Codes</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickSetupButtons from "./QuickSetupButtons.vue";
import MasterCode from "./MasterCode.vue";
import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "QuickSetup",
  components: {MasterCode, QuickSetupButtons},
  props: ['tournament'],
  data() {
    return {
      codes: null,
      loading: false,
      failed: false
    }
  },
  computed: {
    roundsWithCodes() {
      let list = [];
      for (let round of this.tournament.rounds) {
        if (this.codes[round.id]) {
          list.push(round);
        }
      }
      return list;
    },
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    codeLength() {
      return this.codes ? Object.values(this.codes).length : 0;
    },
  },
  methods: {
    ladderStep(ladder) {
      if (ladder.shoot_out) {
        return ladder.start_ladder - 1;
      }
      return 1;
    },
    code(roundId, timeId, locId, bale) {
      return this.codes[roundId]['line_times'][timeId]['locations'][locId]['bales'][bale]['code'];
    },
    ladderCode(ladder, step, bale) {
      if (bale) {
        return this.codes[ladder]['steps'][step - 1]['bales'][bale]['code'];
      } else if (step) {
        return this.codes[ladder]['steps'][step - 1]['code'];
      } else {
        return this.codes[ladder]['code'];
      }
    },
    getCodes() {
      this.$axios
      .get('/tournaments/' + this.tournament.slug + '/get_codes')
      .then(({data}) => {
        this.codes = data.codes;
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    generateCodes() {
      let vm = this;
      vm.loading = true;
      this.$axios
      .post('/tournaments/' + vm.tournament.slug + '/generate_codes')
      .then(({data}) => {
        vm.codes = data.codes;
        vm.loading = false
      }).catch(({response}) => {
        vm.failed = true;
      });
    },
    removeCodes() {
      BsAlert.fire({
        titleText: 'Remove All Codes?',
        html: 'This will permanently erase the codes, they will no longer work for any device setup. New codes may be generated at any time.',
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios
          .post('/tournaments/' + this.tournament.slug + '/remove_codes')
          .then(({data}) => {
            this.codes = {}
          })
          .catch(({response}) => {
            console.log(response);
          })
        }
      });
      
    },
    regenerateCodes() {
      let vm = this;
      BsAlert.fire({
        title: 'Generate all new codes?',
        html: 'This will make any existing codes for this tournament invalid',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$axios
          .post('/tournaments/' + vm.tournament.slug + '/regenerate_codes')
          .then(({data}) => {
            vm.codes = data.codes;
          })
          .catch(({response}) => {
            console.log(response);
          });
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    }
  },
  mounted() {
    this.getCodes();
  }
}
</script>

<style scoped>

</style>
