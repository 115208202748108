<template>
  <div class="tab-pane mt-sm-3 mt-1 d-flex justify-between">
    <div v-if='!round.hidden || (round.hidden && isManager)' class="flex-grow">
      <div class="card">
        <div class="card-header text-center p-1" :class="unassigned.competitors_count ?'incomplete':'complete'">
          <div class="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
            <div v-if="unassigned.competitors_count" class="float-right pr-2">
              Not Assigned: {{ unassigned.competitors_count }}
            </div>
            <div v-else class="float-right pr-2">
              Ready to score!
            </div>
            <div class="float-left pl-2">
              Registered: {{ registeredCount }}
            </div>
          </div>
        </div>
        <div class="flex flex-col card-body">
          <div class="flex flex-col md:flex-row md:justify-between">
            <assignments-import :tournament="tournament" :round="round"/>
            <div>
              <div class="flex flex-col sm:flex-row justify-end">
                <div class="flex-1">
                  <form-switch
                    class="w-full"
                    @toggled="store.showUnassigned = !store.showUnassigned"
                    :model="store.showUnassigned"
                    :id="'show_unassigned'"
                    :label="'Unassigned Only'"
                    :subLabel="'Restrict the dropdown competitor list to unassigned competitors only when editing a position.'">
                  </form-switch>
                </div>
                <AssignmentExtras :tournament="tournament" class="flex-1"/>
              </div>
            </div>
          </div>
          
          <time-assignment
            v-for="lineTime in round.line_times"
            :tournament="tournament"
            :lineTime="lineTime"
            :round="round"
            :key="round.id + '_' + lineTime.id"/>
        </div>
      </div>
    </div>
    <div v-else class="flex">
      Details for this round are not publicly available.
      <tool-tip :size="'w-full'" class="text-lg flex flex-col justify-center ml-2 mb-4">
        <template v-slot:message>
          This round has been hidden by the tournament director. To make it publicly available, admin can toggle
          the switch located at Tournament -> Manage -> Edit -> Setup.
        </template>
      </tool-tip>
    </div>
  </div>
</template>

<script>
import AssignmentsImport from "./AssignmentsImport.vue";
import FormSwitch from "../partials/FormSwitch.vue";
import TimeAssignment from "./TimeAssignment.vue";
import ToolTip from "../partials/ToolTip.vue";
import AssignmentExtras from "./AssignmentExtras.vue";
import {useAssignmentStore} from "../../stores/assignmentStore";

export default {
  name: "RoundAssignment",
  components: {AssignmentExtras, ToolTip, TimeAssignment, FormSwitch, AssignmentsImport},
  props: ['tournament', 'round'],
  data() {
    return {
      showUnassigned: false,
      store: useAssignmentStore(),
    }
  },
  computed: {
    isManager() {
      return this.user && ((this.user.hasOwnProperty('auth_manage') && this.user.auth_manage));
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    unassigned() {
      return this.tournament.unassignedCompetitors.rounds[this.round.id];
    },
    registeredCount() {
      return this.tournament.unassignedCompetitors.rounds[this.round.id].competitors_count + this.tournament.assignedCompetitors.rounds[this.round.id].competitors_count;
    }
  }
}
</script>

<style scoped>
.complete {
  @apply bg-blue-600 text-blue-100 rounded;
}

.incomplete {
  @apply bg-blue-200 text-blue-600 rounded;
}
</style>
