<template>
  <div class=" flex-grow m-2">
    <div v-if="!online" class="flex justify-center text-3xl">
      This feature is not available without an active internet connection.
    </div>
    <div v-else>
      <div class="flex flex-col sm:flex-row justify-between">
        <div class="w-full lg:w-1/2 text-5xl">
          Score Logs
        </div>
        <div v-if="isAdmin" class="w-full lg:w-1/2 p-2 flex flex-wrap">
          To disable scoring visit the Manage -> Settings page for this tournament.
        </div>
      </div>
      <div class="h-center px-2 bg-red-200">
        Rows highlighted in red are from unauthorized devices and will not be included with results.
        <span v-if="isAdmin">Click a device Id to authorize scores.</span>
      </div>
      <div v-if="Object.values(scores).length" class="bg-blue-100 shadow-inner my-2">
        <div class="flex flex-col">
          <div class="flex justify-center flex-wrap">
            <dropdown v-if="tournament.rounds.length > 1" class="m-2">
              <template v-slot:trigger>
                <button type="button" class="btn btn-gray-600 dropdown-toggle">
                  Round: {{ round.name }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="r in tournament.rounds" class="dropdown-item" href=""
                 @click.prevent="round = r">{{ r.name }}</a>
            </dropdown>
            <dropdown v-if="round.line_times.length > 1" class="m-2">
              <template v-slot:trigger>
                <button type="button" class="btn btn-gray-600 dropdown-toggle">
                  Time: {{ time.time }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="t in round.line_times" class="dropdown-item" href=""
                 @click.prevent="time = t">{{ t.time }}</a>
            </dropdown>
            <div class="btn-group m-2">
              <div v-if="end" @click="end = null" class="btn btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger>
                  <button type="button" class="btn btn-gray-600 dropdown-toggle">
                    End{{ end ? ': ' + end : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="e in round.ends_count" class="dropdown-item" href="" @click.prevent="end = e">{{ e }}</a>
              </dropdown>
            </div>
            <div class="btn-group m-2">
              <div v-if="arrow" @click="arrow = null" class="btn btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger class=" ">
                  <button type="button" class="btn btn-gray-600 dropdown-toggle">
                    Arrow{{ arrow ? ': ' + arrow : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="a in round.arrows_count" class="dropdown-item" href=""
                   @click.prevent="arrow = a">{{ a }}</a>
              </dropdown>
            </div>
            <div class="btn-group m-2">
              <div v-if="device" @click="device = null" class="btn btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger class=" ">
                  <button type="button" class="btn btn-gray-600 dropdown-toggle">
                    Device{{ device ? ': ' + device : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="d in deviceList" class="dropdown-item" href=""
                   @click.prevent="device = d">{{ deviceName(d) }}</a>
              </dropdown>
            </div>
            <div class="btn-group m-2">
              <div v-if="competitor" @click="competitor = null" class="btn btn-outline-gray-600">X</div>
              <dropdown>
                <template v-slot:trigger class=" ">
                  <button type="button" class="btn btn-gray-600 dropdown-toggle">
                    Competitor{{ competitor ? ': ' + competitors[competitor].name : '' }}
                    <i class="fas fa-caret-down"></i>
                  </button>
                </template>
                <a v-for="c in sortedCompetitors" class="dropdown-item" href=""
                   @click.prevent="competitor = c.id">{{ c.name }}</a>
              </dropdown>
            
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div v-if="Object.values(allDuplicates).length" class="w-full sm:w-1/2">
          <form-switch
            :model="editsOnly"
            @toggled="editsOnly = !editsOnly"
            :id="'editsOnly'"
            :label="'Edits Only'"
            :sub-label="'This will only show scores that were edited.'"/>
        </div>
        <div class="w-full sm:w-1/2">
          <form-switch
            :model="unauthorized"
            @toggled="unauthorized = !unauthorized"
            :id="'unauthorized'"
            :label="'Only Unauthorized'"
            :sub-label="'This will only show scores that are not authorized.'"/>
        </div>
      </div>
      
      <div class="table-responsive mt-2 bg-white">
        <table class="table-bordered table">
          <thead>
          <tr>
            <th class="table-header">Time</th>
            <th class="table-header">Competitor</th>
            <th class="table-header" v-if="tournament.rounds.length > 1">Round</th>
            <th class="table-header">End</th>
            <th class="table-header">Arrow</th>
            <th class="table-header">Target Hit</th>
            <th class="table-header">Device</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="score in filteredScores" :class="!score.authorized ? 'bg-red-200' : ''">
            <td>{{ formattedTime(score.created_at) }}</td>
            <td v-if="competitors[score.competitor_id] == null">{{ 'deleted user ' + score.competitor_id }}
            </td>
            <td v-else>{{ competitors[score.competitor_id].name }}</td>
            <td v-if="tournament.rounds.length > 1">{{ roundNames[score.round] }}</td>
            <td>{{ score.end }}</td>
            <td>{{ score.arrow }}</td>
            <td>{{ score.target_hit }}</td>
            <td :class="isAdmin ? 'cursor-pointer text-blue-600' : ''" @click="toggleAuth(score)">
              {{ deviceName(score.device) }}
            </td>
          </tr>
          <tr v-if="!Object.values(filteredScores).length">
            <td colspan="10" class="text-center">
              <span v-show="loading">Gathering scores <i class="fas fa-spinner fa-spin"></i></span>
              <span v-show="!loading">
                No scores found.
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FormSwitch from "../partials/FormSwitch.vue";
import ToolTip from "../partials/ToolTip.vue";
import moment from "moment";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ScoreLog",
  components: {ToolTip, FormSwitch},
  props: ['tournamentIn', 'user'],
  data() {
    return {
      scores: {},
      deviceNames: {},
      loading: false,
      round: this.tournamentIn.rounds[0],
      time: this.tournamentIn.rounds[0].line_times[0],
      end: null,
      arrow: null,
      device: null,
      competitor: null,
      editsOnly: false,
      allDuplicates: {},
      unauthorized: false,
      tournament: this.tournamentIn,
    }
  },
  computed: {
    isAdmin() {
      return this.user && this.user.auth_manage;
    },
    competitors() {
      return this.tournament.competitorList;
    },
    sortedCompetitors: function () {
      let list = [];
      Object.keys(this.competitors).forEach(c => list.push(this.competitors[c]));
      list.sort((a, b) => {
        let aName = a.name.toLowerCase();
        let bName = b.name.toLowerCase();
        return (aName > bName) ? 1 : ((bName > aName) ? -1 : 0);
      });
      return list;
    },
    allEdited() {
      let array = Object.assign({}, Object.values(this.allDuplicates));
      let sorted = this.sortDuplicates(Object.values(array));
      return sorted;
    },
    filteredEdited() {
      let filtered = [...this.allEdited].filter(f => f.round === this.round.id);
      let cList = this.timeCompetitors;
      if (this.unauthorized) {
        filtered = filtered.filter(f => f.authorized == false);
      }
      filtered = filtered.filter(f => cList.includes(f.competitor_id));
      if (this.end) {
        filtered = filtered.filter(f => f.end === this.end);
      }
      if (this.arrow) {
        filtered = filtered.filter(f => f.arrow === this.arrow);
      }
      if (this.device) {
        filtered = filtered.filter(f => f.device === this.device);
      }
      if (this.competitor) {
        filtered = filtered.filter(f => f.competitor_id === parseInt(this.competitor));
      }
      filtered = this.sortDuplicates(filtered);
      return filtered;
    },
    timeCompetitors() {
      let list = this.tournament.assignments.filter(a => a.line_time == this.time.id).map(c => c.competitor_id);
      return list;
    },
    filteredScores() {
      if (this.editsOnly) return this.filteredEdited;
      let cList = this.timeCompetitors;
      //TODO handle postal child ids
      let filtered = Object.assign({}, Object.values(this.scores).filter(f => f.round === this.round.id));
      if (this.unauthorized) {
        filtered = Object.values(filtered).filter(f => f.authorized == false);
      }
      filtered = Object.values(filtered).filter(f => cList.includes(f.competitor_id));
      if (this.end) {
        filtered = Object.values(filtered).filter(f => f.end === this.end);
      }
      if (this.arrow) {
        filtered = Object.values(filtered).filter(f => f.arrow === this.arrow);
      }
      if (this.device) {
        filtered = Object.values(filtered).filter(f => f.device === this.device);
      }
      if (this.competitor) {
        filtered = Object.values(filtered).filter(f => f.competitor_id === parseInt(this.competitor));
      }
      
      return filtered
    },
    roundNames() {
      let list = {};
      this.tournament.rounds.forEach((round) => {
        list[round.id] = round.name;
      });
      return list;
    },
    deviceList() {
      return [...new Set(Object.values(this.filteredScores).map(score => score.device))];
    },
    
    online() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  methods: {
    formattedTime(time) {
      return moment(time + '+00:00').local().format('D MMM h:mm A');
    },
    sortDuplicates(duplicates) {
      let list = [];
      let matches = 0;
      while (duplicates.length) {
        let first = duplicates.shift();
        list.push(first);
        let match = null;
        while (match !== -1) {
          match = duplicates.findIndex(f => {
            if (f.competitor_id !== first.competitor_id) return false;
            return f.round === first.round && f.end === first.end && f.arrow === first.arrow;
          });
          if (match !== -1) {
            let found = duplicates.splice(match, 1);
            list.push(found[0]);
            matches++;
          }
        }
      }
      return list;
    },
    
    deviceName(deviceId) {
      let name = this.deviceNames[deviceId];
      return `${name != null ? `${name} - ` : ''}${deviceId}`;
    },
    toggleAuth(score) {
      if (!this.isAdmin) return;
      BsAlert.fire({
        titleText: score.authorized ? 'Deauthorize Device' : 'Authorize Device',
        html: 'Do you want to apply this to all scores added with this device, or just this one.',
        icon: 'question',
        cancelButtonText: 'All Scores',
        confirmButtonText: 'Only This Score',
        cancelButtonColor: '#3085d6',
        showCloseButton: true,
      }).then(({value, dismiss}) => {
        if (value) {
          this.$axios.post('/device/authorize', {score: score, all: false}).then(() => {
            score.authorized = !score.authorized;
          })
        } else if (dismiss === 'cancel') {
          BsAlert.fire({
            titleText: 'Are You Sure?',
            html: 'This will affect every existing score added by this device for this tournament. Any future scores will not be affected',
            icon: 'warning',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.$axios.post('/device/authorize', {score: score, all: true}).then(() => {
                return this.$axios.get('/tournaments/' + this.tournament.slug + '/scores')
                .then(({data}) => {
                  this.scores = data.scores;
                })
              })
            },
          })
        }
      });
    }
  },
  mounted() {
    this.loading = true;
    this.$axios.get('/tournaments/' + this.tournament.slug + '/scores')
    .then(({data}) => {
      this.loading = false;
      this.scores = data.scores;
      this.deviceNames = data.deviceNames;
    })
    this.$axios.get('/tournaments/' + this.tournament.slug + '/duplicate_scores')
    .then(({data}) => {
      this.allDuplicates = data.scores;
    })
  }
  
}
</script>

<style scoped>

</style>
