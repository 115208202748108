<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Unpaid Tournaments
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-header">Tournament</th>
            <th class="table-header">Club</th>
            <th class="table-header">Amount</th>
            <th class="table-header">Tournament End</th>
            <th class="table-header"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="tournament in tournaments">
            <td>{{ tournament.name }}</td>
            <td>{{ tournament.club.name }}</td>
            <td>${{ tournament.unpaid * cost }}.00</td>
            <td>{{ daysPassed(tournament.end_time) }}</td>
            <td>
              <button @click="sendInvoice(tournament)" class="btn-sm btn-outline-gray-600">invoice
              </button>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="4">Total</td>
            <td>${{ total * cost }}.00</td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from "moment";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "UnpaidTournaments",
  props: ['tournaments'],
  data() {
    return {
      cost: 2
    }
  },
  computed: {
    total() {
      let sum = 0;
      this.tournaments.forEach(function (tournament) {
        sum += tournament.unpaid;
      });
      return sum;
    }
  },
  methods: {
    daysPassed(time) {
      return moment(time).from(moment());
      
    },
    sendInvoice(tournament) {
      BsAlert.fire({
        title: 'Send Invoice?',
        text: "This will email an invoice to the tournament director.",
        icon: 'question',
        confirmButtonText: 'Yes, email invoice!',
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/godmode/invoice', {
            'tournament': tournament.slug
          })
          .then(({data}) => {
          })
          .catch(({response}) => {
            console.log(response);
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
