<script setup>

import {Form} from 'vee-validate';
import CompetitorRegistrationType from "./CompetitorRegistrationType.vue";
import CompetitorPersonalInfo from "./CompetitorPersonalInfo.vue";
import {useCompetitorStore} from "../../../stores/competitorStore";
import CompetitorFormData from "./CompetitorFormData.vue";
import {nextTick, onMounted, computed, ref, onUnmounted} from "vue";
import CompetitorWaiver from "./CompetitorWaiver.vue";
import CompetitorPurchases from "./CompetitorPurchases.vue";
import CompetitorCost from "./CompetitorCost.vue";
import CompetitorPayment from "./CompetitorPayment.vue";
import GroupSelection from "../group/GroupSelection.vue";
import GroupFormData from "../group/GroupFormData.vue";
import GroupPurchases from "../group/GroupPurchases.vue";
import GroupTotal from "../group/GroupTotal.vue";
import GroupPayment from "../group/GroupPayment.vue";
import CompetitorTarget from "./CompetitorTarget.vue";
import CompetitorTime from "./CompetitorTime.vue";
import GroupTargets from "../group/GroupTargets.vue";
import GroupTimes from "../group/GroupTimes.vue";
import moment from "moment-timezone";

const props = defineProps(['tournamentIn', 'user']);
const store = useCompetitorStore();

onMounted(() => {
  store.$reset();
  store.setDefaults(props.tournamentIn, props.user);
})
const tooEarly = computed(() => {
  let dateOpen = new Date(props.tournamentIn.reg_open);
  let momentOpen = moment(dateOpen);
  let openString = momentOpen.format('D MMM YYYY h:mm:ss A');
  let open = moment.tz(openString, 'D MMM YYYY h:mm:ss A', props.tournamentIn.timezone).utc();
  return open.isAfter(liveTimeUtc.value);
})

const liveTimeUtc = computed(() => {
  let momentLive = moment(liveTime.value);
  let liveTimeString = momentLive.format('D MMM YYYY h:mm:ss A');
  let localTimeZone = moment.tz.guess();
  return moment.tz(liveTimeString, 'D MMM YYYY h:mm:ss A', localTimeZone).utc();
})

const closeTimeUtc = computed(() => {
  let dateClose = new Date(props.tournamentIn.reg_close);
  let momentClose = moment(dateClose);
  let closeString = momentClose.format('D MMM YYYY h:mm:ss A');
  return moment.tz(closeString, 'D MMM YYYY h:mm:ss A', props.tournamentIn.timezone).utc();
})

const tooLate = computed(() => {
  return closeTimeUtc.value.isBefore(liveTimeUtc.value);
})

let zoneList = {
  'Africa/Cairo': 'EET',
  'America/Adak': 'HST',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Los_Angeles': 'PST',
  'America/New_York': 'EST',
  'America/Noronha': 'FNT',
  'America/Santiago': 'CLST',
  'America/Sao_Paulo': 'BRT',
  'Asia/Dhaka': 'BST',
  'Asia/Novosibirsk': 'NOVT',
  'Asia/Tashkent': 'UZT',
  'Asia/Tokyo': 'JST',
  'Asia/Vladivostok': 'VLAT',
  'Atlantic/Cape_Verde': 'CVT',
  'Australia/Hobart': 'AEDT',
  'Australia/Perth': 'AWST',
  'Europe/Brussels': 'CET',
  'Europe/Minsk': 'MSK',
  'Europe/Moscow': 'MSK',
  'Pacific/Gambier': 'GAMT',
  'Pacific/Midway': 'SST',
  'UTC': 'UTC'
};

const timeZone = computed(() => {
  if (props.tournamentIn.timezone in zoneList) {
    return zoneList[props.tournamentIn.timezone];
  }
  return props.tournamentIn.timezone;
})

const currentTime = computed(() => {
  let time = moment(liveTime.value);
  return moment.tz(time, props.tournamentIn.timezone).format('D MMM YYYY h:mm:ss A z');
})

const timeLeft = computed(() => {
  return closeTimeUtc.value.from(liveTimeUtc.value);
  
})

const minutesTilWarn = 10;

const closingSoon = computed(() => {
  return closeTimeUtc.value.diff(liveTimeUtc.value, 'minutes') <= minutesTilWarn;
})

const liveTime = ref(new Date());

onMounted(() => {
  const interval = setInterval(() => {
    liveTime.value = new Date();
  }, 1000);
  
  // Clear the interval when the component is unmounted
  onUnmounted(() => {
    clearInterval(interval);
  });
})
</script>

<template>
  <div>
    <div class="main mx-auto">
      <div v-if="user.email_verified_at === null" class="flex justify-center">
        <div class="flex flex-col text-center">
          You must verify your email before registering for tournaments.
          <a href="/email/resend" class="text-blue-600">
            resend verification email
          </a>
        </div>
      </div>
      <div v-else-if="tournamentIn.disable_registration" class="text-center mt-5 font-bold text-xl">
        Registration is disabled for this event
      </div>
      <div v-else-if="tooEarly" class="text-center mt-5 font-bold text-xl">
        Registration for this Tournament opens <br> {{ tournamentIn.reg_open }} {{ timeZone }}
        <div class="mt-5 text-sm">Current time: {{ currentTime }}</div>
      </div>
      <div v-else-if="tooLate" class="text-center mt-5 font-bold text-xl">
        Registration for this Tournament closed at <br> {{ tournamentIn.reg_close }} {{ timeZone }}
        <div class="mt-5 text-sm">current time: {{ currentTime }}</div>
      </div>
      <div v-else>
        <div v-show="store.showWaiver">
          <div class="title">
            <button @click.prevent="store.backFromForm">
              <i class="fa fa-arrow-left"></i><span class="titleLabel">View and sign the waiver</span>
            </button>
          </div>
          <CompetitorWaiver/>
        </div>
        <div v-show="!store.showWaiver">
          <div class="title">
            <a href="javascript:history.back()">
              <i class="fa-solid fa-lg fa-arrow-left"></i>
              <span class="titleLabel">Register for {{ tournamentIn.name }}</span>
            </a>
          </div>
          <div v-if="closingSoon" class="bg-yellow-400 m-2 mb-5 p-4 text-xl text-center rounded">
            <div>Registration closes</div>
            <div class="text-3xl">{{ timeLeft }}</div>
          </div>
          <Form>
            <CompetitorRegistrationType/>
            <div v-if="store.registrationType === 'self'">
              <CompetitorPersonalInfo/>
              <CompetitorTarget/>
              <CompetitorFormData/>
              <CompetitorTime/>
              <CompetitorPurchases/>
              <CompetitorCost/>
              <CompetitorPayment :stripe-key="tournamentIn.club?.stripe?.stripe_publishable_key"/>
            </div>
            <div v-else>
              <GroupSelection/>
              <GroupFormData v-if="store.groupSelectionComplete"/>
              <GroupTargets v-if="store.groupSelectionComplete"/>
              <GroupTimes v-if="store.groupSelectionComplete"/>
              <GroupPurchases v-if="store.groupSelectionComplete"/>
              <GroupTotal v-if="store.groupSelectionComplete"/>
              <GroupPayment v-show="store.groupSelectionComplete"
                            :stripe-key="tournamentIn.club?.stripe?.stripe_publishable_key"/>
            </div>
          </Form>
        </div>
      </div>
    
    </div>
    <div class="hidden xl:flex-1"></div>
  </div>


</template>


<style scoped>
.title {
  @apply ml-2;
  margin-bottom: 22px;
}

.titleLabel {
  @apply m-2;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: .35px;
}

.main {
  width: 100%;
  margin-top: 40px;
}

@media (min-width: 600px) {
  .main {
    width: 600px;
  }
}


</style>
