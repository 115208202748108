<script>
export default {
  name: "FormCheckbox",
  props: ['title', 'subTitle', 'active', 'disabled', 'noMargin', 'name', 'id'],
}
</script>

<template>
  <div class="textStyle" :class="noMargin ? '' : 'mb-2'">
    <div class="flex">
      <input :name="name" :id="id" :disabled="disabled === true" type="checkbox" :checked="active"
             @click="$emit('tapped')">
      <div class="title" :class="disabled === true ? 'disabled':''">{{ title }}</div>
    </div>
    <div class="subTitle">{{ subTitle }}</div>
  </div>
</template>

<style scoped>
.disabled {
  @apply text-stone-400 !important;
}

.textStyle {
  @apply font-medium;
  font-size: 14px;
  line-height: 17px;
}

.title {
  @apply text-black;
  margin-left: 10px;
  letter-spacing: .28px;
}

.subTitle {
  @apply text-stone-400;
  margin-left: 26px;
  margin-top: 2px;
}

input[type="checkbox"] {
  accent-color: #000000;
  width: 16px;
  height: 16px;
  border: 4px solid #000000;
  border-radius: 3px;
}

</style>