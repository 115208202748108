<script setup>

import FormSwitch from "../../../partials/FormSwitch.vue";
import {useManageStore} from "../../../../stores/manageStore";
import axios from "axios";

const manage = useManageStore();

function toggle() {
  manage.allow_edits = !manage.allow_edits;
  axios.post(`/tournaments/${manage.tournament.slug}/manage`,
    {'update': {'allow_edits': manage.tournament.allow_edits}});
  manage.incrementChange();
}
</script>

<template>
  <div>
    <form-switch
      @toggled="toggle()"
      :model="manage.tournament.allow_edits"
      :id="'AllowEditsId'"
      :label="'Allow User Edits'"
      :sub-label="'This allows users to edit their own registration details. ' +
                     'It is generally a good idea to shut this off before the tournament starts.'"/>
  </div>
</template>

<style scoped>

</style>