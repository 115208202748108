<template>
  <tr class="whitespace-nowrap" :class="dropRow ? 'bg-red-400' : ''">
    <td v-if="competitor_only" :key="`overall_place_${competitor.id}_place`" class="align-top">{{ place }}</td>
    <td v-if="!competitor_only" class="align-top text-left">
      
      <a href="" @click.prevent="setSelectedCompetitor()">{{ place }} &middot; {{ competitor.name }}</a>
      <span v-if="isAdmin && duplicates.length > 0" @click="duplicatesTapped" class="badge">
        {{ duplicates.length - 1 }}</span>
    </td>
    <td v-else class="align-top">{{ round.name }}</td>
    <td v-if="breakdown" v-for="detail in filter_list">{{ filteredDetail[detail] }}</td>
    <td v-show="!rowIsRound" class="align-top">
      {{ scores.points }}
    </td>
    <td v-for="breaker in tieBreakers" v-show="!rowIsRound" class="align-top">
      {{ scores[breaker] }}
    </td>
    <td v-if="!rowIsRound && useHandicap">{{ handicapValue }}</td>
    <td v-show="rounds.length > 1 && !rowIsRound" v-for="r in rounds"
        :key="r.id" class="align-top" :class="dropRound(r.id) ? 'bg-red-400' : ''">
      {{ scores['rounds'][r.id]['points'] }}
    </td>
    <td v-if="rowIsRound" class="align-top">
      {{ scores['rounds'][round.id]['points'] }}
    </td>
    <td v-for="breaker in tieBreakers" v-if="rowIsRound" class="align-top">
      {{ scores['rounds'][round.id][breaker] }}
    </td>
    <td v-if="rowIsRound && !breakdown" v-for="end in endCount(round)"
        :key="`headerFor_${competitor.id}_${end}_${round.slug}`" class="min-width-md align-top">
      <div v-if="detailed" class="flex flex-nowrap whitespace-nowrap ">
        <div class="text-right whitespace-nowrap">
          <div v-for="(arrow) in round.arrows_count">Arrow {{ arrow }}:</div>
          <div>Total:</div>
        </div>
        <div class="ml-2">
          <div v-for="(arrow) in round.arrows_count">{{ arrowValue(end, arrow) }}</div>
          <div class="flex">
            {{ scores['rounds'][round.id].ends[end]['points'] }}
            <div v-for="breaker in tieBreakers">
              <div class="flex"
                   v-if="scores['rounds'][round.id].ends[end][breaker] > 0">
                +{{ scores['rounds'][round.id].ends[end][breaker] }}-{{ breaker }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span v-else>
        {{ scores['rounds'][round.id].ends[end]['points'] }}
      </span>
      <div v-if="detailed && image(round.id, end)">
        <img @click="showImage(image(round.id, end))" :src="image(round.id, end)" alt="target image">
      </div>
    </td>
  </tr>

</template>

<script>
/**
 * @typedef {object} round
 * @property {string} name
 * @property {string} arrows_count
 * @property {string} ends_count
 * @property {string} slug
 * @property {string} id
 */
/**
 @typedef {object} competitor
 @property {string} name
 @property {string} id
 */

import moment from "moment";
import {mapState, mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import {BsAlert} from "../../../mixins/swal_mixins";
import user from "../../../store/modules/user";

/**
 @typedef {object} scores
 @property {string} points
 */
export default {
  name: "CompetitorScore",
  props: {
    'competitor': Object,
    'scores': Object,
    'competitor_only': Boolean,
    'place': Number,
    'detailed': Boolean,
    'selectionPlace': {'type': Number, 'default': () => 1},
    'filter_list': {'type': Array, 'default': () => []},
    'breakdown': Boolean,
    'images': [Object, Array],
    'duplicates': Array,
    'round': Object,
  },
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, [
      'useHandicap',
      'targetValues',
      'tieBreakers',
      'rounds',
      'regform',
      'user',
    ]),
    ...mapWritableState(useResultsStore, ['selectedCompetitor']),
    handicapValue() {
      return this.scores['handicap'] !== null ? this.scores['handicap'] : 0;
    },
    isAdmin() {
      return this.user && this.user.auth_manage;
    },
    dropRow() {
      return this.rowIsRound && this.scores['rounds'][this.round.id]['drop'];
    },
    filteredDetail() {
      let list = {};
      this.filter_list.forEach(detail => {
        if (detail === 'target') {
          let found = this.targetValues.find(f => f.id === this.competitor.target_id);
          if (found) {
            list[detail] = found.name;
          }
        } else {
          let found = this.competitor.regform.find(f => f.option === detail);
          if (found) {
            list[detail] = this.abbrev(detail, found.name);
          }
        }
      });
      return list;
    },
    
    rowIsRound() {
      let isRound = this.round != null && Object.keys(this.round).length;
      return isRound;
    },
    target_values() {
      let target = this.targetValues.find(f => f.id === this.competitor.target_id);
      return target ? target.values : this.targetValues[0].values;
    },
  },
  methods: {
    endCount(round) {
      if (!this.rowIsRound) return 0;
      return round.ends_count;
    },
    duplicatesTapped() {
      BsAlert.fire({
        titleText: 'Score Edits',
        html: this.duplicatesDisplay(),
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
      })
    },
    duplicatesDisplay() {
      let html = '<div class="table-responsive"><table class="table table-bordered">'
      html += '<thead><tr><th>Time</th><th>Round</th><th>End</th><th>Arrow</th><th>Hit</th></tr></thead><tbody>'
      let sorted = [...this.sortDuplicates(this.duplicates)];
      let lastGroup = '';
      let colored = true;
      for (let duplicate of sorted) {
        let group = `${duplicate.round}_${duplicate.end}_${duplicate.arrow}`;
        if (group !== lastGroup) colored = !colored;
        let time = moment(duplicate.created_at).format('MM/DD h:mma');
        let round = this.rounds.find((f) => f.id === duplicate.round).name;
        html += `<tr class="${colored ? 'bg-blue-100' : ''}">`;
        html += `<td>${time}</td><td>${round}</td><td>${duplicate.end}</td><td>${duplicate.arrow}</td>`;
        html += `<td>${duplicate.target_hit != null ? duplicate.target_hit : '-'}</td></tr>`;
        lastGroup = group;
      }
      html += '</tbody></table></div>';
      return html;
    },
    sortDuplicates(duplicates) {
      let list = [];
      let matches = 0;
      while (duplicates.length) {
        let first = duplicates.shift();
        list.push(first);
        let match = null;
        while (match !== -1) {
          match = duplicates.findIndex(f => f.round === first.round && f.end === first.end && f.arrow === first.arrow);
          if (match !== -1) {
            let found = duplicates.splice(match, 1);
            list.push(found[0]);
            matches++;
          }
        }
      }
      return list;
    },
    abbrev(type, name) {
      let detail = this.regform.find(f => f.option === type);
      if (detail) {
        let option = detail.multiple.find(f => f.label === name);
        if (option) {
          return option.abbr ? option.abbr : option.label;
        }
      }
      return name;
    },
    arrowValue(end, arrow) {
      if (this.scores['rounds'][this.round.id].ends[end].arrows[arrow].target_hit) {
        return this.scores['rounds'][this.round.id].ends[end].arrows[arrow].target_hit;
      }
      return '-'
    },
    dropRound(roundId) {
      return this.scores['rounds'][roundId]['drop'];
    },
    image(round, end) {
      if (this.images !== undefined && this.images[round] !== undefined && this.images[round][end] !== undefined) {
        let image = this.images[round][end];
        //Don't === this line.
        return image.enabled == true ? image.url : '/img/imagePending.jpg';
      }
      return null
    },
    setSelectedCompetitor() {
      this.store.selectedCompetitor = this.competitor;
      this.store.updateUrl();
    },
    showImage(url) {
      BsAlert.fire({
        html: url ? `<img src="${url}" class="mx-auto" width="400" height="400" alt="target image">` : 'No Image',
        showConfirmButton: false,
        showCloseButton: true,
        animation: false,
        showCancelButton: false,
      })
    }
  }
}
</script>

<style scoped>
th, td {
  width: 1px;
  text-align: center;
}

.flex {
  display: flex;
}

.badge {
  @apply ml-2;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

</style>
