<script setup>
const props = defineProps(['league'])
const navToStore = ((type) => {
  let appleLink = 'https://apps.apple.com/us/app/bowscore/id6480208560';
  let googleLink = 'https://play.google.com/store/apps/details?id=com.scoringtech.bowscore';
  let amazonLink = 'https://www.amazon.com/gp/product/B0D1W5G6SJ';
  window.open(type === 'apple' ? appleLink : type === 'google' ? googleLink : amazonLink);
});
</script>

<template>
  <div class="display flex flex-col lg:flex-row">
    <div class="flex-1 flex flex-col justify-center">
      <div class="title">Try it for yourself</div>
      <div class="text">Download the Bowscore app and see how easy it is to score an archery
        tournament{{ league ? ' or league' : '' }}.
        Create a Quick Shoot and you can easily score your next shoot with friends and see how Bowscore can simplify
        your next event.
      </div>
      <div class="flex flex-col lg:flex-row justify-around mt-8 mb-12">
        <img class="image" @click="navToStore('apple')"
             src="/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.png">
        <img class="image" @click="navToStore('google')" src="/img/google-play-badge.png">
        <img class="image" @click="navToStore('amazon')" src="/img/amazon-appstore-badge-english-black.png">
      </div>
    </div>
    <div class="flex-1 appImage lg:ml-12">
      <img :src="`/img/Bowscore_app_with_logo_badge2x.png`">
    </div>
  
  </div>
</template>

<style scoped>
.appImage {
  margin-left: -28px;
  margin-right: -28px;
}

.image {
  height: 50px;
  cursor: pointer;
}

.display {
  margin-top: 260px;
  margin-bottom: 290px;
  margin-right: 20px;
}

.title {
  @apply font-extrabold;
  color: #FB5202;
  font-size: 56px;
  line-height: 67px;
  margin-bottom: 23px;
}

.text {
  @apply text-stone-600;
  font-size: 24px;
  line-height: 36px;
}

@media (max-width: 1100px) {
  .display {
    @apply mx-4;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  
  .title {
    text-align: center;
  }
  
  .image {
    @apply mx-auto mb-4;
    width: 150px;
    height: 50px;
  }
}
</style>