<script setup>

import SortableHeader from "./SortableHeader.vue";
import ManageCompetitorRow from "./ManageCompetitorRow.vue";
import {useManageStore} from "../../../../stores/manageStore";
import {computed} from "vue";

const manage = useManageStore();
const emptySpan = computed(() => {
  let count = 7;
  if (manage.tournament.targets.length > 1) count++;
  return count;
})
</script>

<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead>
      <tr>
        <SortableHeader label="First Name" value="first_name"/>
        <SortableHeader label="Last Name" value="last_name"/>
        <SortableHeader v-if="manage.tournament.targets.length > 1" label="Target" value="target"/>
        <SortableHeader label="Line Time" value="line_time"/>
        <SortableHeader label="Location" value="location"/>
        <SortableHeader :label="manage.baleWord" value="bale"/>
        <SortableHeader label="Position" value="position"/>
        <th class="table-header">Approved for Round</th>
      </tr>
      </thead>
      <tbody>
      <manage-competitor-row
        v-show="manage.competitorList.length"
        v-for="competitor in manage.competitorList"
        :round="manage.round" :competitor="competitor"
        :key="competitor.id"
        :tournament="manage.tournament"
        @competitor-change="manage.incrementChange"
        @update-available="manage.updateAvailable"/>
      <tr v-show="!manage.competitorList.length">
        <td :colspan="emptySpan" class="table-empty text-center">There are no competitors matching selection.</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
th {
  white-space: nowrap;
}
</style>