<script setup>

import {ref} from "vue";

const modal = ref(false);

const toggleModal = () => modal.value = !modal.value;

</script>
<template>
  <div>
    <div @click="toggleModal">
      <slot name="open-modal"></slot>
    </div>
    
    <div v-if="modal" @click.self="toggleModal"
         class="animated fadeIn fixed inset-0 z-50 overflow-auto bg-smoke-500 flex">
      <div
        class="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex shadow rounded mt-20 md:mt-16 overflow-auto">
        <slot name="modal-title"></slot>
        <slot name="modal-body"></slot>
        <slot name="modal-footer"></slot>
        <button @click="toggleModal" id="closeModal" class="closeButton">
          <i class="fa-regular fa-2x fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.closeButton {
  @apply absolute top-0 right-0 pt-4 px-4;
}
</style>