<template xmlns="http://www.w3.org/1999/html">
  <div class="mb-2 p-2">
    <div class="flex flex-col justify-between sm:flex-row">
      <div class="flex">
        <h2 class="text-2xl">{{ time.time }} <span v-if="memo">({{ memo }})</span>
          <small v-if="remainingSpots" class="text-blue-600">
            {{ remainingSpots }} Open spots
          </small>
          <small v-else class="text-red-600">
            Full
          </small>
        </h2>
      </div>
    </div>
    <location-assignment
      v-for="location in round.locations"
      :tournament="tournament"
      :lineTime="lineTime"
      :round="round"
      :location="location"
      :key="round.id + '_' + lineTime.id + '_' + location.name"
      :size="totalSpots"/>
  </div>
</template>

<script>
import LocationAssignment from "./LocationAssignment.vue";

export default {
  name: "TimeAssignment",
  components: {LocationAssignment},
  props: ['tournament', 'round', 'lineTime'],
  data() {
    return {
      unassigned: this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors,
    }
  },
  computed: {
    memo() {
      return this.time.memo;
    },
    time() {
      let id = this.lineTime.id;
      let tournamentTime = this.tournament.line_times.find(t => t.id === id);
      return tournamentTime != null ? tournamentTime : this.lineTime;
    },
    user() {
      return this.$store.state.user.user
    },
    assignedCount() {
      return this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors_count;
    },
    totalSpots() {
      let count = 0;
      this.round.locations.forEach(function (location) {
        count += Object.keys(location.bales).length;
      });
      return (count * this.tournament.bale_competitors)
    },
    remainingSpots() {
      return this.totalSpots - this.assignedCount;
    },
  }
}
</script>

<style scoped>

</style>
