<script setup>

import FormCheckbox from "./FormCheckbox.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";
import {BsAlert} from "../../../mixins/swal_mixins";
import axios from "axios";

const store = useTournamentStore();

function toggle() {
  BsAlert.fire({
    icon: "info",
    title: `Select the option to toggle`,
    input: "select",
    inputOptions: store.selectedDefaults,
  }).then((data) => {
    if (data.isConfirmed) {
      let item = store.selectedDefaults[data.value];
      store.toggleOptional(item)
    }
  });
}
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Personal Information</div>
    <div class="flex textStyle">
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="true" title="Archer name" :disabled="true"/>
        <FormCheckbox @click.prevent class="bottomMargin" :active="true" :disabled="true" title="Email"/>
        <FormCheckbox class="bottomMargin" :active="store.gender" title="Gender"
                      :sub-title="store.optional.includes('Gender') ? 'optional' : null"
                      @click="store.gender = !store.gender"/>
        <FormCheckbox :active="store.phone" title="Phone number"
                      :sub-title="store.optional.includes('Phone number') ? 'optional' : null"
                      @click="store.phone = !store.phone"/>
      </div>
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="store.regAddress" title="Street address"
                      :sub-title="store.optional.includes('Street address') ? 'optional' : null"
                      @click="store.regAddress = !store.regAddress"/>
        <FormCheckbox class="bottomMargin" :active="store.regCity" title="City"
                      :sub-title="store.optional.includes('City') ? 'optional' : null"
                      @click="store.regCity = !store.regCity"/>
        <FormCheckbox class="bottomMargin" :active="store.regZip" title="Zip"
                      :sub-title="store.optional.includes('Zip') ? 'optional' : null"
                      @click="store.regZip = !store.regZip"/>
        <FormCheckbox :active="store.regState" title="State"
                      :sub-title="store.optional.includes('State') ? 'optional' : null"
                      @click="store.regState = !store.regState"/>
      </div>
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="store.country" title="Country"
                      :sub-title="store.optional.includes('Country') ? 'optional' : null"
                      @click="store.country = !store.country"/>
        <FormCheckbox :active="store.number" title="Membership number"
                      :sub-title="store.optional.includes('Membership number') ? 'optional' : null"
                      @click="store.number = !store.number"/>
      </div>
    </div>
    <div class="flex justify-end">
      <div @click="toggle()" class="toggle">toggle optional</div>
    </div>
  </div>
</template>

<style scoped>
.bottomMargin {
  margin-bottom: 16px;
}

.textStyle {
  @apply font-medium text-black;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
}

.toggle {
  @apply ml-3 text-black font-medium cursor-pointer;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 16px;
}
</style>