<template>
  <div>
    <div v-if="extras.length" class="mb-2 card card-blue-700">
      <div class="card-header">Disabled Product Images</div>
      <div class="card-body">
        <div v-for="(extra, index) in extras">
          <div style="width:210px">
            <div class="mb-1 flex justify-between">
              <button class="btn-sm btn-green-500" @click="handleExtra('enable',extra, index)">enable
              </button>
              <button class="btn-sm btn-red-500" @click="handleExtra('remove',extra, index)">remove
              </button>
              <button class="btn-sm btn-gray-600" @click="handleExtra('delete',extra, index)">delete
                product
              </button>
            </div>
            <div class="mx-auto"
                 :style="'background-image: url(' + extra['imageUrl'] + ');width:200px;height:200px;background-size:cover;'">
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div v-if="forms.length" class="mb-2 card card-blue-700">
      <div class="card-header">Disabled Regform Images</div>
      <div class="card-body">
        <div v-for="(form, index) in forms">
          <div style="width:224px">
            <div class="mb-1 flex justify-between">
              <button class="btn-sm btn-green-500" @click="handleForm('enable',form, index)">enable
              </button>
              <button class="btn-sm btn-red-500" @click="handleForm('remove',form, index)">remove
              </button>
              <button class="btn-sm btn-gray-600" @click="handleForm('delete',form, index)">delete
                competitor
              </button>
            </div>
            <div class="mx-auto"
                 :style="'background-image: url(' + form['name'] + ');width:200px;height:200px;background-size:cover;'">
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div v-if="scores.length" class="mb-2 card card-blue-700">
      <div class="card-header">Disabled Score Images</div>
      <div class="card-body flex">
        <div v-for="(score, index) in scores">
          <div class="mr-2 mb-2">
            <div class="mb-1 flex justify-between">
              <button class="btn-sm btn-green-500" @click="handleScore('enable',score, index)">enable
              </button>
              <button class="btn-sm btn-red-500" @click="handleScore('remove',score, index)">remove
              </button>
            </div>
            <div class="mx-auto"
                 :style="'background-image: url(' + score['url'] + ');width:200px;height:200px;background-size:cover;'">
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "DisabledImages",
  props: ['inc_extras', 'inc_form', 'inc_score'],
  data() {
    return {
      extras: this.inc_extras,
      forms: this.inc_form,
      scores: this.inc_score,
    }
  },
  methods: {
    handleExtra(task, extra, index) {
      BsAlert.fire({
        text: `Are you sure you want to ${task} this product${task !== 'delete' ? ' image' : ''}?`,
        icon: 'question',
        animation: false
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post('/godmode/handle_extra', {task: task, extra: extra}).then(({data}) => {
            if (data['success']) {
              this.extras.splice(index, 1);
            }
          })
        }
      })
    },
    handleForm(task, form, index) {
      BsAlert.fire({
        text: `Are you sure you want to ${task} this competitor${task !== 'delete' ? ' image' : ''}?`,
        icon: 'question',
        animation: false
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post('/godmode/handle_form', {task: task, form: form}).then(({data}) => {
            if (data['success']) {
              this.forms.splice(index, 1);
            }
          })
        }
      })
    },
    handleScore(task, score, index) {
      BsAlert.fire({
        text: `Are you sure you want to ${task} this score${task !== 'delete' ? ' image' : ''}?`,
        icon: 'question',
        animation: false
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$axios.post('/godmode/handle_score', {task: task, score: score}).then(({data}) => {
            if (data['success']) {
              this.scores.splice(index, 1);
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
