<template>
  <div class="p-3">
    <div v-if="authorized" class="card card-blue-700 mb-8">
      <div class="card-header">
        Update Version
      </div>
      <div class="card-body">
        <div class="mx-2">
          <div class="flex my-2">
            <input class="bs-form-box flex flex-grow mr-2" type="text" v-model="note">
            <button @click="addNote()" class="btn btn-blue-600">Add Note</button>
          </div>
          
          <ul class="list-disc pl-6">
            <li v-for="note in list">{{ note }}</li>
          </ul>
        </div>
        <div v-if="list.length" class="pt-6 flex justify-between">
          <button @click="updateVersion(0)" class="btn btn-red-600">Update Breaking Change</button>
          <button @click="updateVersion(1)" class="btn btn-green-600">Update New Feature</button>
          <button @click="updateVersion(2)" class="btn btn-blue-600">Update Bug Fixes</button>
        </div>
      </div>
    </div>
    <div class="overflow-y-scroll">
      <div class="pb-4" v-for="version in versionCopy">
        <span class="text-2xl">{{ version.version }}</span>
        <span class="text-xl pl-2">{{ date(version.created_at) }}</span>
        <hr>
        <ul class="list-disc pl-6">
          <li class="text-lg" v-for="note in notes(version)">{{ note }}</li>
        </ul>
      </div>
    </div>
  
  
  </div>
</template>

<script>

import moment from "moment";
import {BsAlert} from "../mixins/swal_mixins";

export default {
  name: "BuildNotes",
  props: ['user', 'versions'],
  data() {
    return {
      note: null,
      list: [],
      versionCopy: this.versions,
    }
  },
  methods: {
    updateVersion(type) {
      BsAlert.fire({
        titleText: 'Update the version?',
        html: this.html(type),
        icon: 'warning',
        showCloseButton: true,
        cancelButtonText: "Yes",
        confirmButtonText: 'Yes, and email god users',
      }).then(value => {
        if (value.value) {
          this.handleUpdate(type, true);
        } else if (value.isDismissed) {
          this.handleUpdate(type, false);
        }
      })
    },
    html(type) {
      let typeString = type === 0 ? 'Breaking Change' : (type === 1 ? 'New Feature' : 'Bug Fixes');
      let htmlString = `<div class="pb-2">Type: ${typeString}</div> <div>Build Notes:</div>`;
      this.list.forEach((item) => {
        htmlString += `<div>${item}</div>`;
      })
      return htmlString;
    },
    handleUpdate(type, email) {
      this.$axios.post('/update_version', {notes: this.list, type: type, email: email})
          .then(({data}) => {
            if (data['success']) {
              this.versionCopy.unshift(data['version']);
              this.note = null;
              this.list = [];
              BsAlert.fire({
                titleText: 'Successfully updated the version',
                icon: 'success',
                showCancelButton: false,
              })
            } else {
              BsAlert.fire({
                titleText: 'Version update failed',
                icon: 'error',
                showCancelButton: false,
              })
            }
          })
    },
    addNote() {
      if (this.note != null) {
        this.list.push(this.note);
        this.note = null;
      }
    },
    date(date) {
      let dateTime = new Date(date);
      return moment(dateTime).format('D MMM YYYY h:mm A')
    },
    notes(version) {
      let noteString = version.notes;
      return JSON.parse(noteString);
    }
  },
  computed: {
    authorized() {
      if (this.user) {
        let god = this.user.roles.find((f) => f.name === 'god');
        if (god) return true;
      }
      return false;
    }
  }
}
</script>

<style scoped>

</style>