<template>
  <div @click="selectClub">
    <slot v-if="customButton"></slot>
    <span v-else class="float-right"
          :class="(size ? size : 'btn-sm')+ ' btn-outline-' + color + ((hidePlus || hideText) ? ' thick' : '')">
      <i v-if="!hidePlus" class="fal fa-plus"></i> <span v-if="!hideText">Create Tournament</span>
    </span>
  </div>

</template>

<script>
import {BsAlert, Toast} from "../../mixins/swal_mixins";

export default {
  name: "NewTournamentButton",
  props: {
    user: Object,
    color: String,
    size: String,
    hidePlus: Boolean,
    hideText: Boolean,
    customButton: false,
  },
  methods: {
    needManage() {
      BsAlert.fire({
        title: 'Tournaments are created by Clubs.',
        icon: 'info',
        showCancelButton: false,
        html: '<div class="text-left mx-5">There are two ways that you can become a Club Director:' +
          '<div class="mt-2 ">' +
          '<div class="mb-2">1. Find an <a href="/clubs" class="text-blue-600 cursor-pointer">Existing Club </a>' +
          'and ask the Club Manager to make you a Director.</div>' +
          '<div>2. If you are the Club Manager and the Club has not yet been created on Bowscore, click' +
          '<a href="/clubs/create" class="text-blue-600 cursor-pointer"> here </a> ' +
          'to create a new Club.</div></div></div>'
      });
    },
    selectClub() {
      if (!this.user) {
        BsAlert.fire({
          text: "You must be logged in to create a tournament.",
          confirmButtonText: 'Log In',
        }).then((result) => {
          if (result.value) {
            window.location.href = '/login';
          }
        });
      } else {
        if (this.user.managed_clubs) {
          if (this.user.managed_clubs.length === 1) {
            let club = this.user.managed_clubs[0];
            window.location.href = '/clubs/' + club.slug + '/tournaments/create'
          } else if (this.user.managed_clubs.length > 1) {
            let text = '';
            let clubs = this.user.managed_clubs.filter(function (club) {
              return true;
            });
            if (clubs.length) {
              clubs.forEach(function (club) {
                text += `<a href="/clubs/${club.slug}/tournaments/create" class="block mb-2 text-blue-600 cursor-pointer">${club.name}</a>`;
              });
              BsAlert.fire({
                title: 'Which of your Clubs would like to host this tournament?',
                html: text
              })
            } else {
              BsAlert.fire({
                title: 'Your Club must be accepted before creating a tournament.',
                showCancelButton: false,
              })
            }
            
          } else {
            this.needManage();
          }
        } else {
          this.needManage();
        }
      }
    }
  }
  
}
</script>

<style scoped>
.thick {
  border-radius: 10px;
  border-width: 2px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Helvetica Neue";
}

</style>
