<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import {computed, ref} from "vue";
import CloneTournament from "../../partials/CloneTournament.vue";
import ClipboardCopy from "../../partials/ClipboardCopy.vue";

const store = useTournamentStore();

const toggled = ref(false);
const view = () => {
  window.location = `/tournaments/${store.tournamentSlug}/overview`;
}

const linkUrl = computed(() => `${location.origin}/tournaments/${store.tournamentSlug}#/register`);


</script>

<template>
  <div class="body" :class="store.tournamentSlug ? 'topMarginWithButtons' : 'topMargin'">
    <div v-if="store.tournamentSlug && store.isComplete" class="buttons">
      <input type="hidden" :value="linkUrl" id="urlHidden">
      <clone-tournament :use-slot="true" :tournament="store.defaultTournament">
        <button class="button">Duplicate</button>
      </clone-tournament>
      <button @click.prevent="view" class="button">View</button>
      <ClipboardCopy message="Link copied to clipboard" :copy-value="linkUrl">
        <button class="button">Share</button>
      </ClipboardCopy>
    </div>
    <div v-else class="buttons"></div>
    <div class="dropdown flex justify-end mr-5">
      <i class="fa-regular fa-xl fa-ellipsis" @click="toggled = !toggled"></i>
      <div v-if="toggled" @click="toggled = false" class="menuSelect bs-card">
        <div class="flex flex-col">
          <clone-tournament :use-slot="true" :tournament="store.defaultTournament">
            <button class="dropItem">Duplicate</button>
          </clone-tournament>
          <button class="dropItem" @click.prevent="view">View</button>
          <ClipboardCopy message="Link copied to clipboard" :copy-value="linkUrl">
            <button class="dropItem">Share</button>
          </ClipboardCopy>
        </div>
      </div>
    </div>
    <div class="bs-card">
      <div class="bs-section-header">Status</div>
      <div class="select-wrapper">
        <select v-model="store.tournamentStatus" name="status" id="status" class="bs-dropdown"
                @change="store.touched()">
          <option value="draft" :selected="store.tournamentStatus === 'draft'">Draft</option>
          <option :disabled="!store.isComplete" value="public" :selected="store.tournamentStatus === 'public'">
            Public
          </option>
          <option :disabled="!store.isComplete" value="private" :selected="store.tournamentStatus === 'private'">
            Private
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menuSelect {
  @apply font-semibold text-stone-400 px-5 pt-4 pb-0;
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  top: 5px;
  right: -8px;
  z-index: 100;
  width: 168px;
  
}


.topMargin {
  margin-top: 78px;
}

.topMarginWithButtons {
  margin-top: 46px;
}

.buttons {
  @apply flex justify-end;
  margin-right: 6px;
}

.button {
  @apply font-semibold text-black;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: .018rem;
  background: #E5E5E5 0 0 no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  margin-left: 8px;
  padding: 8px 16px 7px 16px;
}

.body {
  min-width: 272px;
  width: 100%;
  max-width: 272px;
  margin-right: 6px;
}


@media (max-width: 1170px) {
  .body {
    min-width: 220px;
  }
}

@media (max-width: 900px) {
  .body {
    min-width: 170px;
  }
}

@media (max-width: 768px) {
  .body {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
  
  .topMargin {
    margin-top: 0;
  }
  
  .topMarginWithButtons {
    margin-top: -58px;
  }
}

.dropdown {
  @apply hidden;
}

@media (min-width: 431px) {
  .dropdown {
    @apply hidden !important;
  }
}

@media (max-width: 430px) {
  .buttons {
    @apply hidden;
  }
  
  .dropdown {
    @apply relative flex;
    margin-bottom: 20px;
    margin-top: -33px;
  }
  
  .dropItem {
    @apply mb-4;
    text-align: start;
  }
  
  .topMargin {
    margin-top: 0px;
  }
  
  .topMarginWithButtons {
    margin-top: -30px;
  }
}
</style>