<script setup>
import CompetitorScore from "./CompetitorScore.vue";
import {useResultsStore} from "../../../stores/ResultsStore";
import {computed} from "vue";

const props = defineProps(['tournamentIn', 'filteredCompetitors', 'competitors', 'placeList'])
const store = useResultsStore();

const columnCount = computed(() => {
  let base = 2;
  let filterCount = Object.keys(store.filterList).length
  let breakerCount = store.tieBreakers.length;
  let handicap = store.useHandicap ? 1 : 0;
  let roundsCount = store.rounds.length > 1 ? store.rounds.length : 0;
  return base + filterCount + breakerCount + handicap + roundsCount;
});

</script>
<template>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-header text-left">Name</th>
        <th class="table-header" v-for="option in Object.keys(store.filterList)">
          {{ `${option[0].toUpperCase()}${option.slice(1)}` }}
        </th>
        <th class="table-header ">Total Score</th>
        <th v-for="breaker in store.tieBreakers" class="table-header">Total {{ breaker }}'s</th>
        <th v-if="store.useHandicap" class="table-header">Handicap</th>
        <th class="table-header " v-if="store.rounds.length > 1" v-for="round in store.rounds">{{ round.name }}</th>
      </tr>
      </thead>
      <tbody>
      <competitor-score
        v-for="competitor in filteredCompetitors"
        :competitor="competitors[competitor]"
        :scores="tournamentIn.scoreList[competitor]"
        :place="placeList['overall'].indexOf(competitor) + 1"
        :key="'overall_' + competitor"
        :filter_list="Object.keys(store.filterList)"
        :breakdown="true"
        :images="tournamentIn.scoreImages[competitor]"
        :duplicates="store.competitorDuplicates(competitor)"/>
      <tr v-if="!filteredCompetitors.length" class="text-center">
        <td :colspan="columnCount" class="table-empty "> No matching results</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>