<template>
  <div v-if="user" class="card card-blue-700">
    <div class="card-header">
      My Registration Preferences
    </div>
    <div class="card-body">
      <Form>
        <div class="flex flex-col mt-4 px-1">
          <label for="name">First Name</label>
          <Field type="text" name="first_name" class="bs-form-box"
                 @change="updates.general.push('first_name')"
                 rules="required|min:3|clean" v-model="user.archer.first_name"/>
          <ErrorMessage name="first_name" class="errorText"/>
        </div>
        <div class="flex flex-col mt-4 px-1">
          <label for="name">Last Name</label>
          <Field type="text" name="last_name" class="bs-form-box"
                 @change="updates.general.push('last_name')"
                 rules="required|min:3|clean" v-model="user.archer.last_name"/>
          <ErrorMessage name="last_name" class="errorText"/>
        </div>
        <div class="flex flex-col mt-4 px-1">
          <label for="email">Email</label>
          <Field type="text" name="email" class="bs-form-box"
                 @change="updates.general.push('email')"
                 rules="required|min:5|email" v-model="user.archer.email"/>
          <ErrorMessage name="email" class="errorText"/>
        </div>
      </Form>
      
      <div class="mt-4 px-1">
        <div class="inline-block">
          <div>Sport Preferences</div>
          <select v-model="sport" class="bs-form-box w-full ">
            <option v-for="s of Object.values(sportslist)" :value="s">{{ s.abbrev }}</option>
          </select>
        </div>
        
        
        <div class="bs-card mx-0">
          <div v-for="(options, name) in sport.options">
            <div class="flex flex-col mt-4">
              <label :for="sport.abbrev + ' ' + name">{{ name }}</label>
              <select v-if="sport.options[name].type === 'radio'"
                      v-model="archer.registration_defaults[sport.id][name]"
                      @change="updateField(sport.id,name)"
                      class="bs-form-box w-full">
                <option v-for="option in options.options" :value="option">{{ option }}</option>
              </select>
              <div v-else-if="sport.options[name].type === 'date'">
                <div class="bs-form-box w-full flex justify-end p-0">
                  <flat-pickr
                    class="w-full px-3 focus:outline-none"
                    :config="dateConfig"
                    v-model="archer.registration_defaults[sport.id][name]"
                    :name="sport.abbrev + ' ' + name"
                    @on-change="updateField(sport.id,name)"
                    :ref="`picker_${sport.abbrev + ' ' + name}`"/>
                  <i @click="openPicker(sport.abbrev + ' ' + name)" class="far fa-calendar text-xl p-2"></i>
                </div>
              </div>
              <input v-else
                     class="bs-form-box w-full"
                     @change="updateField(sport.id,name)"
                     v-model="archer.registration_defaults[sport.id][name]">
            </div>
          </div>
        </div>
      </div>
      
      <div class="flex justify-end m-1">
        <transition name="fade" :duration="{ enter: 1800, leave: 800 }">
          <span v-show="updatedMessage" class="float-right text-xl text-blue-600 mr-2">updated</span>
        </transition>
        <button @click.prevent="update" class="btn btn-blue-600">
          Update
          <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
        </button>
      </div>
    
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import {Form, Field, ErrorMessage} from "vee-validate";

export default {
  name: "RegistrationPreferences",
  props: ['inc_user', 'sportslist'],
  components: {ErrorMessage, Form, Field, flatPickr},
  data() {
    return {
      sport: this.sportslist['Scholastic 3D Archery'],
      updates: {},
      user: this.inc_user,
      loading: false,
      updatedMessage: false,
      dateConfig: {
        dateFormat: 'd M Y',
        allowInput: true,
        static: false,
      },
      
    }
  },
  watch: {
    editing(field) {
      if (field !== '') {
        this.$nextTick(() => this.$refs[field][0].focus())
      }
    }
  },
  computed: {
    archer() {
      return this.user.archer
    }
  },
  methods: {
    openPicker(detail) {
      let picker = this.$refs['picker_' + detail][0].fp;
      picker.open();
    },
    updateField(sport, name) {
      this.updates.sports[sport].push(name)
    },
    update() {
      let vm = this;
      vm.loading = true;
      this.$axios
      .post('/archers/update/', {
        archer: vm.archer,
        updates: vm.updates,
      })
      .then(({data}) => {
        vm.updatedMessage = true;
        setTimeout(vm.hideUpdated, 1500);
        vm.loading = false;
      })
      .catch(({response}) => {
        console.log(response);
        vm.loading = false;
      });
    },
    hideUpdated() {
      this.updatedMessage = false;
    },
    setUpdates() {
      let list = {'general': [], 'sports': {}};
      Object.values(this.sportslist).forEach(sport => {
        list.sports[sport.id] = [];
      });
      this.updates = list;
    }
  },
  mounted() {
    this.setUpdates();
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
