import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';

import SingleScore from "./components/scoring/SingleScore.vue";
import PostalDashboard from "./components/tournaments/postal/PostalDashboard.vue";
import LadderSetup from "./components/tournaments/manage/LadderSetup.vue";
import RegistrationRestrictions from "./components/tournaments/manage/RegistrationRestrictions.vue";
import CompetitorCreate from "./components/competitors/form/CompetitorCreate.vue";
import CompetitorPage from "./components/competitors/CompetitorPage.vue";
import CompetitorEdit from "./components/competitors/CompetitorEdit.vue";
import CompetitorAssignment from "./components/assignments/CompetitorAssignment.vue";
import LadderAssignment from "./components/assignments/LadderAssignment.vue";
import LadderBoard from "./components/tournaments/results/LadderBoard.vue";
import ScoringComponent from "./components/scoring/ScoringComponent.vue";
import ScoringLadder from "./components/scoring/ScoringLadder.vue";
import ScoreReshoots from "./components/scoring/ScoreReshoots.vue";
import EndScoring from "./components/scoring/EndScoring.vue";
import TournamentResults from "./components/tournaments/results/TournamentResults.vue";
import TournamentTeamResults from "./components/tournaments/results/TournamentTeamResults.vue";
import ScoringProgress from "./components/scoring/ScoringProgress.vue";
import ScoreLog from "./components/tournaments/ScoreLog.vue";
import PracticeEdit from "./components/practice/PracticeEdit.vue";
import TournamentEdit from "./components/tournaments/TournamentEdit.vue";
import TournamentSettings from "./components/tournaments/manage/TournamentSettings.vue";
import ManageLeague from "./components/tournaments/manage/ManageLeague.vue";
import ManageCompetitors from "./components/tournaments/manage/competitors/ManageCompetitors.vue";
import ManageLadderBoard from "./components/tournaments/manage/ManageLadderBoard.vue";
import CompetitorImport from "./components/competitors/CompetitorImport.vue";
import ManageDevices from "./components/tournaments/manage/ManageDevices.vue";
import AutoSettings from "./components/tournaments/manage/AutoSettings.vue";
import TournamentNotifications from "./components/tournaments/manage/TournamentNotifications.vue";
import TournamentMessaging from "./components/tournaments/manage/TournamentMessaging.vue";
import CustomTargets from "./components/tournaments/manage/CustomTargets.vue";
import BaleSettings from "./components/tournaments/manage/BaleSettings.vue";
import TournamentOverview from "./components/tournaments/TournamentOverview.vue";

let routes = [

  {
    // will match everything
    path: '/:pathMatch(.*)*', name: 'not-found',
    beforeEnter: (to, from, next) => {
      console.log('invalid extension:' + to.path);
      next(false);
    },
  },
  {
    path: '/',
    name: 'home',
    props: true,
    component: TournamentOverview
  },
  {
    path: '/register',
    name: 'register',
    component: CompetitorCreate,
  },
  {
    path: '/competitors/:uuid',
    props: true,
    name: 'competitor',
    component: CompetitorPage
  },
  {
    path: '/competitors/:uuid/edit',
    component: CompetitorEdit
  },
  {
    path: '/assignment',
    component: CompetitorAssignment
  },
  {
    path: '/ladder_assignment',
    component: LadderAssignment
  },
  {
    path: '/ladder_board',
    component: LadderBoard
  },
  {
    key: 'scoreKey',
    path: '/score',
    name: 'score',
    component: ScoringComponent
  },
  {
    key: 'scoreLadderKey',
    name: 'score_ladder',
    path: '/score_ladder',
    component: ScoringLadder
  },
  {
    path: '/reshoots',
    component: ScoreReshoots
  },
  {
    path: '/complete',
    component: EndScoring
  },
  {
    path: '/results',
    component: TournamentResults
  },
  {
    path: '/results/:teamType',
    component: TournamentTeamResults
  },
  {
    path: '/progress',
    component: ScoringProgress
  },
  {
    path: '/logs',
    component: ScoreLog
  },
  {
    path: '/practice/',
    component: PracticeEdit
  },
  {
    path: '/practice/assignments',
    component: CompetitorAssignment
  },
  {
    path: '/practice/score',
    name: 'practiscore',
    component: ScoringComponent
  },
  {
    path: '/practice/results',
    component: TournamentResults
  },
  {
    path: '/practice/progress',
    component: ScoringProgress
  },
  {
    path: '/practice/reshoots',
    component: ScoreReshoots
  },
  {
    path: '/manage/edit',
    name: 'editTournament',
    component: TournamentEdit
  },
  {
    path: '/manage/settings',
    component: TournamentSettings
  },
  {
    path: '/manage/league',
    component: ManageLeague
  },
  {
    path: '/manage/competitors',
    component: ManageCompetitors
  },
  {
    path: '/manage/ladder_board',
    component: ManageLadderBoard
  },
  {
    path: '/manage/import',
    component: CompetitorImport
  },
  {
    path: '/manage/devices',
    component: ManageDevices
  },
  {
    path: '/manage/assign',
    component: AutoSettings
  },
  {
    path: '/manage/notifications',
    component: TournamentNotifications
  },
  {
    path: '/manage/logs',
    component: ScoreLog
  },
  {
    path: '/manage/messaging',
    component: TournamentMessaging
  },
  {
    path: '/manage/targets',
    component: CustomTargets
  },
  {
    path: '/manage/bales',
    component: BaleSettings
  },
  {
    path: '/manage/restrictions',
    component: RegistrationRestrictions
  },
  {
    path: '/manage/ladder',
    component: LadderSetup
  },
  {
    path: '/manage/postal',
    component: PostalDashboard
  },
  {
    path: '/user_scoring/:uuid',
    component: SingleScore
  },
];

const router = createRouter({
  routes,
  linkActiveClass: 'active',
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
});


export default router;

