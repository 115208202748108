<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";

const store = useCompetitorStore();
</script>

<template>
    <div class="bs-card">
      <div class="bs-card-header">{{ store.waiverObject?.name }}</div>
      <p>{{ store.waiverObject?.placeholder }}</p>
      <hr class="my-2">
      <div v-if="store.registrationType === 'group'" class="center">
        I acknowledge that I have read and understand the waiver. I am registering on behalf of {{ store.archerNames }},
        they have agreed to be bound by its terms.
      </div>
      <div v-else class="center">
        By clicking accept, I acknowledge that I have read and understand the waiver. I agree to be bound by its
        terms.
      </div>
      <div class=" flex justify-between mt-4">
        <div>
          <button @click.prevent="store.acceptWaiver(false)" class="bs-input-box">Decline</button>
        </div>
        <div>
          <button @click.prevent="store.acceptWaiver(true)" class="bs-input-box bg-black text-white">Accept</button>
        </div>
      </div>
    </div>
</template>

<style scoped>
.title {
  @apply ml-2;
  margin-bottom: 22px;
}

.titleLabel {
  @apply m-2;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: .35px;
}
</style>