<script setup>
import {computed, ref} from 'vue';

import {onMounted} from "vue";
import axios from "axios";
import {BsAlert} from "../../../../mixins/swal_mixins";
import ClipboardCopy from "../../../partials/ClipboardCopy.vue";

const props = defineProps(['tournament'])

onMounted(() => {
  axios
  .get('/tournaments/' + props.tournament.slug + '/get_master_code')
  .then(({data}) => {
    if (data.success) {
      code.value = data.code;
      // this.ladderCode = data.ladder;
    }
  })
})

const code = ref(null);

const loading = ref(false);

const generateCode = (() => {
  loading.value = true;
  axios
  .post('/tournaments/' + props.tournament.slug + '/generate_master_code')
  .then(({data}) => {
    debugger;
    code.value = data.code;
    loading.value = false
  })
})

const generateClicked = (() => {
  if (code.value != null) {
    BsAlert.fire({
      titleText: 'Really Generate New Code?',
      icon: 'warning',
      html: 'This will invalidate the existing code.',
    }).then(({dismiss}) => {
      if (!dismiss) {
        generateCode();
      }
    })
  } else {
    generateCode();
  }
});
</script>

<template>
  <div class="bs-card">
    <div class="bs-section-header">Scoring Code</div>
    <div v-if="code?.code != null" class="inline-block cursor-pointer">
      <ClipboardCopy message="Scoring code copied to clipboard" :copy-value="code.code.toUpperCase()">
        <div class="bs-input-box flex justify-between px-4">
          <div class="code">{{ code.code.toUpperCase() }}</div>
          <div class="flex flex-col justify-center text-stone-400">
            <i class="fa-regular fa-lg fa-link"></i>
          </div>
        </div>
      </ClipboardCopy>
    </div>
    <div class="helpText">Enter this code in the Bowscore app to score this tournament</div>
    <div class="refreshLink" @click="generateClicked">
      <i class="fa-regular fa-lg fa-arrows-rotate mr-2"></i> Generate new code
    </div>
  </div>
</template>

<style scoped>
.refreshLink {
  @apply text-black mt-4 font-bold cursor-pointer;
  display: inline-block;
  line-height: 17px;
  letter-spacing: .018rem;
}

.helpText {
  @apply mt-2 text-stone-400;
}

.code {
  @apply font-bold mr-4;
  color: #FB5202;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: .04rem;
}
</style>