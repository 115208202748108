<script setup>


import {BsAlert, Toast} from "../../../../mixins/swal_mixins";
import {useManageStore} from "../../../../stores/manageStore";
import axios from "axios";

const manage = useManageStore();

function emailMessage(subject) {
  BsAlert.fire({
    title: 'Compose a Message',
    input: 'textarea',
    footer: 'All emails will include links to their personal competitor management page and the tournament home page.',
    confirmButtonText: 'Send Email',
    showLoaderOnConfirm: true,
    preConfirm: (message) => {
      return axios.post('/email', {
        'to': manage.competitorList,
        'tournament': manage.tournament,
        'subject': subject,
        'message': message,
        'type': 'generic',
      }).then(({data}) => {
        if (data.success) {
          return BsAlert.fire({
            title: 'Messages have been sent!', showCancelButton: false, icon: "success"
          });
        } else if (data.error) {
          return BsAlert.fire({title: data.error.message, showCancelButton: false, icon: "error"});
        }
      }).catch(function (error) {
        manage.axiosError(error);
      });
    },
    allowOutsideClick: () => !BsAlert.isLoading()
  })
}

function email() {
  BsAlert.fire({
    title: 'Emailing Selected Archers',
    text: 'Please enter the email subject',
    footer: 'This will be sent only to shown competitors. Make sure that showing is set to "All" if you intend to email entire filtered selection.',
    input: 'text',
    confirmButtonText: 'Next'
  }).then((result) => {
    if (result.value) emailMessage(result.value);
  })
}

function download() {
  BsAlert.fire({
    title: 'Select Download Details',
    showCloseButton: true,
    cancelButtonText: 'Overview and Rounds',
    confirmButtonText: 'Displayed Selection',
    animation: false,
    icon: 'question',
  }).then(({value, dismiss}) => {
    if (value) {
      downloadOverview();
    } else if (dismiss && dismiss === 'cancel') {
      downloadDisplayed();
    }
  })
}

function downloadDisplayed() {
  let blob = new Blob([manage.comboTable()], {type: 'text/csv'});
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${manage.tournament.slug}_competitors_overview.csv`;
  link.click();
}

function downloadOverview() {
  let blob = new Blob([manage.tableData()], {type: 'text/csv'});
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  let name = manage.tournament.slug;
  name += manage.round === null ? '_competitors.csv' : `_round_${manage.round.id}_competitors.csv`;
  link.download = name;
  link.click();
}

function copy() {
  BsAlert.fire({
    title: 'Select Copy Details',
    showCloseButton: true,
    cancelButtonText: 'Overview and Rounds',
    confirmButtonText: 'Displayed Selection',
    animation: false,
    icon: 'question',
  }).then(({value, dismiss}) => {
    let el = document.createElement('textarea');
    if (value) {
      el.value = this.tableData;
    } else if (dismiss && dismiss === 'cancel') {
      el.value = this.comboTable;
    }
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    navigator.clipboard.writeText(el.value);
    // Remove temporary element
    document.body.removeChild(el);
    Toast.fire({title: 'Table copied to clipboard', icon: "success"})
  });
}
</script>

<template>
  <div class="flex justify-end">
    <a @click.prevent="email" href="" class="actionButton" title="Email table">
      Email <i class="far fa-envelope"></i>
    </a>
    <a @click.prevent="download" href="" class="actionButton" title="Download table">
      Download CSV <i class="far fa-download"></i>
    </a>
    <a @click.prevent="copy" href="" class="actionButton" title="Copy table">
      Copy <i class="far fa-paperclip"></i>
    </a>
    <a @click="$emit('handlePrint')" class="actionButton" title="Print table">
      Print <i class="far fa-print"></i>
    </a>
  </div>
</template>

<style scoped>
.actionButton {
  @apply text-gray-600 ml-3 cursor-pointer;
}
</style>