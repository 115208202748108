<script setup>
import {onMounted, ref} from "vue";
import axios from 'axios';
import CardSettings from "./CardSettings.vue";
import {useDashboardStore} from "../../../stores/DashboardStore";

let clubs = ref([]);

const loading = ref(true);
const store = useDashboardStore()

onMounted(() => {
  axios.get('/dashboard/clubs').then(({data}) => {
    if (data['success']) {
      clubs.value = data['list'];
    }
    loading.value = false;
  }).catch((e) => {
    loading.value = false;
    store.axiosError(e);
  })
})

</script>

<template>
  <div class="bs-shadow-card cardStyle">
    <card-settings name="clubs"/>
    <div class="flex justify-between">
      <div class="w-full">
        <div class="bs-card-header">Clubs you manage</div>
        <div v-for="club of clubs" class="clubRow sm:flex-row">
          <a :href="`/clubs/${club.slug}`" class="label">{{ club.name }}</a>
          <div class="text">{{ club.tournaments.length }} tournaments</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardStyle {
  @apply w-full relative mb-4;
  padding-top: 32px;
  padding-bottom: 38px;
}

.clubRow {
  @apply mb-4 flex flex-col;
  cursor: pointer;
}

.label {
  @apply font-bold text-black pr-1;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
  flex: 2;
}

.text {
  @apply font-medium text-stone-400 pr-1;
  flex: 4;
  font-size: 16px;
  line-height: 19px;
}

</style>