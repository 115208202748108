<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {ref, computed} from "vue";

const store = useCompetitorStore();
const show = ref(null);

const waitCount = computed(() => store.waitlist.length);
</script>

<template>
  <div @click="show = null" class="bs-card">
    <div class="bs-section-header">Tournament total</div>
    <div>
      <div v-for="archer of store.selectedArchers" class="flex mb-2">
        <div class="flex-2 flex flex-col justify-start">
          <div class="bs-input-box" :class="store.waitlisted(archer) ? 'text-stone-400' : 'text-black'">
            {{ archer.name }}
          </div>
        </div>
        <div class="flex-2 mx-4" :class="store.waitlisted(archer) ? 'text-stone-400' : 'text-black'">
          <div v-if="show !== archer.id" @click.stop="show = archer.id"
               class="bs-input-box pr-1 flex flex-col justify-center">
            <div class="flex justify-between">
              <div class="purchaseList">
                {{ [store.archerRegistrationLabel(archer), ...store.groupPurchases[archer.id]].join(', ') }}
              </div>
              <i class="fa fa-angle-down"></i>
            </div>
          </div>
          <div v-else class="bs-input-box pr-1">
            <div v-for="option in store.archerBreakdown(archer)" class="breakdownItem">{{ option }}</div>
          </div>
        </div>
        <div class="flex-1 flex flex-col justify-start">
          <div class="bs-input-box moneyBox">
            <div class="flex justify-between">
              <div class="flex flex-col justify-center">
                <i class="fa fa-lg fa-dollar-sign"></i>
              </div>
              <span :class="store.waitlisted(archer) ? 'text-stone-400' : 'text-black'">
                {{ store.archerTotal(archer) }}.00
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full">
        <div class="flex-4">
          <div class="flex flex-col justify-center">Total</div>
        </div>
        <div class="flex-1 bs-input-box moneyBox">
          <div class="flex justify-between">
            <div class="flex flex-col justify-center">
              <i class="fa fa-lg fa-dollar-sign"></i>
            </div>
            <span :class="waitCount === store.selectedArchers.length ? 'text-stone-400' : 'text-black'">
              {{ store.groupTotal }}.00
            </span>
          </div>
        </div>
      </div>
      <div class="text-stone-400 mt-4" v-if="waitCount">
        Payment is not collected for waitlisted competitors until they have been approved.
      </div>
    </div>
  </div>
</template>

<style scoped>
.breakdownItem {
  @apply mb-2 overflow-scroll whitespace-nowrap;
  min-width: 20px;
  max-width: 185px;
}

.flex-4 {
  @apply ml-24 text-stone-400 flex justify-end pr-8;
  flex: 4;
}


.bs-input-box {
  min-height: 36px;
}

.purchaseList {
  padding-right: 22px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 175px;
  text-overflow: ellipsis;
}

.moneyBox {
  @apply flex flex-col justify-center bg-stone-100 text-stone-400;
}
</style>