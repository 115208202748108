<template>
  <div v-if="tournament" class="flex justify-center my-2">
    <div>
      <h1 class="text-3xl">Additional Purchase Options</h1>
      <hr>
      <div class="flex flex-col mt-5">
        <div v-for="extra in tournament.payments.pricing_extras">
          <div v-if="extra.type === '+'">
            <input type="checkbox" v-model="pricing_extras" :value="extra" :disabled="disabled(extra)">
            <label class="ml-1">
              <span class="flex">
                {{ extra.name }} ( {{ extra.type }}${{ extra.amount }}.00 )
                <span v-if="disabled(extra)" class="ml-2 font-bold">PURCHASED</span>
                <image-modal v-if="extra.imageUrl !== undefined && extra.enabled" :image="extra.imageUrl"/>
              </span>
            </label>
          </div>
        </div>
        <div>
          Price: ${{ fullAmount }}.00
        </div>
        <div class="my-2 w-72 sm:w-96">
          <div v-if="useAuthNet">
            <div class="bs-form-box" id="authNetCard">
              <input class="w-1/3 focus:outline-none" maxlength="16" type="text" name="cardNumber"
                     id="cardNumber" placeholder="Card number"/>
              <div class="text-right float-right w-2/3">
                <input class="w-1/4 justify-end text-right focus:outline-none" type="text"
                       name="expMonth" id="expMonth" maxlength="2" placeholder="MM"/>{{ ' / ' }}
                <input class="w-1/4 justify-end focus:outline-none" maxlength="4" type="text"
                       name="expYear" id="expYear" placeholder="YYYY"/>
                <input class="w-1/4 justify-end text-right focus:outline-none" maxlength="4" type="text"
                       name="cardCode" id="cardCode" placeholder="CVV"/>
              </div>
            </div>
            <input type="hidden" name="authNetToken" id="authNetToken"/>
            <input type="hidden" name="authNetDescriptor" id="authNetDescriptor"/>
          </div>
          <div v-else>
            <div ref="card" class="bs-form-box flex flex-col justify-center"></div>
          </div>
        </div>
        <div class="flex justify-end">
          <div class="btn btn-outline-blue-600" @click.prevent="pay">
            Pay <i v-if="loading" class="fas fa-spin fa-spinner"></i>
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
</template>
<script>

import ModalComponent from "../partials/ModalComponent.vue";
import ImageModal from "../partials/ImageModal.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "PurchaseExtras",
  components: {ImageModal, ModalComponent},
  props: ['competitor', 'api_login', 'tournament'],
  data() {
    return {
      pricing_extras: [],
      stripe: null,
      elements: null,
      card: null,
      loading: false,
    }
  },
  computed: {
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    fullAmount() {
      let price = 0.0;
      this.pricing_extras.forEach(extra => {
        price += parseInt(extra.amount)
      });
      return price;
    },
  },
  methods: {
    disabled(extra) {
      if (this.competitor.pricing_extras === null) return false;
      let exists = this.competitor.pricing_extras.filter((f) => {
        if (extra.hasOwnProperty('id')) {
          return f.id === extra.id;
        }
        return f.name === extra.name;
      })
      return exists.length > 0;
    },
    async handleStripe() {
      if (!this.stripe) {
        return BsAlert.fire({
          title: 'Unknown payment form error, please refresh the page and try again.',
          showCancelButton: false,
        })
      }
      let vm = this;
      return this.stripe.createToken(this.card).then(({token, error}) => {
        if (error) throw new Error(error.message);
        axios.post('/purchase_extras/' + this.competitor.uuid,
          {stripeToken: token.id, extras: vm.pricing_extras})
        .then(({data}) => {
          if (data['success']) {
            BsAlert.fire({showCancelButton: false, icon: "success", title: 'Payment Accepted'}).then(() => {
              window.location.href = '/tournaments/' + this.tournament.slug + '#/competitors/' + this.competitor.uuid;
            });
          } else if (data['error']) {
            BsAlert.fire({title: data['error'].message, showCancelButton: false, icon: "error"});
          }
          this.loading = false;
        });
      }).catch(function (error) {
        throw new Error(error.message);
      });
    },
    
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        let html = '<div>'
        Object.values(response.messages.message).forEach((message) => {
          html += `<div>${message.text}</div>`;
        })
        html += '</div>'
        BsAlert.fire({
          title: 'Error Processing',
          html: html,
          icon: "error",
          showCancelButton: false,
        })
        this.loading = false;
      } else {
        document.getElementById("authNetCard").innerHTML = '';
        axios.post('/purchase_extras/' + this.competitor.uuid, {
          authNetToken: response.opaqueData.dataValue,
          authNetDescriptor: response.opaqueData.dataDescriptor,
          extras: this.pricing_extras,
        }).then(({data}) => {
          if (data['success']) {
            BsAlert.fire({title: 'Payment Accepted', showCancelButton: false, icon: "success"}).then(() => {
              window.location.href = '/tournaments/' + this.tournament.slug + '#/competitors/' + this.competitor.uuid;
            });
          } else if (data['error']) {
            BsAlert.fire({title: data['error'].message, showCancelButton: false, icon: "error"});
          }
          this.loading = false;
        });
      }
    },
    handleAuthNet() {
      let data = {
        authData: {clientKey: this.tournament.club.auth_net_key, apiLoginID: this.api_login},
        cardData: {
          cardNumber: document.getElementById("cardNumber").value,
          month: document.getElementById("expMonth").value,
          year: document.getElementById("expYear").value,
          cardCode: document.getElementById("cardCode").value,
        }
      }
      
      Accept.dispatchData(data, this.responseHandler);
    },
    pay() {
      this.loading = true;
      if (this.useAuthNet) {
        this.handleAuthNet();
      } else {
        this.handleStripe().catch((error) => {
          this.loading = false;
          BsAlert.fire({title: error.message, icon: "error", showCancelButton: false});
        })
      }
      
    }
  },
  mounted() {
    if (!this.useAuthNet) {
      if (typeof (Stripe) == 'undefined') return;
      this.stripe = Stripe(this.tournament.club.stripe.stripe_publishable_key);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount(this.$refs['card']);
    }
  },
}
</script>

<style scoped>

</style>
