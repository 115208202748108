<template>
  <div>
    <div v-if="!selectedCompetitor">
      <button v-if="canExport" id="ladderExportButton" class="btn btn-outline-blue-600 m-1" @click="exportClicked">
        Export Ladder CSV
      </button>
      <ul v-if="rounds.length > 1" class="tabs mb-3 mt-4" role="tablist">
        <li :class="'selectedTab'" :id="'overall'">
          <a @click.prevent="selectTab('overall')" href="">Overall</a>
        </li>
        <li v-for="round in rounds" :id="'round_' + round.id">
          <a @click.prevent="selectTab(round)" href="">{{ round.name }}</a>
        </li>
      </ul>
      <div id="printFullBreakdown">
        <h2 v-if="printing" class="text-xl">Tournament: {{ tournamentName }}</h2>
        <h2 class="text-2xl">{{ selectedRound ? selectedRound.name : 'Overall' }} Breakdown</h2>
        <div v-if="breakdownArray && Object.keys(breakdownArray).length">
          <div v-for="a in detailedArray(0)">
            <div v-for="b in detailedArray(1)">
              <div v-for="c in detailedArray(2)">
                <div v-for="d in detailedArray(3)">
                  <div v-for="e in detailedArray(4)">
                    <div v-if="breakdownCompetitors(a,b,c,d,e).length">
                      <h2 class="my-2 text-xl">{{ breakdownLabel(a, b, c, d, e, true) }}</h2>
                      <breakdown-results-table
                        :competitors="competitors"
                        :tournament-in="tournamentIn"
                        :breakdown-competitors="breakdownCompetitors(a,b,c,d,e)"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-2xl bg-yellow-200 border border-yellow-900 text-yellow-900 rounded p-4">
          Select your breakdown filters from the list at the top of this page.
        </div>
      </div>
    </div>
    <div v-else id="printCompetitor" class="table-responsive">
      <div v-if="printing" style="display:flex; flex-direction:column;">
        <span>Competitor: {{ competitors[selectedCompetitor]?.name ?? '-' }}</span>
        <span>Tournament: {{ tournamentName }}</span>
      </div>
      <individual-results-table
        :tournament-in="tournamentIn"
        :place-list="placeList"/>
    </div>
  </div>
</template>
<script>
import CompetitorScore from "./CompetitorScore.vue";
import {mapState, mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import BreakdownResultsTable from "./BreakdownResultsTable.vue";
import IndividualResultsTable from "./IndividualResultsTable.vue";
import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "BreakdownResults",
  components: {IndividualResultsTable, BreakdownResultsTable, CompetitorScore},
  props: ['tournamentIn', 'competitors', 'placeList', 'user'],
  computed: {
    ...mapState(useResultsStore, [
      'printing',
      'breakdownArray',
      'rounds',
      'tournamentName',
      'tieBreakers',
      'regform',
    ]),
    ...mapWritableState(useResultsStore, [
      'overall',
      'selectedRound',
      'selectedClass',
      'selectedDivision',
      'selectedCompetitor',
      'selectedTarget',
      'selectedGender',
      'selectedTeam',
    ]),
    canExport() {
      if (!(this.breakdownArray && Object.keys(this.breakdownArray).length)) return false;
      let validIds = [1, 501, 774, 2802, 3441];
      return !!(this.user && validIds.includes(this.user.id));
    },
    breakdownTitle() {
      let list = Object.keys(this.breakdownArray);
      let options = [];
      for (let item of list) {
        let form = this.regform.find((a) => {
          return a.option === item;
        });
        if (form) {
          options.push(form.name);
        }
      }
      return options.join(' - ');
    },
  },
  methods: {
    exportClicked() {
      BsAlert.fire({
        titleText: 'How many competitors per category?',
        icon: 'question',
        input: "number",
        inputValue: "8",
        showCloseButton: true,
        preConfirm: async (value) => {
          this.exportLadder(value);
        }
      })
    },
    
    exportLadder(count) {
      let headers = [];
      let list = ['\"Ladder\"', '\"Place\"'];
      let breakdown = [`"${this.breakdownTitle}"`, ""];
      for (let item of Object.values(this.regform)) {
        if (item.enabled) {
          headers.push(item.option);
          list.push(`"${item.name}"`);
          breakdown.push("");
        }
      }
      let csvString = `${list.join(',')}\n`;
      csvString += `${breakdown.join(',')}\n`;
      for (let a of this.detailedArray(0)) {
        for (let b of this.detailedArray(1)) {
          for (let c of this.detailedArray(2)) {
            for (let d of this.detailedArray(3)) {
              for (let e of this.detailedArray(4)) {
                if (this.breakdownCompetitors(a, b, c, d, e).length) {
                  csvString += this.exportTable(a, b, c, d, e, headers, count);
                }
              }
            }
          }
        }
      }
      let blob = new Blob([csvString], {type: 'text/csv'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let name = `ladder_import__${Object.keys(this.breakdownArray).join('_')}.csv`;
      link.download = name;
      link.click();
      return csvString;
    },
    exportTable(a, b, c, d, e, headers, count) {
      let string = '';
      let competitorIds = this.breakdownCompetitors(a, b, c, d, e);
      let ladder = this.breakdownLabel(a, b, c, d, e, false);
      for (let i = 0; i < competitorIds.length; i++) {
        if (i >= count) break;
        let id = competitorIds[i];
        let place = i + 1;
        let competitor = this.competitors[id];
        let name = competitor.name.split(' ');
        let list = [ladder, place];
        for (let header of headers) {
          let match = competitor.regform.find(f => f.option.toLowerCase() === header.toLowerCase());
          let value = null;
          if (match == null || match.name === '') {
            if (header === 'first_name') {
              value = name[0];
            } else if (header === 'last_name') {
              value = name[1];
            } else if (header === 'email') {
              value = competitor.email;
            } else {
            }
          } else {
            value = match.name;
          }
          list.push(value ? `"${value}"` : '""');
        }
        string += list.join(',') + '\n';
      }
      return string;
    },
    breakdownCompetitors(a, b, c, d, e) {
      let competitors = Object.values(this.competitors);
      let name = '';
      [a, b, c, d, e].forEach((option, index) => {
        if (option) {
          name = Object.keys(this.breakdownArray)[index];
          competitors = competitors.filter(competitor => {
            return competitor.regform.find(detail => {
              if (detail.option === name) {
                return detail.multiple ? detail.multiple.indexOf(option.label) !== -1 : detail.name === option.label;
              }
            })
          })
        }
      });
      let keyed = competitors.reduce((obj, key) => {
        obj[key.id] = key;
        return obj;
      }, {});
      return this.orderCompetitors(keyed);
    },
    breakdownLabel(a, b, c, d, e, addRound) {
      let label = a ? a.label : '';
      if (b) label += ` - ${b.label}`;
      if (c) label += ` - ${c.label}`;
      if (d) label += ` - ${d.label}`;
      if (e) label += ` - ${e.label}`;
      if (addRound) {
        if (this.selectedRound) label += ` - ${this.selectedRound.name}`;
      }
      return label;
    },
    detailedArray(index) {
      if (Object.keys(this.breakdownArray).length >= (index + 1)) {
        return Object.values(this.breakdownArray[Object.keys(this.breakdownArray)[index]])
      } else {
        return [null]
      }
    },
    orderCompetitors(competitors) {
      let round = this.selectedRound;
      let scoreTotals = Object.keys(this.tournamentIn.scoreList)
      .filter(key => Object.keys(competitors).includes(key))
      .reduce((obj, key) => {
        obj[key] = round != null ? this.tournamentIn.scoreList[key].rounds[round.id] : this.tournamentIn.scoreList[key];
        obj[key]['id'] = key;
        return obj;
      }, {});
      let sorted = Object.values(scoreTotals);
      sorted.sort((a, b) => {
        let n = b.points - a.points;
        if (n !== 0) {
          return n
        }
        let found = 0;
        this.tieBreakers.forEach(breaker => {
          if (!found) {
            let compare = b[breaker] - a[breaker];
            if (compare !== 0) {
              found = compare;
            }
          }
          
        });
        return found;
      });
      sorted.forEach(function (competitor, index) {
        scoreTotals[competitor.id]['place'] = index + 1;
      });
      return sorted.map(m => {
        return m.id
      });
    },
    selectTab(round) {
      this.selectedRound = round === 'overall' ? null : round;
      let previousTab = document.querySelector('.selectedTab');
      previousTab.className = "tabItem py-3";
      let newTab = document.querySelector(round === 'overall' ? '#overall' : '#round_' + round.id);
      newTab.className = "selectedTab";
    },
  }
}
</script>


<style scoped>

</style>