<template>
  <div class="mx-1 sm:mx-4">
    <div class="flex justify-between flex-col sm:flex-row">
      <div>
        <h1 class="text-4xl">
          <a :href="'/tournaments/' + tournament.slug" class="text-blue-600">{{ tournament.name }}</a>
        </h1>
      </div>
      <div class="sm:mt-3">
        <span class="text-gray-600 float-right">{{ startDate }}</span>
      </div>
    </div>
    <hr class="mt-0">
    <div v-if="waitListed" class="text-3xl text-center text-red-600">
      Competitor is currently on the wait list
    </div>
    <div class="mt-3">
      <div class="shadow-inner bg-blue-100 p-2 mb-2 text-center">
        <h2 class="text-xl ">Thanks for signing up {{ competitor.first_name }}!</h2>
        <p>
          This page is private to you. Sharing this page with anyone else gives them full access to pay,
          withdraw, or edit your info.
        </p>
        <div class="flex justify-center">
          <dropdown v-if="additionalCompetitors.length > 1" :align="'right'">
            <template v-slot:trigger>
              <button class="btn-sm btn-blue-600">
                Selected Competitor: {{ competitor.name }} <i
                class="fas fa-caret-down"></i>
              </button>
            </template>
            <div @click="linkClicked(c)" v-for="c in additionalCompetitors" class="dropdown-item">
              {{ c.name }}
            </div>
          </dropdown>
        </div>
      
      </div>
      <div class="p-2">
        <div class="flex flex-wrap justify-between">
          <div class="text-2xl">Competitor Details</div>
          <div class="flex flex-wrap actionButtons">
            <div v-if="tournament.user_scoring && !waitListed" class="btn-gray-600 btn-sm">
              <router-link :to="`/user_scoring/${competitor.uuid}`"> Score</router-link>
            </div>
            <div @click="register()" class="btn-sm btn-outline-blue-600"> Register Again</div>
            <div v-if="competitor.paid" class="btn-blue-600 btn-sm disabled">Paid</div>
            <a v-else-if="!waitListed" :href="`/competitor_invoice/${competitor.uuid}`"
               class="btn-blue-600 btn-sm">
              <i class="fal fa-file-invoice-dollar"></i> Pay
            </a>
            <a v-if="tournament.payments.pricing_extras && tournament.payments.pricing_extras.length > 0"
               :href="`/purchase_extras/${competitor.uuid}`" class="btn-sm btn-outline-green-500"> Purchase Extras
            </a>
            <div class="btn-outline-blue-300 btn-sm">
              <router-link :to="`/competitors/${competitor.uuid}/edit`">
                <i class="fas fa-cog"></i> Edit
              </router-link>
            </div>
            <div v-if="competitor.withdrew" class="btn-gray-400 btn-sm disabled"> Withdrawn</div>
            <div v-else-if="competitor.withdraw_request" class="btn-gray-400 btn-sm disabled">
              Requested Withdrawal
            </div>
            <div v-else @click="withdraw" class="btn-outline-gray-600 btn-sm">Request to Withdraw</div>
          </div>
        </div>
        <hr>
        <competitor-detail-row :name="'First Name'" :value="competitor.first_name"/>
        <competitor-detail-row :name="'Last Name'" :value="competitor.last_name"/>
        <competitor-detail-row :name="'Email'" :value="competitor.email"/>
        <competitor-detail-row v-if="competitor.target_id" :name="'Target'" :value="getTargetName()"/>
        <competitor-detail-row v-for="detail in competitor.regform" :key="detail.id"
                               :name="nameFromDetail(detail)"
                               :image="detail.type === 'image'"
                               :value="detailFormat(detail)"/>
        <competitor-detail-row v-if="!competitor.paid && competitor.partial_paid" :name="'Paid'" :value="partialPaid"/>
        <competitor-detail-row v-if="competitor.pricing_extras" :name="'Extras'" :value="selectedExtras"/>
        
        <div v-for="(round, index) in competitor.rounds" :class="index % 2 === 0 ? 'bg-gray-200' : ''">
          <div v-if="!round.hidden || round.hidden && user && user.auth_manage">
            <competitor-detail-row v-if="competitor.rounds.length > 1" :name="'Round'" :value="round.name"/>
            <competitor-detail-row :name="'Line Time'" :value="getLineTime(round)"/>
            <competitor-detail-row :name="'Location'"
                                   :value="round.pivot.location ? round.pivot.location : 'Not Assigned'"/>
            <competitor-detail-row :name="'Bale'" :value="round.pivot.bale ? round.pivot.bale : 'Not Assigned'"/>
          </div>
          <div v-else>
            <competitor-detail-row :name="'Round'" :value="round.name"/>
            <competitor-detail-row :name="' '" :value="'details are not publicly available'"/>
          </div>
        </div>
      </div>
      <div class="p-2">
        <h3 class="text-2xl">
          Tournament Details
        </h3>
        <hr>
        <div class="flex flex-wrap">
          <div class="w-full sm:w-1/2 px-2">
            <competitor-detail-row :name="'Club'" :value="''" :tournament_detail="true">
              <a :href="'/clubs/' + tournament.club.slug"
                 class="text-blue-600">{{ tournament.club.name }}</a>
            </competitor-detail-row>
            <competitor-detail-row :name="'Starts'" :value="startDateFull" :tournament_detail="true"/>
            <competitor-detail-row :name="'Ends'" :value="endDateFull" :tournament_detail="true"/>
            <competitor-detail-row :name="'Location'" :value="tournamentLocation" :tournament_detail="true"/>
          </div>
          <div v-if="tournament.description" class="w-full sm:w-1/2 px-2 mt-2">
            <pre style="font-family: 'Roboto', sans-serif;"
                 class="shadow-inner bg-gray-200 rounded p-4 whitespace-pre-wrap">
                {{ tournament.description }}
            </pre>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import CompetitorDetailRow from "./CompetitorDetailRow.vue";
import moment from "moment/moment";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "CompetitorDetails",
  components: {CompetitorDetailRow},
  props: ['tournament', 'competitor'],
  data() {
    return {
      additionalCompetitors: [],
      redirecting: false,
    }
  },
  watch: {
    competitor() {
      this.redirecting = false;
    }
  },
  computed: {
    waitListed() {
      return this.competitor.wait_list !== null;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    startDate() {
      let date = new Date(this.tournament.start_time);
      return moment(date).format('DD MMM YYYY');
    },
    startDateFull() {
      let date = new Date(this.tournament.start_time);
      return moment(date).format('DD MMM YYYY h:mm A');
    },
    endDateFull() {
      let date = new Date(this.tournament.end_time);
      return moment(date).format('DD MMM YYYY h:mm A');
    },
    tournamentLocation() {
      return this.tournament.address.address1 + (this.tournament.address.address2 ? this.tournament.address.address2 : '') +
        ' ' + this.tournament.address.city + ', ' + this.tournament.address.state;
    },
    selectedExtras() {
      let extras = '';
      this.competitor.pricing_extras.forEach(extra => {
        extras += `${extra.name} ( ${extra.type}$${extra.amount}.00 )  `;
      });
      return extras;
    },
    partialPaid() {
      let paid = '$';
      paid += (this.competitor.partial_paid + (this.competitor.paid_processing ? 2 : 0)) + ' of the $';
      let price = parseInt(this.tournament.payments.price + this.tournament.payments.processing_fee);
      if (this.competitor.pricing_extras) {
        this.competitor.pricing_extras.forEach(extra => {
          if (extra.type === '+') {
            price += parseInt(extra.amount);
          } else {
            price -= parseInt(extra.amount);
          }
        });
      }
      paid += price + ' tournament fee';
      return paid;
    },
  },
  methods: {
    nameFromDetail(detail) {
      let option = detail.option;
      return option
      .replace(/[-_]/g, ' ') // Replace "-" and "_" with spaces
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/\b\w/g, char => char.toUpperCase());
    },
    async linkClicked(c) {
      if (c.id !== this.competitor.id) {
        try {
          await this.$router.push({name: 'competitor', params: {uuid: c.uuid}});
          this.$emit('getCompetitor', c.uuid);
        } catch (e) {
          if (e.name === 'NavigationDuplicated') {
            // The link was clicked a second time before waiting for the route to load.
          } else {
            throw (e);
          }
        }
      }
    },
    additionalRegistrations() {
      this.$axios.get(`/tournaments/${this.tournament.slug}/user_competitors`).then(({data}) => {
        this.additionalCompetitors = data.competitors ? data.competitors : [];
      })
    },
    register() {
      BsAlert.fire({
        titleText: 'Register for this Tournament Again?',
        html: 'You have already registered for this tournament. Please only continue if you intend to compete more than once.' +
          ' If you would like to register someone else,' +
          ' either have them do it from their own account or use the ' +
          '<a href="/tournaments/' + this.tournament.slug + '#/group" class="text-blue-600 cursor-pointer"> register groups</a> feature. ',
        icon: 'info',
        confirmButtonText: 'Continue',
      }).then(({dismiss}) => {
        if (!dismiss) {
          window.location.href = '/tournaments/' + this.tournament.slug + '#/register';
        }
      })
    },
    detailFormat(detail) {
      let formatted = '';
      if (detail.multiple) {
        formatted = detail.multiple.join(', ')
      } else {
        formatted = detail.name
      }
      if (detail.type === 'image' && !detail.enabled) {
        formatted = '/img/imagePending.jpg';
      }
      return formatted;
    },
    getLineTime(round) {
      if (round.pivot.line_time) {
        return round.line_times.find(q => q.id === parseInt(round.pivot.line_time)).time
      }
      return "Not Assigned"
    },
    getTargetName() {
      let target_id = this.competitor.target_id;
      let target = this.tournament.targets.find(f => parseInt(f.id) === parseInt(target_id));
      if (!target) {
        target = this.tournament.targets[0];
      }
      return target.name;
    },
    withdraw() {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + this.competitor.uuid + '/status';
      BsAlert.fire({
        titleText: 'Request Withdrawal',
        html: 'Are you sure that you want to withdraw from ' + this.tournament.name + '? <br> Please tell the coordinator why you want to withdraw from this tournament.',
        input: 'textarea',
        footer: 'This message will be sent to the tournament coordinator. You will be notified upon further action.',
        confirmButtonText: 'Withdraw',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        },
        preConfirm: (message) => {
          return this.$axios.post(url, {
            'action': 'withdraw_request',
            'message': message
          })
                     .then(response => {
                       if (!response.data.success) {
                         throw new Error(response.statusText)
                       }
                       return BsAlert.fire({
                         title: 'Sent!',
                         html: 'Your request to withdraw has been delivered.',
                         icon: 'success',
                         showCancelButton: true
                       })
                     })
                     .catch(error => {
                       BsAlert.fire(
                         {
                           title: `Request failed: ${error}`, showCancelButton: false,
                         }
                       )
                     })
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    }
  },
  created() {
    this.additionalRegistrations();
  },
}
</script>

<style scoped>
.actionButtons > div, a {
  @apply cursor-pointer m-1;
}
</style>
