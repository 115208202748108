<template>
  <div>
    <div class="flex flex-col sm:flex-row justify-between">
      <div class="text-4xl">Master Code: {{ code ? code.code : 'None' }}
        <span v-if="code && code.disabled" class="text-gray-600 text-sm">Disabled</span>
      </div>
      <div class="text-4xl">Master Ladder Code: {{ ladderCode ? ladderCode.code : 'None' }}
        <span v-if="ladderCode && ladderCode.disabled" class="text-gray-600 text-sm">Disabled</span>
      </div>
      <div class="flex flex-col justify-center">
        <div>
          <button v-if="code" @click="toggleEnabled" class="btn-sm btn-outline-gray-600">
            {{ code.disabled ? 'Enable ' : 'Disable ' }} Master Code
          </button>
          <button @click="generateCode" class="btn-sm btn-outline-blue-600">
            {{ code ? 'Regenerate' : 'Generate' }} Master Code
          </button>
        </div>
      </div>
    </div>
    <div class="text-sm text-gray-700">
      This code will authorize any device to score the tournament.
      The device will be redirected to the tournament scoring page with full access to all competitors.
    </div>
  </div>
</template>

<script>

import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "MasterCode",
  props: ['tournament'],
  data() {
    return {
      show: false,
      code: null,
      loading: false,
      ladderCode: null,
    }
  },
  methods: {
    getCode() {
      this.$axios
      .get('/tournaments/' + this.tournament.slug + '/get_master_code')
      .then(({data}) => {
        if (data.success) {
          this.code = data.code;
          this.ladderCode = data.ladder;
        }
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    generateCode() {
      let vm = this;
      vm.loading = true;
      if (this.code) {
        BsAlert.fire({
          titleText: 'Really Generate New Code?',
          icon: 'warning',
          html: 'This will invalidate the existing code.',
        }).then(({dismiss}) => {
          if (!dismiss) {
            this.$axios
            .post('/tournaments/' + vm.tournament.slug + '/generate_master_code')
            .then(({data}) => {
              vm.code = data.code;
              vm.ladderCode = data.ladder;
              vm.loading = false
            })
            .catch(({response}) => {
              console.log(response);
            });
          }
        })
      } else {
        this.$axios
        .post('/tournaments/' + vm.tournament.slug + '/generate_master_code')
        .then(({data}) => {
          vm.code = data.code;
          vm.ladderCode = data.ladder;
          vm.loading = false
        })
        .catch(({response}) => {
          console.log(response);
        });
      }
      
    },
    toggleEnabled() {
      this.$axios
      .post('/tournaments/' + this.tournament.slug + '/toggle_master_code')
      .then(({data}) => {
        this.code.disabled = !this.code.disabled
      })
      .catch(({response}) => {
        console.log(response);
      })
    },
  },
  mounted() {
    this.getCode()
  }
}
</script>

<style scoped>

</style>