<template>
  <div class="bs-right-margin">
    <div class="flex justify-between pb-1">
      <h1 class="text-3xl"> Clubs </h1>
      <div class="flex flex-col justify-center">
        <new-club-button :show-button="true"/>
      </div>
    </div>
    <hr class="mt-0">
    <div class="flex flex-col-reverse sm:flex-row my-2 justify-center">
      <div class="bs-form-box w-full sm:w-3/4 md:w-2/3 lg:w-1/2 p-0 flex rounded-lg">
        <div @click="applySearch" class="flex flex-col justify-center text-gray-500 p-2 mr-2 cursor-pointer">
          <i class=" fas fa-search"></i>
        </div>
        <input class="flex-grow focus:outline-none" :placeholder="' Find Clubs'" v-model="search">
        <button v-if="search.length > 0" @click="clearSearch"
                class="flex flex-col justify-center text-gray-500 p-2 mr-2 font-light">
          <i class=" fas fa-times"></i>
        </button>
        <div v-else>&nbsp;</div>
      </div>
    </div>
    
    <div class="flex flex-wrap">
      <club-list-item v-for="club in clubs" :key="club.id" :club="club"/>
      
      <div v-if="!clubs.length">
        <p>Nothing found</p>
      </div>
    </div>
    <div>
    
    
    </div>
  </div>
</template>

<script>
import ClubListItem from "./ClubListItem.vue";
import NewClubButton from "../partials/NewClubButton.vue";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ClubList",
  components: {NewClubButton, ClubListItem},
  props: ['inc_clubs', 'user'],
  data() {
    return {
      clubs: this.inc_clubs,
      search: ''
    }
  },
  watch: {
    search(text) {
      if (!text.length) {
        this.clubs = this.inc_clubs;
      } else if (text.length > 2 || Object.keys(this.clubs).length) {
        this.applySearch();
      }
    }
  },
  methods: {
    
    
    applySearch() {
      this.$axios.get('/clubs', {
        params: {
          'search': this.search
        }
      }).then(({data}) => {
        if (data['error']) {
          BsAlert.fire({title: data['error'], showCancelButton: false})
        } else {
          this.clubs = data['clubs']
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    clearSearch() {
      this.search = '';
      this.applySearch();
    }
  }
}
</script>

<style scoped>

</style>
