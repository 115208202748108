<template>
  <div class="flex-grow sm:m-2 md:mx-auto max-w-7xl">
    <template v-if="online">
      <tournament-form
        v-if="onlineTournament"
        :inc_tournament="onlineTournament"
        :club="onlineTournament.club"
        :sport-list="onlineTournament.sportList"
        :target-list="onlineTournament.targetList"/>
      <LoadingDisplay v-else :message="loadingMessage"/>
    </template>
    
    <online-feature v-else/>
  </div>
</template>

<script>
import CloneTournament from "../partials/CloneTournament.vue";
import DeleteTournament from "../partials/DeleteTournament.vue";
import OnlineFeature from "../partials/OnlineFeature.vue";
import TournamentForm from "./form/TournamentForm.vue";
import LoadingDisplay from "./LoadingDisplay.vue";
import Bugsnag from "@bugsnag/js";

export default {
  name: "TournamentEdit",
  components: {LoadingDisplay, OnlineFeature, DeleteTournament, CloneTournament, TournamentForm},
  props: ['tournamentIn'],
  data() {
    return {
      onlineTournament: null,
      loadingMessage: 'Loading Details',
    }
  },
  computed: {
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    tournament() {
      return this.tournamentIn;
    }
  },
  mounted() {
    this.getTournament();
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('refresh');
    next()
  },
  methods: {
    axiosError(error) {
      if (error.response) {
        Bugsnag.notify(error, event => {
          if (this.user !== null) event.setUser(this.user.id, this.user.email, this.user.full_name);
          event.addMetadata('Axios', {
            status: error.response.status,
            statusText: error.response.statusText,
            headers: error.response.headers,
            data: error.response.data
          });
        });
      } else {
        Bugsnag.notify(error);
      }
    },
    getTournament() {
      this.$axios.get('/tournaments/' + this.tournament.slug + '/edit').then(({data}) => {
        this.onlineTournament = data.tournament;
      }).catch((e) => {
        this.loadingMessage = null;
        this.axiosError(e)
      });
    }
  }
}
</script>

<style scoped>

</style>
