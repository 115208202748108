<template>
  <div class="flex-grow my-2 card card-blue-700 flex flex-col">
    <div class="card-header">Import Registrations</div>
    <div v-if="online" class="w-full card-body">
      <div class="bg-blue-100 p-2">
        <div class="text-lg text-center">
          CSV file Example
        </div>
        <div>
          <div class="table-responsive">
            <table class="table table-bordered table-striped">
              <thead>
              <tr class="bg-white">
                <template v-for="field in tournament.regform">
                  <th v-if="field.type !== 'header' && field.enabled && field.type !== 'waiver'"
                      class="table-header text-center">
                    {{ field.name }}
                  </th>
                </template>
                <th v-if="tournament.targets.length > 1" class="table-header text-center">
                  Target
                </th>
                <th v-if="tournament.line_times.length > 1" class="table-header text-center">
                  Line Time
                </th>
              
              </tr>
              </thead>
              <tbody>
              <tr class="align-top bg-gray-100">
                <template v-for="field in tournament.regform">
                  <td v-if="field.type !== 'header' && field.type !== 'waiver' && !field.multiple && field.enabled"
                      class="text-center">
                    <div class="underline font-bold">Type</div>
                    {{ field.type }}
                  </td>
                  <td v-else-if="field.type !== 'header' && field.type !== 'waiver' && field.enabled"
                      class="text-center">
                    <div class="underline font-bold">Choices</div>
                    <div v-for="option in field.multiple">{{ option.label }}</div>
                    <div v-if="field.type === 'checkbox'"
                         class="mt-2 text-xs text-gray-600 whitespace-normal">
                      separate multiple with a comma, no spaces
                    </div>
                  </td>
                
                </template>
                <td v-if="tournament.targets.length > 1" class="text-center">
                  <div class="underline font-bold">Choices</div>
                  <div v-for="target in tournament.targets">{{ target.name }}</div>
                </td>
                <td v-if="tournament.line_times.length > 1" class="text-center">
                  <div class="underline font-bold">Choices</div>
                  <div v-for="time in tournament.line_times">{{ time.time }}</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      
      <form @submit.prevent>
        <input type="hidden" name="_token" :value="csrf">
        
        <div class="flex flex-wrap">
          <div class="flex-grow flex flex-col p-2 m-4">
            <div class="text-sm">
              <div>Uploaded file must be of type '.csv'.</div>
              <div>Column order does not matter.</div>
              <div>Headers and choices must match provided spacing, capitalization, spelling and
                punctuation.
              </div>
              <div>Columns not matching this template will not be included.</div>
              <div>Download a template that you can fill in using a spreadsheet!</div>
              <label class="text-blue-600 cursor-pointer mt-3">
                <input @change="sport80Convert" type="file" id="sport80" name="sport80"
                       accept="text/csv" class="hidden">
                Click here to create a Bowscore compatible import file from an existing Sport80 .csv
                file.
              </label>
            </div>
          </div>
          <div class="flex flex-col">
            <div v-if="showLoader" class="my-3 text-center">
              <div class="whitespace-pre-line">
                This may take a while with large tournaments.
                Do not leave this page until the import is complete.
              </div>
              <div><i class="fas fa-2x fa-spinner fa-spin"></i></div>
              <div class="text-lg">{{ percentage < 100 ? percentage : 99 }}% Complete</div>
            </div>
            <div v-else class="flex flex-col justify-between p-5 h-full">
              <a :href="'/tournaments/' + tournament.slug + '/template'" class="btn btn-outline-blue-600" download>
                <i class="fas fa-download mr-2"></i>Download Template
              </a>
              <label class="btn btn-outline-blue-600 w-full">
                <input @change="submitButton" type="file" id="registrations" name="registrations"
                       accept="text/csv" class="hidden">
                <i class="fas fa-file-upload mr-2"></i> Upload File
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else class="card-body">
      <div>This feature is only available online</div>
    </div>
  
  </div>
</template>

<script>

import {BsAlert, Toast} from "../../mixins/swal_mixins";

export default {
  name: "Import",
  props: ['tournamentIn', 'user'],
  data() {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      completed: 0,
      chunkCount: 0,
      showLoader: false,
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    percentage() {
      if (this.completed) {
        let count = this.chunkCount > 0 ? this.chunkCount : 1;
        return Math.round(((this.completed / count) * 100));
      } else {
        return 0;
      }
    }
  },
  watch: {
    percentage(newValue) {
      if (newValue === 100) {
        this.$axios
        .get('/tournaments/' + this.tournament.slug + '/suite/tournament')
        .then(({data}) => {
          this.$store.commit('tournament/SET_TOURNAMENT', {
            'tournament': data.tournament
          });
          this.showLoader = false;
          Toast.fire({title: 'Import complete!', icon: 'success'});
        })
        .catch(({response}) => {
          console.log(response);
        });
        
      }
    }
  },
  methods: {
    sport80Convert() {
      let vm = this;
      var formData = new FormData();
      var csv = document.querySelector('#sport80');
      if (!csv.files.length) {
        return BsAlert.fire({title: 'No file has been selected. Please try again.', showCancelButton: false});
      }
      formData.append("sport80", csv.files[0]);
      this.$axios.post('/tournaments/' + vm.tournament.slug + '/sport80', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        let blob = new Blob([response.data], {type: 'text/csv'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.headers['content-disposition'].split('=')[1];
        link.click()
      }).catch(({response}) => {
        console.log(response);
        BsAlert.fire({
          titleText: 'Conversion process failure',
          html: 'The format did not perfectly match our Sport80 Template. ' +
            'You may manually create a compatible csv by downloading the ' +
            'template and copying your data to match the examples.',
          icon: 'error',
          showCancelButton: false,
        });
      });
    },
    submitButton() {
      let vm = this;
      var formData = new FormData();
      var csv = document.querySelector('#registrations');
      if (!csv.files.length) {
        return BsAlert.fire({title: 'No file has been selected. Please try again.', showCancelButton: false});
      }
      vm.showLoader = true;
      formData.append("registrations", csv.files[0]);
      this.$axios.post('/tournaments/' + vm.tournament.slug + '/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.competitors) {
          const [list, chunkSize] = [response.data.competitors, 200];
          if (!list.length) {
            vm.showLoader = false;
            return BsAlert.fire({title: 'CSV file contained no valid competitor rows', showCancelButton: false});
          }
          let chunks = new Array(Math.ceil(list.length / chunkSize)).fill().map(_ => list.splice(0, chunkSize));
          vm.chunkCount = chunks.length;
          
          let index = 0;
          
          function sendChunk(chunk) {
            vm.$axios.post('/tournaments/' + vm.tournament.slug + '/import/update', {competitors: chunk})
            .then((response) => {
              if (response.data.completed) {
                vm.completed++;
                index++;
                if (vm.percentage !== 100) {
                  sendChunk(chunks[index])
                }
              }
            }).catch(({response}) => {
              vm.showLoader = false;
              BsAlert.fire({
                titleText: 'Upload process failure',
                html: response.data.message,
                icon: 'error',
                showCancelButton: false
              });
              console.log(response);
            });
            
          }
          
          sendChunk(chunks[index]);
        }
      }).catch(({response}) => {
        console.log(response);
        vm.showLoader = false;
        BsAlert.fire({
          titleText: 'Upload process failure',
          html: response.data.message,
          icon: 'error',
          showCancelButton: false
        });
      });
    },
  }
}
</script>

<style scoped>
th, td {
  @apply whitespace-nowrap
}
</style>
