<script setup>
import GlobalSearch from "../GlobalSearch.vue";
import UserButton from "./UserButton.vue";
import FormBanner from "./FormBanner.vue";
import {useTournamentStore} from "../../stores/TournamentStore";
import {computed} from "vue";
import {useStore} from 'vuex';

const props = defineProps({user: Object});

const store = useTournamentStore();

const vStore = useStore();

const locked = computed(() => {
  let tournament = vStore.getters['tournament/getTournament'];
  if (tournament == null) return false;
  let value = vStore.getters['tournament/getLocked'](tournament['slug'])
  return value;
});
</script>

<template>
  <div v-if="!locked">
    <FormBanner v-if="store.banner === 'formEdit'" :user="props.user">
      <template #buttons>
        <div class="flex justify-end">
          <button class="btn btn-stone-400 discard" @click="store.discard()">Discard</button>
          <button v-if="store.canSave" @click="store.save()" class="btn btn-stone-50 save">
            Save <i v-if="store.saveLoading" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </template>
    </FormBanner>
    <div v-else>
      <div class="banner flex justify-between">
        <div class="flex-1 flex flex-col justify-center">
          <img src="/img/Bowscore Logo.svg" alt="bowscore logo" class="logo">
        </div>
        <global-search class="flex-3 hidden lg:flex"/>
        <div class="flex-1 flex flex-col justify-center">
          <div class="flex justify-end">
            <user-button :user="props.user"/>
          
          </div>
        </div>
      </div>
      <div class="lg:hidden banner py-3">
        <global-search/>
      </div>
    </div>
  
  </div>
</template>

<style scoped>
.banner {
  @apply bg-stone-300;
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  margin: -5px;
  height: 14px;
  width: 118px;
}

.discard {
  @apply mr-2;
  width: 128px;
}

.save {
  @apply mr-2;
  width: 98px;
}

@media (min-width: 1100px) {
  .logo {
    margin: -15px;
    height: 24px;
    width: 210px;
  }
  
  .banner {
    height: 80px;
    padding-left: 64px;
    padding-right: 64px;
  }
}

</style>