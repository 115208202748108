<template>
  <div v-if="user" class="bs-right-margin">
    <div class="text-4xl flex flex-wrap">Manage Archers</div>
    <hr>
    <div>
      <div class="mt-2 mb-8">
        <button v-if="!managesSelf" @click="addSelf" class="bs-shadow-card mr-2">
          <i class="fas fa-plus mr-2"></i> Add Me
        </button>
        <button @click="addGroup" class="bs-shadow-card mr-2">
          <i class="fas fa-plus mr-2"></i> Add Group
        </button>
      </div>
      <div class="bs-card mb-8">
        <div class="bs-card-header">How to add an archer:</div>
        <ul class="ml-6">
          <li>Have the archer log into or create an account at <a href="/register">bowscore.com/register</a></li>
          <li>Have them go to their account management page at <a href="/account">bowscore.com/account</a></li>
          <li>Have them add your Bowscore email ( {{ user.email }} ) to their "My Managers list"</li>
        </ul>
        <p class="text-center">
          Archers added this way will appear in the list below.
        </p>
      </div>
      <div class="overflow-scroll">
        <ul class="tabs mb-3">
          <li class="selectedTab" id="generalTab">
            <a @click.prevent="selectTab({})" href="" data-toggle="tab" role="tab">General Information</a>
          </li>
          <li v-for="s in sportslist" :id="s.abbrev" class="tabItem" data-toggle="tab" role="tab">
            <a @click.prevent="selectTab(s)" href="" class="">{{ s.abbrev }}</a>
          </li>
        </ul>
        
        <div v-if="user.managed_archers.length">
          <div v-if="sport && !Object.values(sport).length" class="table-responsive">
            <table class="table table-striped table-bordered table-fixed">
              <thead>
              <tr>
                <th class="table-header w-20">Profile</th>
                <th class="table-header w-48">First Name</th>
                <th class="table-header w-48">Last Name</th>
                <th class="table-header w-72">Email</th>
                <th class="table-header w-48">Groups</th>
                <th class="table-header w-28">Stop Managing</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="archer in user.managed_archers">
                <td style="padding:2px !important;">
                  <div class="w-full flex justify-center">
                    <a :href="'/archers/' + archer.user.slug" v-if="archer.user">
                      <img v-if="archer.user.image" :src="archer.user.image"
                           width="40" height="40">
                      <span v-else>
                        <i class="fal fa-2x fa-user text-blue-500 hover:text-blue-600"></i>
                      </span>
                    </a>
                    <a style="cursor: default !important;" @click.prevent v-else title="No user account"
                       class="flex flex-col">
                      <span class="text-center">
                        <i class="fal fa fa-user-slash text-grey-lighter"></i>
                      </span>
                      <span class="text-xs text-center">No Account</span>
                    </a>
                  </div>
                </td>
                <td class="editable" @click.prevent="editing = archer.id + '_general_' + field"
                    v-for="field in generals">
                  <div @click.stop v-show="editing === archer.id + '_general_' + field">
                    <Field
                      @blur="updateField(archer.registration_defaults.general[field])"
                      class="bs-form-box w-full"
                      :rules="(field === 'email' ? 'email' : '')"
                      :name="archer.id + '_general_' + field"
                      :ref="archer.id + '_general_' + field"
                      v-model="general(archer)[field]"/>
                  </div>
                  <a v-show="editing !== archer.id + '_general_' + field" href=""
                     :class="archer.registration_defaults.general[field] ? 'text-black' : ''">
                    {{
                      archer.registration_defaults.general[field] ? archer.registration_defaults.general[field] : 'none'
                    }}
                  </a>
                </td>
                <td class="editable" @click="groupClicked(archer)">
                  <div class="w-full" v-if="editing !== `${archer.id}_groups`">{{ groupDisplay(archer.groups) }}</div>
                  <div class="w-full" v-else>
                    <multiselect
                      v-model="archer.groups"
                      :options="user.groups"
                      :hideSelected="true"
                      :multiple="true"
                      :searchable="false"
                      :showLabels="false"
                      track-by="name"
                      label="name"
                      @update:modelValue="updateGroup"
                    />
                  </div>
                </td>
                <td style="padding:2px !important;">
                  <div class="w-full flex justify-center">
                    <div class="flex flex-col justify-center">
                      <button @click="removeArcher(archer)" class="btn btn-outline-gray-600"><i
                        class="fas fa-ban"></i></button>
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="table-responsive">
            <table class="table table-striped table-bordered table-fixed">
              <thead>
              <tr>
                <th class="table-header w-20">Profile</th>
                <th class="table-header w-48">First Name</th>
                <th class="table-header w-48">Last Name</th>
                <th class="table-header w-48 xl:w-auto" v-for="(options, name) in sport.options">
                  {{ name }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="archer in user.managed_archers">
                <td style="padding:2px !important;">
                  <div class="w-full flex justify-center">
                    <a :href="'/archers/' + archer.user.slug" v-if="archer.user_id">
                      <img v-if="archer.user.image" :src="archer.user.image"
                           width="40" height="40">
                      <span v-else>
                        <i class="fal fa-2x fa-user text-blue-500 hover:text-blue-600"></i>
                      </span>
                    </a>
                    <a style="cursor: default !important;" @click.prevent v-else title="No user account"
                       class="flex flex-col">
                      <span class="text-center">
                        <i class="fal fa fa-user-slash text-gray-600"></i>
                      </span>
                      <span class="text-xs text-center">No Account</span>
                    
                    </a>
                  </div>
                </td>
                <td style="cursor: default !important;">{{ general(archer).first_name }}</td>
                <td style="cursor: default !important;">{{ archer.registration_defaults.general.last_name }}</td>
                <td class="editable" @click.prevent="editing = archer.id + '_' + sport.id + '_' + name"
                    v-for="(options, name) in sport.options">
                  <div @click.stop v-if="editing === archer.id + '_' + sport.id + '_' + name">
                    <select v-if="sport.options[name].type === 'radio'"
                            @blur="updateField(archer.registration_defaults[sport.id][name])"
                            :ref="archer.id + '_' + sport.id + '_' + name"
                            v-model="archer.registration_defaults[sport.id][name]"
                            class="bs-form-box w-full">
                      <option v-for="option in options.options" :value="option">{{ option }}</option>
                    </select>
                    <input v-else
                           @blur="updateField(archer.registration_defaults[sport.id][name])"
                           class="bs-form-box w-full"
                           :ref="archer.id + '_' + sport.id + '_' + name"
                           v-model="archer.registration_defaults[sport.id][name]"
                    >
                  </div>
                  <a v-else href="" @click=""
                     :class="archer.registration_defaults[sport.id][name] ? 'text-black' : ''">
                    {{
                      archer.registration_defaults[sport.id][name] ? archer.registration_defaults[sport.id][name] : 'none'
                    }}
                  </a>
                </td>
              </tr>
              </tbody>
            
            </table>
          </div>
        </div>
        <div v-else class="text-center">
          You do not have any archers to manage.
        </div>
      </div>
    </div>
    <div class="bs-card my-8">
      <div class="font-semibold mb-4">Adding an archer using the above method provides the following benefits:</div>
      
      <ul class="ml-6">
        
        <li> The ability to register and update personal details on their own</li>
        <li> Allowing multiple managers, keeping your account secure</li>
        <li> All their tournament registrations and results will be on their own home page instead of cluttering yours
        </li>
        <li> They can drop you as manager at any time</li>
      
      </ul>
      <div class="font-medium">
        It is highly recommended to add archers from their own account.<br>
        If you are worried about adding a child that may not have
        their own email, you can create an account for them by adding a suffix to your email. EX: {{ suffixEmail }}
      </div>
      <div class="text-center mt-4">If you cannot create an account for them, you may add an archer
        <a @click.prevent="addArcher" href="/">here</a>. <br>This way will NOT link the archer to their own account.
      </div>
    </div>
  </div>
</template>

<script>
import {Field, validate} from 'vee-validate';
import ToolTip from "../partials/ToolTip.vue";
import Multiselect from "vue-multiselect";
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ManageArchers",
  components: {Multiselect, ToolTip, Field},
  props: ['inc_user', 'sportslist'],
  data() {
    return {
      sport: {},
      editing: '',
      selected: 'None',
      user: this.inc_user,
      generals: ['first_name', 'last_name', 'email'],
    }
  },
  computed: {
    suffixEmail() {
      let email = this.user.email.split('@');
      return `${email[0]}+child_name@${email[1]}`;
      
    },
    managesSelf() {
      if (this.user.managed_archers) {
        return this.user.managed_archers.findIndex(f => f.user_id === this.user.id) !== -1;
      }
      return false;
    }
  },
  methods: {
    updateGroup(value, id) {
      this.editing = '';
      let archerId = this.archerGroup;
      this.$axios.post('/groups/update', {
        groups: value.map((m) => m.id), archerId: archerId
      })
    },
    groupDisplay(groups) {
      let list = [];
      if (groups == null || !groups.length) return 'none';
      for (let group of groups) {
        if (group !== null) {
          list.push(group.name);
        }
      }
      list.sort((a, b) => {
        let aValue = a.toUpperCase();
        let bValue = b.toUpperCase();
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      });
      return list.join(', ');
    },
    general(archer) {
      return archer.registration_defaults.general;
    },
    selectTab(s) {
      this.sport = s;
      if (this.sport.abbrev) {
        let previousTab = document.querySelector('.selectedTab');
        previousTab.className = "tabItem";
        
        let newTab = document.querySelector(`[id="${this.sport.abbrev}"]`);
        newTab.className = "selectedTab";
      } else {
        let selectedTab = document.querySelector('.selectedTab');
        selectedTab.className = "tabItem";
        
        let generalTab = document.querySelector('#generalTab');
        generalTab.className = "selectedTab";
      }
    },
    updateField(value) {
      let details = this.editing.split('_');
      this.editing = '';
      this.$axios
      .post('/defaults/' + this.user.slug, {
        archer: details[0],
        sport: details[1],
        option: details[2],
        value: value ? value : '',
        user: this.user.archer.id === parseInt(details[0]) ? null : this.user.id,
      })
      .then(({data}) => {
        if (data.error) {
          BsAlert.fire({icon: 'error', title: data.error.message, showCancelButton: false});
        }
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    groupClicked(archer) {
      this.editing = `${archer.id}_groups`;
      this.archerGroup = archer.id;
    },
    addGroup() {
      let vm = this;
      BsAlert.fire({
        title: 'Enter a name for the group',
        input: 'text',
        confirmButtonText: 'Add Group',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return validate(value, 'clean|required').then(({valid, errors}) => {
            if (valid) {
              return;
            }
            if (errors.length) {
              return errors.join(' ');
            }
          });
        },
        preConfirm: (name) => {
          return this.$axios
          .post('/groups/create', {
            name: name,
            user: vm.user,
          })
          .then(({data}) => {
            if (data.success) vm.user.groups.push(data.group);
            if (data.error) BsAlert.fire({icon: 'error', title: data.error.message, showCancelButton: false});
          })
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    },
    addSelf() {
      this.$axios
      .post('/profile/' + this.inc_user.slug + '/manager/create', {
        email: this.user.email,
        self: true,
      })
      .then(({data}) => {
        if (data.success) {
          this.user.managed_archers.push(data.archer);
        } else if (data.error) {
          console.log(data.error.message)
        }
      })
      .catch(({response}) => {
        console.log(response)
      });
    },
    
    addArcher() {
      let vm = this;
      BsAlert.fire({
        title: 'Create New Archer',
        html: '<b>Enter the full name of the archer</b>',
        footer: 'This method is not recommended.',
        input: 'text',
        confirmButtonText: 'Add Archer',
        showLoaderOnConfirm: true,
        inputValidator: (value) => {
          return validate(value, 'clean|full_name|required').then(({valid, errors}) => {
            if (valid) {
              return;
            }
            if (errors.length) {
              return errors.join(' ');
            }
          });
        },
        preConfirm: (name) => {
          return this.$axios
          .post('/archers/create', {
            name: name,
            user: vm.user,
          })
          .then(({data}) => {
            if (data.success) {
              vm.user.managed_archers.push(data.archer);
            }
            if (data.error) {
              BsAlert.fire({icon: 'error', title: data.error.message, showCancelButton: false})
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    },
    removeArcher(archer) {
      let vm = this;
      BsAlert.fire({
        title: 'Stop Managing ' + archer.name,
        html: archer.user_id ? 'You will no longer be able to manage this archer.' : 'This archer only exists on your account. ' +
          ' Their results will no longer be associated with any account.',
        icon: archer.user_id ? 'question' : 'error',
        confirmButtonText: 'Yes, I no longer want to manage this archer',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.$axios
          .post('/archers/remove', {
            archer: archer.id,
            user: vm.user.id,
          })
          .then(({data}) => {
            if (data.success) {
              let index = vm.user.managed_archers.findIndex(f => f.id === archer.id);
              if (index !== -1) {
                vm.user.managed_archers.splice(index, 1);
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
        },
        allowOutsideClick: () => !BsAlert.isLoading()
      })
    }
  }
}
</script>

<style scoped>
th {
  @apply text-center whitespace-nowrap px-0;
}

.table th, .table td {
  vertical-align: middle !important;
}

.editable, .editable a {
  cursor: url("/img/pencil.svg"), pointer;
}

html {
  overflow-x: scroll !important;
}

.table-responsive {
  overflow: visible;
}

.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.bs-card {
  @apply mx-0;
  font-size: 16px;
  letter-spacing: .018rem;
  line-height: 24px;
}

.bs-card a {
  @apply text-blue-600;
}

.bs-card li {
  @apply font-medium mb-2;
  font-size: 17px;
  letter-spacing: .018rem;
  line-height: 24px;
  list-style-type: circle;
}
</style>
