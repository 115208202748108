<template>
  <div v-if="device" class="w-full flex flex-wrap justify-between px-1">
    <div>Device ID: {{ device.device_id }}</div>
    <div>
      Device Name:
      <span v-if="device.name" @click.prevent="setName">{{ device.name }}</span>
      <span v-else @click.prevent="setName" class="cursor-pointer text-blue-600">
        click here to set name
      </span>
    </div>
  </div>
</template>

<script>
import {validate} from 'vee-validate';
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ScoringDevice",
  computed: {
    device() {
      return this.$store.getters['device/getDevice'];
    }
  },
  methods: {
    setName() {
      BsAlert.fire({
        html: this.device.name ? 'Rename this device?' : 'Enter a device name below.',
        input: 'text',
        inputPlaceholder: this.device.name ? this.device.name : 'EX: Range A iPad 1',
        inputValidator: (value) => {
          return validate(value, 'clean').then(({valid, errors}) => {
            if (valid) {
              return;
            }
            if (errors.length) {
              return errors.join(' ');
            }
          });
        }
      }).then(({dismiss, value}) => {
        if (!dismiss) {
          this.$axios.post('/device/set_name', {device: this.device, name: value})
          .then(() => {
            this.$store.dispatch('device/setName', value);
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
