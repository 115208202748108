<template>
  <div v-if="tournament != null && !tournament.is_master_postal && !tournament.master_postal_id">
    <div @click="clone" v-if="useSlot">
      <slot></slot>
    </div>
    <button
      v-else
      @click.prevent="clone()"
      class="m-2 btn btn-outline-blue-600">
      Clone {{ tournament.name }}
    </button>
  </div>
</template>

<script>

import {BsAlert} from "../../mixins/swal_mixins";

/**
 * @typedef {object} tournament
 * @property {array} managed_clubs
 */
export default {
  name: "CloneTournament",
  props: ['tournament', 'useSlot'],
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  methods: {
    async clone() {
      let club = this.tournament.club;
      if (this.user.managed_clubs.length > 1) {
        club = await this.pickClub();
        if (!club) return;
      }
      let cloneCompetitors = false;
      if (club.id === this.tournament.club_id) {
        cloneCompetitors = await this.pickCompetitors();
        if (!cloneCompetitors) return;
      }
      await this.nameAlert(club, cloneCompetitors);
    },
    async nameAlert(club, cloneCompetitors) {
      await BsAlert.fire({
        title: 'What would you like to name the new tournament?',
        icon: 'question',
        confirmButtonText: 'Duplicate',
        input: 'text',
        animation: false,
        inputValue: this.tournament.name + " (Copy)",
        footer: 'This process may take a while on large tournaments.',
        inputValidator: (nameValue) => {
          if (nameValue === "") return 'Please enter a valid tournament name.';
        },
        showLoaderOnConfirm: true,
        preConfirm: async (tournamentName) => {
          await this.submitClone(tournamentName, club, cloneCompetitors);
        },
      })
    },
    
    async pickClub() {
      let clubs = {};
      this.user.managed_clubs.forEach(function (club) {
        clubs[club.id] = club.name;
      });
      const {value: selectedClub, dismiss: cancel} = await BsAlert.fire({
        title: 'Which of your clubs would like to host the tournament?',
        icon: 'question',
        animation: false,
        input: 'select',
        inputOptions: clubs,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve()
            } else {
              resolve('You must select a club.')
            }
          })
        }
      });
      if (cancel) return false;
      return this.user.managed_clubs.find(f => f.id === parseInt(selectedClub));
    },
    
    async pickCompetitors() {
      const inputOptions = {
        true: 'Duplicate with competitors',
        false: 'Just duplicate the details'
      };
      const {value: competitors, dismiss: cancel} = await BsAlert.fire({
        title: 'Would you like to include all the competitors, or just the tournament details?',
        icon: 'question',
        confirmButtonText: 'Duplicate',
        input: 'radio',
        inputOptions: inputOptions,
        animation: false,
        inputValidator: (value) => {
          if (!value) return 'You need to choose a cloning option!';
        }
      });
      return cancel ? false : competitors;
    },
    
    async submitClone(name, club, competitors) {
      if (name) {
        return this.$axios.post(`/tournaments/${this.tournament.slug}/clone`, {
          'cloneCompetitors': competitors,
          'newTournamentName': name,
          'club': club.id,
        }).then(({data}) => {
          if (data['error']) return BsAlert.fire({icon: 'error', html: "Clone failed", showCancelButton: false});
          if (data['success']) {
            window.location.href = '/tournaments/' + data['slug'];
            return BsAlert.fire({
              icon: 'success',
              html: "Successfully cloned the tournament! Don't forget to change the dates and any other important details!",
              showConfirmButton: false,
              showCancelButton: false,
            })
          }
        }).catch(function (error) {
          return BsAlert.fire({icon: 'error', html: "Clone failed", showCancelButton: false});
        });
      }
    },
  }
}
</script>


<style scoped>

</style>