<script setup>

import {computed, ref} from "vue";
import {useDisplayStore} from "../../stores/DisplayStore";

const props = defineProps(['tournament', 'user'])

const isMaster = computed(() => {
  return props.tournament.is_master_postal;
})

const isLeague = computed(() => props.tournament.is_league);

const store = useDisplayStore();
const isAdmin = computed(() => props.user?.auth_manage ?? false);
</script>

<template>
  <div @click="store.closeHamburger" v-if="tournament != null" class="flex flex-col">
    <div v-if="tournament.status === 'draft'">
      <router-link to="" class="mdLeft block" active-class="selected">{{ tournament.name }}</router-link>
      <div class="flex flex-col">
        <router-link to="/manage/edit" active-class="selected" class="lgLeft">Edit</router-link>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <router-link :to="`/`" class="mdLeft" active-class="selected">{{ tournament.name }}</router-link>
      <router-link v-if='!isMaster' to="/register" active-class="selected" class="lgLeft">Registration</router-link>
      <router-link v-if='!isMaster' to="/assignment" active-class="selected" class="lgLeft">Assignments</router-link>
      <router-link v-if='!isMaster' to="/score" active-class="selected" class="lgLeft">Scoring</router-link>
      <router-link v-if='!isMaster' to="/progress" active-class="selected" class="lgLeft">Progress</router-link>
      <router-link to="/results" active-class="selected" class="lgLeft">Results</router-link>
      <router-link to="/logs" active-class="selected" class="lgLeft">Score logs</router-link>
      
      <div v-if="isAdmin && !isMaster" class="flex flex-col">
        <router-link to="/manage/edit" active-class="selected" class="lgLeft">Edit</router-link>
        <router-link to="/manage/settings" active-class="selected" class="lgLeft">Settings</router-link>
        <router-link to="/manage/competitors" active-class="selected" class="lgLeft">Competitors</router-link>
        <router-link v-if="isLeague" to="/manage/league" active-class="selected" class="lgLeft">
          League Settings
        </router-link>
        <router-link to="/manage/bales" active-class="selected" class="lgLeft">Bale Settings</router-link>
        <router-link to="/manage/ladder" active-class="selected" class="lgLeft">Ladder Setup</router-link>
        <router-link to="/manage/import" active-class="selected" class="lgLeft">Import</router-link>
        <router-link to="/manage/devices" active-class="selected" class="lgLeft">Devices</router-link>
        <router-link to="/manage/assign" active-class="selected" class="lgLeft">Auto Assign</router-link>
        <router-link to="/manage/notifications" active-class="selected" class="lgLeft">
          Notifications
        </router-link>
        <router-link to="/manage/messaging" active-class="selected" class="lgLeft">Messaging</router-link>
        <router-link to="/manage/targets" active-class="selected" class="lgLeft">Custom Targets</router-link>
        <router-link to="/manage/restrictions" active-class="selected" class="lgLeft">Restrictions</router-link>
      </div>
      <div v-else-if="isAdmin && isMaster" class="flex flex-col">
        <router-link to="/manage/edit" active-class="selected" class="lgLeft">Edit</router-link>
        <router-link to="/manage/settings" active-class="selected" class="lgLeft">Settings</router-link>
        <router-link to="/manage/postal" active-class="selected" class="lgLeft">Postal Dashboard</router-link>
        <router-link to="/manage/targets" active-class="selected" class="lgLeft">Custom Targets</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>

a:hover {
  @apply text-black;
}

.selected {
  @apply bg-white text-black font-extrabold py-2;
}


.xlLeft {
  @apply py-2 mr-1;
  padding-left: 6.5rem;
}

.lgLeft {
  @apply py-2 mr-1;
  padding-left: 5rem;
}

.mdLeft {
  @apply py-2 mr-1;
  padding-left: 3.5rem;
  margin-right: 1rem;
}


@media (min-width: 1100px) {
  .xlLeft {
    padding-left: 9rem;
  }
  
  .lgLeft {
    padding-left: 7.5rem;
  }
  
  .mdLeft {
    padding-left: 6rem;
    margin-right: 1rem;
  }
}


</style>