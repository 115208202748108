<template>
  <div class="flex flex-col text-center w-full sm:w-3/4 md:1/2 m-auto my-3">
    <div class="text-xl sm:text-2xl">
      Add Authorization Code
      <tool-tip class="text-sm" :size="'w-full'">
        <template v-slot:message>
          <div class="text-2xl">What is an authorization code?</div>
          <p>A club authorization code is a quick way authorize a scoring device for all of a clubs
            {{ this.$trans.choice('search.tournament', 2) }}.</p>
          <div class="text-2xl">Where do I get a code?</div>
          <p> This code can be generated on the clubs settings page. These codes are powerful,
            and should only be given to people with express permission to score.
            A compromised code can be disabled or
            regenerated, but the Club Director must manually unauthorize any unwanted devices.</p>
        </template>
      </tool-tip>
    </div>
    <input maxlength="6" v-model="code" name="code" :disabled="lock" class="bs-form-box w-full">
    <div v-if="invalid" class="text-red-600 text-left">{{ invalid }}</div>
    
    <small>Please enter the 6 letter code provided by the Club Director.
    </small>
  
  
  </div>
</template>

<script>
import {BsAlert} from "../../mixins/swal_mixins";

export default {
  name: "ClubAuth",
  data() {
    return {
      code: '',
      invalid: '',
      lock: false,
    }
  },
  watch: {
    code(text) {
      if (text.length === 6) {
        this.checkCode()
      } else if (text.length) {
        this.invalid = ''
      }
    }
  },
  methods: {
    checkCode() {
      let vm = this;
      this.$axios
      .post('/device/club_add', {
        'code': this.code,
        'device': this.$store.getters['device/getDeviceId'],
      })
      .then(({data}) => {
        if (data.error) {
          vm.invalid = data.error.message;
          vm.lock = true;
          setTimeout(function () {
            vm.lock = false;
            vm.code = ''
          }, 4000);
        }
        if (data.success) {
          BsAlert.fire({
            title: 'success',
            html: "You are now authorized to score that club with this device",
            showCancelButton: false,
          })
        }
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>
