<template>
  <div class="sm:p-3 mb-2 flex flex-col">
    <div>
      <h3 @click="showGroup = !showGroup" class="float-left cursor-pointer text-2xl">
        <span :class="unassignedCount ? 'text-blue-300' : 'text-blue-600'">
          <span v-if="showGroup" key="showMinus"> <i class="far fa-minus-square"></i> </span>
          <span v-else key="showPlus"> <i class="far fa-plus-square"></i> </span>
        </span>
        {{ location.name }}
        <small v-if="remainingSpots" class="text-blue-600">
          {{ remainingSpots }} Open spots
        </small>
        <small v-else class="text-red-600">
          Full
        </small>
      </h3>
    </div>
    <div v-if="showGroup" class="flex flex-wrap flex-row">
      <bale-assignment
        v-for="bale in location.bales"
        :tournament="tournament"
        :lineTime="lineTime"
        :round="round"
        :location="location"
        :bale="bale"
        :key="round.id + '_' + lineTime.id + '_' + location.name + '_' + bale.name"/>
    </div>
  </div>
</template>

<script>
import BaleAssignment from "./BaleAssignment.vue";

export default {
  name: "LocationAssignment",
  components: {BaleAssignment},
  props: ['tournament', 'round', 'lineTime', 'location', 'size'],
  data() {
    return {
      showGroup: this.size <= 35,
    }
  },
  computed: {
    unassignedCount() {
      return this.tournament.unassignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].competitors_count
    },
    totalSpots() {
      return Object.values(this.location.bales).length * parseInt(this.tournament.bale_competitors)
    },
    remainingSpots() {
      let assigned = this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].competitors_count;
      return this.totalSpots - assigned;
    }
  }
}
</script>

<style scoped>
.location {
  flex-basis: 24%;
}
</style>
