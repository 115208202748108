<script setup>
import {computed} from "vue";
import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field} from "vee-validate";
import FormCheckbox from "./FormCheckbox.vue";

const store = useTournamentStore();

const baleWord = computed(() => store.style === 'paper' ? 'bale' : 'stake');

const toggleAssign = () => store.auto_assign = !store.auto_assign;
const toggleSelf = () => store.self_assign = !store.self_assign;

const archerCount = (count) => {
  store.bale_competitors = count;
  store.touched();
};

</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">{{ baleWord }}s</div>
    <div class="flex flex-col sm:flex-row">
      <div class="flex-1 mr-4">
        <div class="bs-section-header">Number of {{ baleWord }}s</div>
        <Field class="bs-input-box" type="number" name="bales" v-model="store.bales"
               rules="required|min_value:1|max_value:300"/>
        <ErrorMessage class="errorText" name="bales"/>
      </div>
      <div class="flex-2 mt-4 sm:mt-0">
        <div class="bs-section-header">Archers per {{ baleWord }}</div>
        <div class="flex flex-wrap">
          <div v-for="count in [1,2,3,4,5,6,7,8]">
            <button @click.prevent="archerCount(count)"
                    class="bs-input-box mb-1"
                    :class="count === store.bale_competitors ? 'activeBox' : ''">
              {{ count }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 bs-section-header">Assignments</div>
    <form-checkbox title="Automatically assign archers" :active="store.auto_assign" @tapped="toggleAssign"/>
    <form-checkbox :title="`Allow ${baleWord} switching`"
                   :sub-title="`Archers can change their own ${baleWord} assignments`"
                   :active="store.self_assign"
                   @tapped="toggleSelf"/>
  
  </div>
</template>

<style scoped>
.activeBox {
  @apply text-white bg-black;
  color: #FFFFFF !important;
}

.bs-input-box {
  @apply text-black font-medium;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
  
  margin-right: 8px;
  width: auto;
}
</style>