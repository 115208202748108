<script setup>

import {BsAlert} from "../../mixins/swal_mixins";

const props = defineProps(['showButton'])

const createClub = () => {
  BsAlert.fire({
    titleText: 'Create a new club?',
    icon: 'question',
    html: 'This will make a brand new club with you as the club manager. Most users are not looking to do this.' +
      'Type "yes" to begin the club creation process.',
    input: 'text',
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value === 'yes') {
          resolve()
        } else {
          resolve('You need to type "yes" to continue.')
        }
      })
    }
  }).then(({dismiss}) => {
    if (!dismiss) {
      window.location.href = '/clubs/create';
    }
  })
};
</script>

<template>
  <div @click="createClub">
    <button v-if="showButton" class="btn-sm btn-outline-gray-600"><span><i class="fas fa-plus"></i></span>
      Create Club
    </button>
    <slot v-else></slot>
  
  </div>
</template>

<style scoped>

</style>