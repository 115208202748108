<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {computed} from "vue";

const store = useCompetitorStore();
const invalid = computed(() => store.submitted && !store.justTargetComplete)


</script>

<template>
  <div v-if="store.targets.length > 1" class="bs-card" id="targetCard" :class="invalid ? 'invalid' : ''">
    <div class="bs-section-header">Target</div>
    <div class="flex flex-wrap">
      <div v-for="target in store.targets" class="mb-2 mr-2">
        <button @click.prevent="store.toggleTarget(target)"
                class="bs-input-box"
                :class="store.target_id === target.id ? 'bg-black text-white' : ''">
          {{ target.name }}
        </button>
      </div>
    </div>
  
  </div>
</template>

<style scoped>
.invalid {
  border-color: red;
}
</style>