<template>
  <div class="flex flex-col">
    <div class="mt-6 bs-section-header">
      This is a postal tournament
    </div>
    <a @click.prevent="hostPostal" href="" class="text-blue-600 cursor-pointer">
      Click here if you are a tournament director wishing to host this postal.
    </a>
    <div class="mt-6 bs-section-header">
      If you are looking to compete, check out one of the following host tournaments.
    </div>
    <ul>
      <li v-for="child in master.children">
        <a class="text-blue-600 cursor-pointer" :href="`/tournaments/${child.slug}`">{{ child.name }}</a>
      </li>
      <li v-if="!master.children || !master.children.length">
        There are no approved hosting tournaments at this time.
      </li>
    </ul>
  
  </div>
</template>

<script>

import {BsAlert} from "../../../mixins/swal_mixins";

export default {
  name: "HostPostal",
  props: ['master'],
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    }
  },
  methods: {
    hostPostal() {
      if (this.user && this.user.managed_clubs) {
        if (Object.values(this.user.managed_clubs).length > 1) {
          let clubList = {};
          Object.values(this.user.managed_clubs).forEach(club => {
            clubList[club.id] = club.name;
          });
          BsAlert.fire({
            titleText: 'Which of your clubs would you like to host this postal',
            input: 'radio',
            customClass: {
              input: 'my-radio',
            },
            inputOptions: clubList,
          }).then((value) => {
            if (value.value != null && !value.dismiss) {
              let club = this.user.managed_clubs.find(f => f.id === parseInt(value.value));
              this.createPostal(club);
            }
          })
        } else {
          BsAlert.fire({
            titleText: 'Host this Tournament from ' + this.user.managed_clubs[0].name + '?',
          }).then(({dismiss}) => {
            if (!dismiss) {
              this.createPostal(this.user.managed_clubs[0])
            }
          })
        }
        
      }
    },
    createPostal(club) {
      let name = this.master.name + ' - ' + club.range_address.city + ', ' + club.range_address.state;
      BsAlert.fire({
        titleText: 'Name of the Tournament',
        input: 'text',
        inputValue: name,
        html: 'This will be the name of your local tournament, and can be changed'
      }).then((value) => {
        this.$axios.post('/tournaments/' + this.master.slug + '/clone', {
          'cloneCompetitors': false,
          'newTournamentName': value.value,
          'club': club.id,
          'postal': true,
        }).then(({data}) => {
          if (data['error']) {
            return BsAlert.fire({title: data['error'], showCancelButton: false, icon: "error"})
          }
          if (data['success']) {
            window.location.href = '/tournaments/' + data['slug'];
            return BsAlert.fire({
              icon: 'success',
              html: "Hosting tournament successfully created! Don't forget to change the dates and any other important details!",
              showConfirmButton: false,
              showCancelButton: false,
            })
          }
        }).catch(function (error) {
          console.log(error);
        });
      })
      
    }
  },
}
</script>

<style scoped>

</style>
