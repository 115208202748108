<script setup>
import {useManageStore} from "../../../../stores/manageStore";

const props = defineProps(['label', 'value'])
const manage = useManageStore();
</script>

<template>
  <th class="table-header p-0 m-0">
    <div class="flex justify-between">
      <div class="text-nowrap p-3">{{ label }}</div>
      <div @click="manage.sort(value)" class="py-3 pl-5 pr-3 cursor-pointer">
        <i :class="manage.sorted.includes(value)?'fa fa-arrow-down':'fa fa-arrow-up'"></i>
      </div>
    </div>
  </th>
</template>

<style scoped>

</style>