<script setup>

import {onMounted, ref} from "vue";
import axios from "axios";
import moment from "moment";
import ClipboardCopy from "../../../partials/ClipboardCopy.vue";
import {BsAlert} from "../../../../mixins/swal_mixins";

const props = defineProps(['tournament'])

const codes = ref({});
onMounted(() => {
  axios
  .get('/tournaments/' + props.tournament.slug + '/get_codes')
  .then(({data}) => {
    codes.value = data.codes;
  });
})

const generateCodes = (() => {
  axios
  .post('/tournaments/' + props.tournament.slug + '/generate_codes')
  .then(({data}) => {
    codes.value = data.codes;
  })
});

const roundName = (roundId) => {
  let round = props.tournament.rounds.find((f) => f.id == roundId);
  return round?.name ?? `Round ${roundId}`;
}

const timeName = (timeId, roundId) => {
  let showDay = multiDay(roundId);
  let time = props.tournament.line_times.find((f) => f.id == timeId);
  if (time) {
    let dateTime = new Date(time.time)
    let momentTime = moment(dateTime);
    return momentTime.format(`${showDay ? 'dddd ' : ''}HH:mm A`);
  }
  
  return `Time ${timeId}`;
}

const multiDay = (roundId) => {
  let round = props.tournament.rounds.find((f) => f.id == roundId);
  if (round) {
    if (round.line_times.length === 1) return false;
    let day = null;
    for (let line_time of round.line_times) {
      let dateTime = new Date(line_time.time);
      let time = moment(dateTime)
      let timeDay = time.day();
      if (day == null) day = timeDay;
      if (timeDay !== day) return true;
    }
    
  }
  return false;
}

const baleList = (roundId, timeId) => {
  let bales = codes.value[roundId]['line_times'][timeId]['locations']['1']['bales'];
  return bales;
}

const generateClicked = (roundId) => {
  axios
  .post('/tournaments/' + props.tournament.slug + '/regenerate_codes', {round: roundId})
  .then(({data}) => {
    let existing = codes.value;
    existing[roundId] = data.codes[roundId];
    codes.value = existing;
  });
}


const removeCodes = () => {
  BsAlert.fire({
    titleText: 'Remove All Codes?',
    html: 'This will permanently erase the codes, they will no longer work for any device setup. New codes may be generated at any time.',
  }).then(({dismiss}) => {
    if (!dismiss) {
      axios
      .post('/tournaments/' + props.tournament.slug + '/remove_codes')
      .then(() => {
        codes.value = {}
      });
    }
  });
}
</script>

<template>
  <div>
    <div class="bs-card">
      <div class="bs-card-header">Bale codes</div>
      <div class="hint">These codes allow scoring for ONLY the assigned bale.</div>
      <div v-if="!Object.keys(codes).length" class="generate" @click="generateCodes">
        <i class="fa-regular fa-lg fa-circle-plus mr-2"></i> Generate codes
      </div>
      <div v-else>
        <div v-for="roundId of Object.keys(codes)" class="mt-4 mb-4">
          <div class="bs-section-header">{{ roundName(roundId) }}</div>
          <div class="bs-card mx-0">
            <div v-for="timeId of Object.keys(codes[roundId]['line_times'])" class="mb-4">
              <div class="time">{{ timeName(timeId, roundId) }}</div>
              <div class="flex flex-wrap">
                <div v-for="bale in baleList(roundId, timeId)">
                  <clipboard-copy :message="`Bale ${bale['bale']} code copied to clipboard`"
                                  :copy-value=" bale['code'].toUpperCase()">
                    <div class="baleItem">
                      <div class="bale">Bale {{ bale['bale'] }}</div>
                      <div class="flex">
                        <div class="code">{{ bale['code'].toUpperCase() }}</div>
                        <div class="flex flex-col justify-center">
                          <i class="fa-regular fa-sm fa-link ml-3 text-stone-600"></i>
                        </div>
                      </div>
                    </div>
                  </clipboard-copy>
                </div>
              </div>
            </div>
            <div class="refreshLink" @click="generateClicked(roundId)">
              <i class="fa-regular fa-lg fa-arrows-rotate mr-2"></i> Generate new codes
            </div>
          </div>
        </div>
      </div>
      <div v-if="Object.keys(codes).length" class="flex justify-end mt-6">
        <button @click="removeCodes" class="btn btn-red-600">Delete codes</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn {
  @apply py-0 text-white font-medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .018rem;
  height: 32px;
}

.code {
  @apply text-black font-bold;
  font-size: 14px;
  letter-spacing: .018rem;
  line-height: 17px;
}

.bale {
  @apply text-stone-600;
}

.baleItem {
  @apply flex justify-between bs-input-box mr-2 mb-2 cursor-pointer;
  width: 163px;
}

.hint {
  @apply text-stone-400;
}

.time {
  @apply text-black font-medium mb-2;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
}

.generate {
  @apply text-black mt-4 font-bold cursor-pointer inline-block;
  line-height: 17px;
  letter-spacing: .018rem;
}

.refreshLink {
  @apply text-black mt-2 font-bold cursor-pointer;
  display: inline-block;
  line-height: 17px;
  letter-spacing: .018rem;
}
</style>