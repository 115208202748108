<script setup>

import FormCheckbox from "./FormCheckbox.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field} from "vee-validate";
import {BsAlert} from "../../../mixins/swal_mixins";

const store = useTournamentStore()

const clicked = () => {
  if (store.waiver === null) {
    store.waiver = '';
  } else if (store.waiver.length > 0) {
    BsAlert.fire({
      title: 'This will clear the following',
      html: `<p>${store.waiver}</p>`,
    }).then((result) => {
      if (result.isConfirmed) {
        store.waiver = null;
      }
    })
  } else {
    store.waiver = null;
  }
}

</script>

<template>
  <div class="bs-card flex flex-col justify-between">
    <div class="bs-section-header">Waiver</div>
    <div v-if="store.waiver != null">
      <Field type="textarea" as="textarea" v-model="store.waiver" class="bs-input-box" name="waiver"/>
    </div>
    <ErrorMessage name="waiver" class="errorText"/>
    <form-checkbox :active="store.waiver != null"
                   title="Require archers to sign a waiver"
                   @click="clicked"/>
  </div>
</template>

<style scoped>

</style>