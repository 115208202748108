<script setup>

</script>

<template>
  <div class="w-full flex justify-center">
    <a href="/contact_us" class="button block text-center"><span>contact us</span></a>
  </div>
</template>

<style scoped>
span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.button {
  @apply text-white;
  background-color: #FB5202;
  font-weight: 700;
  font-family: 'Figtree', serif;
  border-radius: 3px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  height: 44px;
  width: 338px;
  font-size: 18px;
  letter-spacing: .48px;
  line-height: 44px;
  text-transform: uppercase;
  margin-top: 16px
}
</style>