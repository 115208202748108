<script setup>
import {ErrorMessage, Field, useForm, Form} from "vee-validate";
import {computed, ref, watch} from "vue";
import axios from "axios";

const props = defineProps(['tournament']);
const dropRounds = ref(props.tournament.dropped_rounds);
const error = ref(false);
const max = computed(() => props.tournament.rounds.length - 1);

watch(dropRounds, (value) => {
  error.value = false;
  let passed = value !== null && value >= 0 && value < max.value;
  if (passed) {
    axios.post('/tournaments/' + props.tournament.slug + '/league', {
      'dropCount': dropRounds.value,
    });
  } else {
    error.value = true;
  }
})

</script>
<template>
  <div class="w-full sm:w-1/2 m-2">
    <div class="card card-blue-700 ">
      <div class="card-header">Drop Rounds</div>
      <div class="card-body flex flex-col">
        <label for="drop count" class="text-lg">Rounds to drop</label>
        <Field v-model="dropRounds" class="bs-input-box mb-3" type="number" name="drop count"
               :rules="`required|min:0|max:${max}`"/>
        <ErrorMessage name="drop count" class="errorText"/>
        <span v-if="error" class="errorText">
          Number must be less than the number of rounds
        </span>
        <span class="hintText">
          This will ignore scores for the specified number of rounds in the final results calculation. The
          rounds with the lowest scores for each individual competitor will be dropped. Set this field to 0 to
          include all rounds.
        </span>
      </div>
    </div>
  </div>

</template>


<style scoped>

</style>
