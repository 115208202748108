<script setup>
import {useManageStore} from "../../../../stores/manageStore";
import {onMounted, ref, watch} from "vue";
import AllowEdits from "./AllowEdits.vue";
import ManageCompetitorFilters from "./ManageCompetitorFilters.vue";
import ManageCompetitorTabs from "./ManageCompetitorTabs.vue";
import ManageCompetitorActions from "./ManageCompetitorActions.vue";
import CompetitorDetailsTable from "./CompetitorDetailsTable.vue";
import CompetitorRoundTable from "./CompetitorRoundTable.vue";
import {onBeforeRouteLeave} from "vue-router";
import {useVueToPrint, VueToPrint} from "vue-to-print";
import {useDisplayStore} from "../../../../stores/DisplayStore";

const props = defineProps(['tournamentIn', 'user'])
const manage = useManageStore();
const printRef = ref(null);
const display = useDisplayStore();
onMounted(() => {
  if (!display.loadingTournament) {
    manage.setDefaults(props.tournamentIn, props.user);
  }
});
onBeforeRouteLeave((to, from, next) => {
  manage.updateSuite();
  manage.$reset();
  next();
})
watch(() => display.loadingTournament, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue === false) {
    manage.$reset();
    manage.setDefaults(props.tournamentIn, props.user);
  }
})

const {handlePrint} = useVueToPrint({
  content: printRef,
  documentTitle: 'Manage Competitors',
  removeAfterPrint: true,
})
</script>
<template>
  <div class="w-full">
    <div v-if="display.loadingTournament"></div>
    <div v-else-if="manage.selectedOptions && manage.tournament !== null"
         class="card card-blue-700 mx-1 md:mx-2 mt-4 flex-grow ">
      <div class="card-header">
        Competitors ({{ manage.tournament.competitors.length }})
      </div>
      <div class="card-body" id="printArea">
        <AllowEdits/>
        <div class="flex flex-col">
          <ManageCompetitorActions @handlePrint="handlePrint"/>
          <ManageCompetitorFilters/>
          <ManageCompetitorTabs/>
          <div class="flex justify-between">
            <div v-if="manage.round !== null" @click="manage.updateAllClicked()" class="btn-sm btn-gray-600">
              Update All
            </div>
            <div class="flex flex-col justify-end text-sm text-gray-600">
              <span>
                <i class="fal fa-info-circle"></i> Most fields may be edited by clicking on the cell
              </span>
            </div>
          </div>
          <div ref="printRef">
            <CompetitorDetailsTable v-if="manage.round === null"/>
            <CompetitorRoundTable v-else/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center flex-grow py-32">
      <h1 class="mt-4 text-5xl">Loading Competitor Details</h1>
      <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
    </div>
  </div>

</template>
<style scoped>

</style>
