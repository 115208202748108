<script setup>

import {useTournamentStore} from "../../stores/TournamentStore";
import UserButton from "./UserButton.vue";

const props = defineProps(['user'])
const store = useTournamentStore();
</script>

<template>
  <div class="formBanner" :class="store.unsavedChanges ? 'bigHeight' : 'smallHeight'">
    <div class="flex flex-col sm:flex-row">
      <div class="left mb-2 sm:mb-0">
        <div class="h-full w-full flex flex-col justify-center">
          <div class="flex justify-between sm:justify-center">
            <div class="flex flex-col justify-center">
              <img alt="bowscore logo" src="/img/White_Bowscore_Logo.png" class="image">
            
            </div>
            <user-button class="sm:hidden" :user="props.user"/>
          </div>
        
        
        </div>
      </div>
      
      <div class="middle">
        <div class="buttons">
          <slot v-if="store.unsavedChanges" name="buttons"></slot>
          <user-button class="hidden sm:block md:hidden" :user="props.user"/>
          <div class="right"></div>
        </div>
      </div>
      <user-button class="hidden md:block mr-4" :user="props.user"/>
    </div>
  </div>
</template>

<style scoped>
.formBanner {
  @apply bg-stone-600 flex flex-col justify-center;
}

.bigHeight {
  height: 80px;
}

.smallHeight {
  height: 80px;
}

.left {
  padding-left: 64px;
  
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}


@media (max-width: 576px) {
  .formBanner {
    padding-left: 1rem;
  }
  
  .bigHeight {
    height: 100px;
  }
  
  .smallHeight {
    height: 60px;
  }
  
  .left {
    padding-left: 20px;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    padding-right: 20px;
  }
}

.middle {
  @apply flex-grow flex justify-center;
}

.buttons {
  @apply flex justify-end;
  margin-left: 660px;
  max-width: 0px;
  min-width: 10px;
  width: 100%;
}

@media (max-width: 1375px) {
  .middle {
    @apply justify-end
  }
  
  .buttons {
    @apply ml-0;
  }
}

.right {
  min-width: 10px;
  width: 100%;
  max-width: 200px;
}

.image {
  width: 210px;
  height: 24px;
}

@media (max-width: 600px) {

}

</style>