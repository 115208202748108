<script setup>

import {useDisplayStore} from "../../../../stores/DisplayStore";

const store = useDisplayStore();
</script>

<template>
  <div class="bs-card">
    <div class="bs-section-header">Scoring Portal</div>
    <div class="text">
      We recommend scoring your tournaments using the Bowscore app (available on iOS, Android, and Kindle devices) for
      the best scoring experience. However, if you need to score using devices that haven’t downloaded the app, you can
      use our scoring portal below instead.
    </div>
    <div class="flex justify-start mt-4">
      <button @click="store.scoreOnline = true" class="btn btn-green-700">Score online</button>
    </div>
  </div>
</template>

<style scoped>


.text {
  @apply text-stone-600 my-2;
  font-size: 14px;
  line-height: 17px;
}

.btn {
  @apply py-0 text-white font-medium;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .018rem;
  height: 32px;
}
</style>